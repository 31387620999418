import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

import { awsBucketConfig } from "../config/awsBucketConfig";

const s3Client = new S3Client({
  region: awsBucketConfig.awsRegion,
  credentials: {
    accessKeyId: awsBucketConfig.accessKeyId,
    secretAccessKey: awsBucketConfig.secretAccessKey,
  },
});

export const EnumFileUploadStatus = {
  FileUploadSuccess: 1,
  FileUploadFailed: 2,
  FileNotAvailable: 3,
};

export const uploadMediaOnAWS = async (selectedMedia) => {
  if (Object.keys(selectedMedia).length !== 0) {
    const uploadParams = {
      Bucket: awsBucketConfig.storageBucketName,
      Key: selectedMedia.fileName,
      Body: selectedMedia.mediaFile,
      ACL: "public-read",
      ContentType: selectedMedia.fileType,
    };
    let result = await s3Client.send(new PutObjectCommand(uploadParams));
    if (result && result?.$metadata?.httpStatusCode === 200) {
      return EnumFileUploadStatus.FileUploadSuccess;
    } else {
      return EnumFileUploadStatus.FileUploadFailed;
    }
  } else {
    return EnumFileUploadStatus.FileNotAvailable;
  }
};
