import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";

const ContextMenuContext = createContext();

export const ContextMenuProvider = ({ children }) => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [showMenu, setShowMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const menuRef = useRef(null);

  const showContextMenu = useCallback((event, items) => {
    event.preventDefault();
    setXPos(`${event.pageX > 200 ? event.pageX - 200 : 100}px`);
    setYPos(`${event.pageY}px`);
    setMenuItems(items);
    setShowMenu(true);
  }, []);

  const hideContextMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        hideContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hideContextMenu]);

  return (
    <ContextMenuContext.Provider
      value={{
        xPos,
        yPos,
        showMenu,
        menuItems,
        menuRef,
        showContextMenu,
        hideContextMenu,
      }}
    >
      {children}
    </ContextMenuContext.Provider>
  );
};

export const useContextMenu = () => useContext(ContextMenuContext);
