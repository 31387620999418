import React from "react";
import { MdDeleteOutline } from "react-icons/md";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";

const DeleteWhatsappTemplate = ({ deleteTemplate }) => {
  const { apiCallDeleteTemplate } = useWhatsappContext();

  const confirmDeletion = () => {
    apiCallDeleteTemplate(deleteTemplate);
  };
  return (
    <div className="my-popup-style-1">
      <p>Are you sure you want to delete this template?</p>
      <button onClick={confirmDeletion} className="my-button-style-cancel mt-2">
        <MdDeleteOutline />
      </button>
    </div>
  );
};

export default DeleteWhatsappTemplate;
