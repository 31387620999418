import React from "react";

export const parseText = (text) => {
  return text.split("\n").map((line, index) => {
    try {
      const parts = line.split(/(\*|_|~)/g);
      return (
        <React.Fragment key={index}>
          {parts.map((part, i) => {
            if (part === "*") {
              return <strong key={i} />;
            } else if (part === "_") {
              return <em key={i} />;
            } else if (part === "~") {
              return <del key={i} />;
            } else {
              return part;
            }
          })}
          <br />
        </React.Fragment>
      );
    } catch (error) {
      return text;
    }
  });
};
