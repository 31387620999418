import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";
import { useUserContext } from "./UserContextProvider";

const FollowupContext = createContext();

export const FollowupContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;
  const { setIsLoading } = useUserContext();

  const [followupList, setFollowupList] = useState();

  const addContactFollowup = (followupData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/save-contact-followup`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(followupData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
          apiCallFetchContactFollowup(
            "assignedFollowup",
            followupData?.contactNumber
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to save contact followup");
        setIsLoading(false);
      });
  };

  const updateContactFollowup = (followupData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/update-contact-followup`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(followupData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to update contact followup");
        setIsLoading(false);
      });
  };

  const updateContactFollowupStatus = (followupData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/update-contact-followup-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(followupData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to update followup status");
        setIsLoading(false);
      });
  };

  const apiCallFetchContactFollowup = (viewType, contactNumber, searchText) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/all-followups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        viewType: viewType,
        contactNumber: contactNumber,
        searchText: searchText,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
          setIsLoading(false);
        }
        setFollowupList(response);
      })
      .catch((err) => {
        toast.error("Unable to get contact followup");
        setIsLoading(false);
      });
  };

  return (
    <FollowupContext.Provider
      value={{
        addContactFollowup,
        updateContactFollowup,
        updateContactFollowupStatus,
        apiCallFetchContactFollowup,
        followupList,
      }}
    >
      {children}
    </FollowupContext.Provider>
  );
};

export const useFollowupContext = () => useContext(FollowupContext);
