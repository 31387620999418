export const viewSentMessageTemplate = (element) => {
  return (
    <div key={element?.type} className="w-full pb-3 text-xs">
      {element?.type?.toUpperCase() === "HEADER" &&
        element?.parameters[0]?.type === "image" && (
          <img
            src={element?.parameters[0]?.image?.link}
            className="w-80 px-1 rounded-md"
            alt="template pic"
          />
        )}
      {element?.type?.toUpperCase() === "HEADER" &&
        element?.parameters[0]?.type === "video" && (
          <span className="flex gap-2 whitespace-nowrap">
            Video Link ⇒
            <button>
              <a
                href={element?.parameters[0]?.video?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="my-hover-effect-2 font-bold"
              >
                Click to Open
              </a>
            </button>
          </span>
        )}
      {element?.type?.toUpperCase() === "HEADER" &&
        element?.parameters[0]?.type === "document" && (
          <span className="flex gap-2 whitespace-nowrap">
            Document Link ⇒
            <button>
              <a
                href={element?.parameters[0]?.document?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="my-hover-effect-2 font-bold"
              >
                Click to Open
              </a>
            </button>
          </span>
        )}
    </div>
  );
};
