import React, { useState, useEffect } from "react";
import { RiRocketFill } from "react-icons/ri";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div>
      {showTopBtn ? (
        <button
          onClick={scrollToTop}
          className="my-page-button-layout bg-wabmGreen hover:bg-wabmBlue px-3 hover:text-white drop-shadow-xl fixed bottom-20 right-10"
        >
          <RiRocketFill size={24} />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ScrollToTop;
