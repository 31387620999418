import React, { useState, useEffect } from "react";
import { PiChatsTeardropFill } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Button } from "@material-tailwind/react";

import { useUserContext } from "../../contexts/UserContextProvider";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import {
  WhatsAppBusinessProfile,
  WhatsAppAnalytics,
  CampaignStatuses,
  WhatsAppConversationInsights,
  SeoTags,
} from "..";
import { aboutApp } from "../../config/appConfig.js";

const Overview = () => {
  const {
    daypart,
    daypartFunction,
    apiConversationQuotaLimit,
    currentUserInfo,
    isUserAdminOrSuperadmin,
    userConversationLimits,
  } = useUserContext();
  const { getWbProfileInfo, getWbPhoneNumberStatus } = useWhatsappContext();

  const [showInsightsType, setShowInsightsType] = useState("message-analytics");

  useEffect(() => {
    daypartFunction();
    getWbProfileInfo();
    getWbPhoneNumberStatus();
    apiConversationQuotaLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="space-y-5">
      <SeoTags title="Overview ‣ WaBM" />

      <div className="w-full mt-5 mb-10 lg:my-1">
        <h1 className="text-2xl mb-2">
          Good {daypart} {currentUserInfo?.agentName}!
        </h1>
        <p>
          So what's the plan today? Wanna start up a campaign or check out your
          past activities.
        </p>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
        <div className="lg:col-span-2 space-y-5">
          <div className="space-y-2">
            <h3 className="font-Montserrat text-lg text-wabmBlue">
              Conversation Limits
            </h3>
            <div className="grid grid-cols-2 gap-2">
              <div className="wbm-card-1 bg-yellow-400 space-y-2">
                <PiChatsTeardropFill size={30} />
                <p className="">Daily Conv Used / Total</p>
                <p className="text-3xl">
                  {userConversationLimits?.conversationUsedCount} /{" "}
                  {userConversationLimits?.conversationLimit}
                </p>
              </div>
              <div className="wbm-card-1 bg-indigo-400 space-y-2">
                <IoLogoWhatsapp size={30} />
                <p className="">Daily WA Remaining / Total</p>
                <p className="text-3xl">
                  {userConversationLimits?.todaysRemainingMessageLimit}/
                  {userConversationLimits?.dailyMessageLimit}
                </p>
              </div>
            </div>
            {isUserAdminOrSuperadmin() && (
              <a
                href={`https://business.facebook.com/wa/manage/phone-numbers/`}
                target="_blank"
                rel="noopener noreferrer"
                className="my-hover-effect-2 after:bg-wabmGreen after:h-[2px] font-semibold text-wabmBlue"
              >
                Insights on Meta ►
              </a>
            )}
          </div>

          <div className="space-y-3">
            <p>
              Click one of the following buttons to view preferred insights.
            </p>
            <div className="flex flex-wrap lg:flex-nowrap gap-2">
              <Button
                type="button"
                variant="gradient"
                color={
                  showInsightsType === "message-analytics" ? "green" : "blue"
                }
                onClick={() => {
                  setShowInsightsType("message-analytics");
                }}
                className="w-full md:w-36 xl:w-auto"
              >
                Message Analytics
              </Button>
              {isUserAdminOrSuperadmin() && (
                <Button
                  type="button"
                  variant="gradient"
                  color={
                    showInsightsType === "conversation-insights"
                      ? "green"
                      : "blue"
                  }
                  onClick={() => {
                    setShowInsightsType("conversation-insights");
                  }}
                  className="w-full md:w-36 xl:w-auto"
                >
                  Conversation Insights
                </Button>
              )}
              <Button
                type="button"
                variant="gradient"
                color={
                  showInsightsType === "campaign-statuses" ? "green" : "blue"
                }
                onClick={() => {
                  setShowInsightsType("campaign-statuses");
                }}
                className="w-full md:w-36 xl:w-auto"
              >
                Campaign Statuses
              </Button>
            </div>
          </div>

          {showInsightsType === "message-analytics" && <WhatsAppAnalytics />}
          {isUserAdminOrSuperadmin() &&
            showInsightsType === "conversation-insights" && (
              <div className="wbm-card-2 space-y-3">
                <WhatsAppConversationInsights />
              </div>
            )}
          {showInsightsType === "campaign-statuses" && <CampaignStatuses />}
        </div>

        <WhatsAppBusinessProfile />
      </div>

      <p className="text-sm text-slate-600">{aboutApp.version}</p>
    </main>
  );
};

export default Overview;
