import React from "react";

import { AppNavbar, Footer } from "../components";
import { contactLinks } from "../config/appConfig";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between gap-5 overflow-hidden">
      <AppNavbar />

      <main className="container mx-auto px-5 py-20 space-y-5">
        <h1 className="font-Montserrat text-wabmBlue text-3xl text-center font-bold">
          Privacy Policy
        </h1>
        <div className="space-y-3">
          <p className="text-justify">
            We respect your privacy and are committed to protecting the personal
            information you share with us. This Privacy Policy explains how we
            collect, use, and protect your personal information in connection
            with your use of our WhatsApp business API provider app.
          </p>
          <h3 className="font-Montserrat text-lg">
            Collection of Personal Information
          </h3>
          <p className="text-justify">
            We collect personal information such as your name, email address,
            and phone number when you register for our app. We also collect
            information about your business, including your business name,
            location, and industry. We may collect additional information from
            you when you use our app, such as your usage data and preferences.
          </p>
          <h3 className="font-Montserrat text-lg">
            Use of Personal Information
          </h3>
          <p className="text-justify">
            We use your personal information to provide you with the services
            and features of our WhatsApp business API provider app. This
            includes facilitating communication between your business and your
            customers, providing marketing opportunities to your business, and
            analyzing usage trends to improve our services.
          </p>
          <h3 className="font-Montserrat text-lg">
            Disclosure of Personal Information
          </h3>
          <p className="text-justify">
            We do not sell or rent your personal information to third parties
            for marketing purposes. We may share your personal information with
            our service providers and business partners who assist us in
            providing our services. We may also disclose your personal
            information if required by law or in connection with legal
            proceedings.
          </p>
          <h3 className="font-Montserrat text-lg">
            Security of Personal Information
          </h3>
          <p className="text-justify">
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. We use encryption and
            other security technologies to safeguard your personal information.
            However, no system can be 100% secure, and we cannot guarantee the
            security of your personal information.
          </p>
          <h3 className="font-Montserrat text-lg">
            Retention of Personal Information
          </h3>
          <p className="text-justify">
            We retain your personal information for as long as necessary to
            provide our services and as required by law. We will delete your
            personal information upon request, provided that we are not required
            to retain it by law or for legitimate business purposes.
          </p>
          <h3 className="font-Montserrat text-lg">Changes to Privacy Policy</h3>
          <p className="text-justify">
            We may update this Privacy Policy from time to time. We will notify
            you of any material changes to this Privacy Policy by email or by
            posting a notice on our website.
          </p>
          <h3 className="font-Montserrat text-lg">Contact Us</h3>
          <p className="text-justify">
            If you have any questions or concerns about this Privacy Policy or
            our use of your personal information, please contact us at
            {contactLinks.supportMail}
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
