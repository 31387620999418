import React from "react";
import { Link } from "react-router-dom";

const FreeTrialSection = () => {
  return (
    <section className="bg-wabmBlue h-2/3 w-full">
      <main className="bg-[url(../src/images/props/platform.png)] bg-cover bg-center h-screen flex items-center justify-center">
        <div className="container mx-auto px-5 py-24 text-center text-white space-y-5">
          <h2 className="font-Montserrat text-3xl">
            Ready to boost your Business to <br /> the Next Level?
          </h2>
          <p className="pb-10">
            Get our WaBM Two Way Communication trial for 14 days now!
          </p>
          <Link to="/signup">
            <button className="my-page-button-layout bg-wabmAiBlue hover:bg-black hover:text-wabmGreen">
              Start Free Trial
            </button>
          </Link>
        </div>
      </main>
    </section>
  );
};

export default FreeTrialSection;
