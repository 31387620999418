import React from "react";

import { AppNavbar, Footer } from "../components";
import { contactLinks } from "../config/appConfig";

const CancellationRefundPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between gap-5 overflow-hidden">
      <AppNavbar />

      <main className="container mx-auto px-5 py-20 space-y-5">
        <h1 className="font-Montserrat text-wabmBlue text-3xl text-center font-bold">
          Cancellation and Refund Policy
        </h1>
        <div className="space-y-3">
          <p className="text-justify">
            WaBM offers a free trial period for our WhatsApp marketing solution
            to enable you to evaluate our services before purchasing.
          </p>
          <h3 className="font-Montserrat text-lg">Cancellation Policy</h3>
          <p className="text-justify">
            ▸ During the trial period, you may cancel your subscription at any
            time without any obligation or penalty.
          </p>
          <p className="text-justify">
            ▸ Once you subscribe to our services, you may cancel your
            subscription at any time by contacting our customer support team.
          </p>
          <p className="text-justify">
            ▸ If you cancel your subscription before the end of the current
            billing cycle, your account will remain active until the end of the
            billing cycle, and you will not be charged for the next cycle.
          </p>
          <p className="text-justify">
            ▸ WaBM reserves the right to suspend or terminate your account at
            any time, without notice, for any violation of our Terms of Service
            or Acceptable Use Policy.
          </p>
          <h3 className="font-Montserrat text-lg">Refund Policy</h3>
          <p className="text-justify">
            ▸ Refunds will only be provided in the event of a technical issue or
            service outage that prevents you from using our WhatsApp marketing
            solution for an extended period of time.
          </p>
          <p className="text-justify">
            ▸ If you cancel your subscription after the trial period has ended,
            you will not be eligible for a refund.
          </p>
          <p className="text-justify">
            ▸ WaBM is not responsible for any third-party fees or charges that
            may be incurred during the use of our services.
          </p>
          <p className="text-justify">
            ▸ Any refunds that are provided will be processed within 14 business
            days.
          </p>
          <h3 className="font-Montserrat text-lg">Contact Us</h3>
          <p className="text-justify">
            If you have any questions or concerns about our cancellation and
            refund policy, please contact our customer support team for
            assistance or email us at {contactLinks.supportMail}
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default CancellationRefundPolicy;
