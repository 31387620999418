import React from "react";
import { NavLink } from "react-router-dom";
import { BiSolidErrorAlt } from "react-icons/bi";

import { useUserContext } from "../../contexts/UserContextProvider";

const FailedPayment = () => {
  const { loginCheck } = useUserContext();

  return (
    <div className="h-screen flex flex-col justify-center items-center gap-1">
      <BiSolidErrorAlt size={64} className="text-red-500" />

      <h2 className="text-xl font-semibold">Payment Failed</h2>
      <p className="">Uh, oh! Your WaBM Payment got Failed!</p>

      <NavLink to="/login">
        <button onClick={loginCheck} className="my-button-style-1 mt-1">
          ⇐ Back to WaBM
        </button>
      </NavLink>
    </div>
  );
};

export default FailedPayment;
