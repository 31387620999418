import { Subject } from "rxjs";

export const subject = new Subject();
export const refreshMonitorData = new Subject();
export const loadMoreContact = new Subject();
export const refreshRecentChat = new Subject();

export const messageService = {
  sendSocketConnectionNotifier: (status) =>
    subject.next({ IsConnected: status }),
  clearMessages: () => subject.next(),
};
