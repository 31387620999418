import React, { useState, useLayoutEffect } from "react";
import { BsFilePersonFill } from "react-icons/bs";
import {
  MdOutlineDomainVerification,
  MdDescription,
  MdEmail,
} from "react-icons/md";
import { FaInfo, FaGlobeAmericas } from "react-icons/fa";
import { GiRoundStar } from "react-icons/gi";
import { IoBusiness, IoPersonCircleSharp } from "react-icons/io5";
import { ImPhone, ImLocation } from "react-icons/im";
import {
  RiCloseCircleFill,
  RiEditLine,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { toast } from "react-toastify";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { websiteLinkValidation } from "../utils/websiteLinkValidation";

const WhatsAppBusinessProfile = () => {
  const { wbProfileInfo, updateWbProfileInfo, wbPhoneNumberStatus } =
    useWhatsappContext();
  const { isUserAdminOrSuperadmin } = useUserContext();

  const [editAbout, setEditAbout] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editVertical, setEditVertical] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editWebsites, setEditWebsites] = useState(false);

  const [about, setAbout] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [website1, setWebsite1] = useState("");
  const [website2, setWebsite2] = useState("");
  const [email, setEmail] = useState("");
  const [vertical, setVertical] = useState("");

  const allowedVerticals = [
    "APPAREL",
    "AUTO",
    "BEAUTY",
    "EDU",
    "ENTERTAIN",
    "EVENT_PLAN",
    "FINANCE",
    "GOVT",
    "GROCERY",
    "HEALTH",
    "HOTEL",
    "NONPROFIT",
    "PROF_SERVICES",
    "RESTAURANT",
    "RETAIL",
    "TRAVEL",
    "OTHER",
  ];

  const updateAboutOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ about: about });
    setEditAbout(false);
  };

  const updateAddressOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ address: address });
    setEditAddress(false);
  };

  const updateDescriptionOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ description: description });
    setEditDescription(false);
  };

  const updateVerticalOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ vertical: vertical });
    setEditVertical(false);
  };

  const updateEmailOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ email: email });
    setEditEmail(false);
  };

  const updateWebsitesOnSubmit = (event) => {
    event.preventDefault();

    let websiteFields = [];
    if (website1 !== "") {
      if (websiteLinkValidation(website1)) websiteFields.push(website1);
      else toast.warn("Please enter a valid Website");
    }
    if (website2 !== "") {
      if (websiteLinkValidation(website2)) websiteFields.push(website2);
      else toast.warn("Please enter a valid Website");
    }

    updateWbProfileInfo({ websites: websiteFields });
    setEditWebsites(false);
  };

  useLayoutEffect(() => {
    if (wbProfileInfo?.about) setAbout(wbProfileInfo?.about);
    if (wbProfileInfo?.address) setAddress(wbProfileInfo?.address);
    if (wbProfileInfo?.description) setDescription(wbProfileInfo?.description);
    if (wbProfileInfo?.websites) {
      if (wbProfileInfo?.websites[0]) setWebsite1(wbProfileInfo?.websites[0]);
      if (wbProfileInfo?.websites[1]) setWebsite2(wbProfileInfo?.websites[1]);
    }
    if (wbProfileInfo?.email) setEmail(wbProfileInfo?.email);
    if (wbProfileInfo?.industry) setVertical(wbProfileInfo?.industry);
  }, [wbProfileInfo]);

  return (
    <div className="space-y-5">
      <div className="bg-slate-200 w-full h-fit flex flex-col items-center text-slate-900 rounded-b-xl drop-shadow-md">
        <div className="bg-wabmAiBlue w-full h-32 rounded-t-xl relative z-0">
          <MdOutlineDomainVerification
            size={24}
            title={`Verification Status = ${wbPhoneNumberStatus?.code_verification_status}`}
            className={`absolute bottom-2 right-2 ${
              wbPhoneNumberStatus?.code_verification_status === "VERIFIED"
                ? "text-wabmGreen"
                : wbPhoneNumberStatus?.code_verification_status === "EXPIRED"
                ? "text-red-600"
                : "text-yellow-400"
            }`}
          />
        </div>
        {wbProfileInfo?.profilePic ? (
          <img
            src={wbProfileInfo?.profilePic}
            alt="WhatsApp Profile Pic"
            className="w-32 h-auto -mt-20 rounded-full z-20"
          />
        ) : (
          <IoPersonCircleSharp className="bg-white w-32 h-auto -mt-20 text-wabmBlue drop-shadow-md rounded-full" />
        )}
        <div className="w-full mt-7 px-4 pb-7 text-justify space-y-4">
          <div
            title="Verified Name"
            className="my-input-style-1 h-auto flex items-center gap-3"
          >
            <BsFilePersonFill size={20} />
            <p className="font-semibold">
              {wbPhoneNumberStatus?.verified_name
                ? wbPhoneNumberStatus?.verified_name
                : "-"}
            </p>
          </div>
          <div
            title="Current Phone Number"
            className="my-input-style-1 h-auto flex items-center gap-3"
          >
            <ImPhone size={20} />
            <p className="font-semibold">
              {wbPhoneNumberStatus?.display_phone_number
                ? wbPhoneNumberStatus?.display_phone_number
                : "-"}
            </p>
          </div>
          <div
            title="WhatsApp Quality Rating"
            className="my-input-style-1 h-auto flex items-center gap-3"
          >
            <GiRoundStar size={20} />
            <p
              className={`${
                wbPhoneNumberStatus?.quality_rating === "GREEN"
                  ? "text-wabmGreen"
                  : wbPhoneNumberStatus?.quality_rating === "RED"
                  ? "text-red-500"
                  : "text-yellow-500"
              } font-semibold`}
            >
              {wbPhoneNumberStatus?.quality_rating
                ? wbPhoneNumberStatus?.quality_rating
                : "-"}
            </p>
          </div>

          <div
            title="About Business"
            className="my-input-style-1 h-auto flex gap-3"
          >
            <FaInfo size={20} />
            {editAbout ? (
              <form
                onSubmit={updateAboutOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <input
                  type="text"
                  value={about}
                  placeholder="Enter about your business profile"
                  onChange={(e) => setAbout(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : (
              <p className="flex-1">
                {wbProfileInfo?.about && wbProfileInfo?.about !== ""
                  ? wbProfileInfo?.about
                  : "-"}
              </p>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditAbout(!editAbout)}>
                {editAbout ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
          <div
            title="Business Location"
            className="my-input-style-1 h-auto flex items-center gap-3"
          >
            <ImLocation size={20} />
            {editAddress ? (
              <form
                onSubmit={updateAddressOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <input
                  type="text"
                  value={address}
                  placeholder="Enter your business address"
                  onChange={(e) => setAddress(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : (
              <p className="flex-1">
                {wbProfileInfo?.address && wbProfileInfo?.address !== ""
                  ? wbProfileInfo?.address
                  : "-"}
              </p>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditAddress(!editAddress)}>
                {editAddress ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
          <div
            title="Business Description"
            className="my-input-style-1 h-auto flex justify-between gap-3"
          >
            <MdDescription size={20} />
            {editDescription ? (
              <form
                onSubmit={updateDescriptionOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <input
                  type="text"
                  value={description}
                  placeholder="Enter your business description"
                  onChange={(e) => setDescription(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : (
              <p className="whitespace-break-spaces">
                {wbProfileInfo?.description && wbProfileInfo?.description !== ""
                  ? wbProfileInfo?.description
                  : "-"}
              </p>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditDescription(!editDescription)}>
                {editDescription ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
          <div
            title="Business Website(s)"
            className="my-input-style-1 h-auto flex gap-3"
          >
            <FaGlobeAmericas size={20} />
            {editWebsites ? (
              <form
                onSubmit={updateWebsitesOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <span className="flex flex-col justify-start gap-2">
                  <input
                    type="text"
                    value={website1}
                    placeholder="Enter your business website 1"
                    onChange={(e) => setWebsite1(e.target.value)}
                    className="my-input-style-1 w-full h-8"
                  />
                  <input
                    type="text"
                    value={website2}
                    placeholder="Enter your business website 2"
                    onChange={(e) => setWebsite2(e.target.value)}
                    className="my-input-style-1 w-full h-8"
                  />
                </span>
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : (
              <div className="flex-1">
                {wbProfileInfo?.websites?.map((site, index) => (
                  <p key={index}>{site}</p>
                ))}
              </div>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditWebsites(!editWebsites)}>
                {editWebsites ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
          <div
            title="Business Email"
            className="my-input-style-1 h-auto flex items-center gap-3"
          >
            <MdEmail size={20} />
            {editEmail ? (
              <form
                onSubmit={updateEmailOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <input
                  type="text"
                  value={email}
                  placeholder="Enter your business email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : (
              <p className="flex-1">
                {wbProfileInfo?.email && wbProfileInfo?.email !== ""
                  ? wbProfileInfo?.email
                  : "-"}
              </p>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditEmail(!editEmail)}>
                {editEmail ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
          <div
            title="Business Industry"
            className="my-input-style-1 h-auto flex justify-between gap-3"
          >
            <IoBusiness size={20} />
            {editVertical ? (
              <form
                onSubmit={updateVerticalOnSubmit}
                className="flex items-center gap-2 flex-1"
              >
                <select
                  name="verticalName"
                  value={vertical}
                  onChange={(e) => setVertical(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                >
                  {allowedVerticals.map((item) => (
                    <option key={item}>{item}</option>
                  ))}
                </select>
                <button
                  type="submit"
                  className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
                >
                  <RiCheckboxCircleFill size={20} />
                </button>
              </form>
            ) : wbProfileInfo?.industry ? (
              <p className="flex-1">{wbProfileInfo?.industry}</p>
            ) : (
              <p>-</p>
            )}
            {isUserAdminOrSuperadmin() && (
              <button onClick={() => setEditVertical(!editVertical)}>
                {editVertical ? (
                  <RiCloseCircleFill
                    size={20}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                ) : (
                  <RiEditLine
                    size={20}
                    className="hover:text-wabmBlue transition duration-300 ease-in-out"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppBusinessProfile;
