import React, { useEffect } from "react";

import { useCatalogContext } from "../../contexts/CatalogContextProvider";
import { Loader, CatalogCard } from "..";

const AllCatalogs = () => {
  const {
    isLoading,
    allCatalogsList,
    getSelectedCatalog,
    catalogItems,
    getCatalogItemDetails,
    currentCatalogId,
    getAllCatalogs,
  } = useCatalogContext();

  useEffect(() => {
    // Updating catalog items with additional info
    if (catalogItems?.length > 0) {
      catalogItems?.forEach((item) => {
        getCatalogItemDetails(false, item?.id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCatalogId]);

  useEffect(() => {
    getAllCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-2">
      <div className="space-y-2">
        <h2 className="text-lg font-semibold">
          Please select a Catalog to View
        </h2>
        <div className="space-x-2">
          {allCatalogsList?.length > 0 &&
            allCatalogsList?.map((catalog) => (
              <button
                onClick={() => getSelectedCatalog(false, catalog?.id)}
                key={catalog?.id}
                className={`my-button-style-5 ${
                  currentCatalogId === catalog?.id && "bg-wabmGreen"
                }`}
              >
                {catalog?.name}
              </button>
            ))}
        </div>
      </div>
      {isLoading ? <Loader /> : <CatalogCard catalogItems={catalogItems} />}
    </div>
  );
};

export default AllCatalogs;
