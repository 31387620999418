import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Input,
  Button,
} from "@material-tailwind/react";

import { AppNavbar, Footer } from "../components";
import { useUserContext } from "../contexts/UserContextProvider";
import { passwordStrengthCheck } from "../components/utils/passwordStrengthCheck";
import { emailValidationCheck } from "../components/utils/inputValidations";
import { inputUsernameSanitation } from "../components/utils/inputSanitation";

const PasswordReset = () => {
  const {
    apiCallInitiateResetPassword,
    apiCallConfirmPasswordReset,
    initiatedPasswordReset,
    setInitiatedPasswordReset,
  } = useUserContext();
  const navigate = useNavigate();

  const [corporateId, setCorporateId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [agentEmail, setAgentEmail] = useState("");

  const initiateResetPasswordOnSubmit = (event) => {
    event.preventDefault();
    if (emailValidationCheck(agentEmail)) {
      apiCallInitiateResetPassword(
        corporateId.toLowerCase(),
        agentName.toLowerCase(),
        agentEmail.toLowerCase()
      );
    } else {
      toast.warn("Please check the entered values");
    }
  };

  // State variables for setting up new password
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [alphaNumericOtp, setAlphaNumericOtp] = useState("");

  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [hideNewPasswords, setHideNewPasswords] = useState(true);

  const handleNewPassword = (passwordValue) => {
    const passwordStrength = passwordStrengthCheck(passwordValue);
    setNewPassword1(passwordValue);
    setProgress(passwordStrength.progress);
    setPasswordStrengthMessage(passwordStrength.strength);
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };

  const resetStateVariables = () => {
    setCorporateId("");
    setAgentEmail("");
    setNewPassword1("");
    setNewPassword2("");
    setAlphaNumericOtp("");
    setPasswordStrengthMessage("");
    setProgress("");
    setHideNewPasswords(true);

    setInitiatedPasswordReset(false);
  };

  const confirmPasswordResetOnSubmit = (event) => {
    event.preventDefault();
    if (newPassword1 === newPassword2) {
      apiCallConfirmPasswordReset(newPassword1, alphaNumericOtp);
      setInitiatedPasswordReset(false);
      resetStateVariables();
      setTimeout(() => navigate("/login"), 2000);
    } else {
      toast.warn("New Passwords Mismatch");
    }
  };

  return (
    <div className="bg-[url(../src/images/props/y-so-serious-white.png)] bg-repeat min-h-screen flex flex-col justify-between">
      <AppNavbar />
      <main className="container m-auto px-5 py-20 flex flex-col justify-center lg:flex-row lg:justify-between items-center gap-y-10 lg:gap-y-0">
        <div className="w-full lg:w-3/5 mb-12 flex flex-col justify-center items-center text-center">
          <h1 className="wabm-font-style-1 pb-3 text-wabmBlue">
            Forgot your Password?
          </h1>
          <p className="text-lg">
            Don't worry, it happens. Just reset your password in a jiffy.
          </p>
        </div>

        <Card className="w-full lg:w-2/5">
          <CardHeader
            variant="gradient"
            color="blue"
            className="p-4 grid h-28 place-items-center"
          >
            <Typography variant="h3" color="white">
              Forgot Password
            </Typography>
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            {initiatedPasswordReset ? (
              <form
                onSubmit={confirmPasswordResetOnSubmit}
                className="w-full flex flex-col space-y-5"
              >
                <Input
                  label="OTP"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  placeholder="Please type OTP received on your Agent Email ID"
                  value={alphaNumericOtp}
                  onChange={(e) => setAlphaNumericOtp(e.target.value)}
                  className="my-input-box-1"
                />
                <Input
                  label="New Password"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  type={hideNewPasswords ? "password" : "text"}
                  icon={
                    <button
                      type="button"
                      className=""
                      onClick={() => {
                        setHideNewPasswords(!hideNewPasswords);
                      }}
                    >
                      {!hideNewPasswords ? (
                        <BsFillEyeSlashFill />
                      ) : (
                        <BsFillEyeFill />
                      )}
                    </button>
                  }
                  required
                  placeholder="Please type your New Password"
                  value={newPassword1}
                  onChange={(e) => handleNewPassword(e.target.value)}
                  className="my-input-box-1"
                />
                <Input
                  label="Confirm Password"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  type={hideNewPasswords ? "password" : "text"}
                  icon={
                    <button
                      type="button"
                      className=""
                      onClick={() => {
                        setHideNewPasswords(!hideNewPasswords);
                      }}
                    >
                      {!hideNewPasswords ? (
                        <BsFillEyeSlashFill />
                      ) : (
                        <BsFillEyeFill />
                      )}
                    </button>
                  }
                  required
                  placeholder="Please confirm your New Password"
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  className="my-input-box-1"
                />
                {newPassword1.length !== 0 ? (
                  <div className="bg-white w-full rounded-sm transition duration-300 ease-in-out">
                    <div
                      className="h-1"
                      style={{
                        width: progress,
                        backgroundColor: getActiveColor(
                          passwordStrengthMessage
                        ),
                        transition: "all 0.5s ease-out",
                      }}
                    />

                    <p
                      className="passwordStrengthMessage"
                      style={{ color: getActiveColor(passwordStrengthMessage) }}
                    >
                      Your password strength is {passwordStrengthMessage}
                    </p>
                  </div>
                ) : null}
                <Button variant="gradient" color="blue" type="submit" fullWidth>
                  Confirm New Password
                </Button>
              </form>
            ) : (
              <form
                onSubmit={initiateResetPasswordOnSubmit}
                className="w-full flex flex-col space-y-5"
              >
                <Input
                  label="Corporate ID"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  placeholder="Please type your Corporate ID"
                  value={corporateId}
                  onChange={(e) =>
                    setCorporateId(
                      inputUsernameSanitation(e.target.value).trim()
                    )
                  }
                  className="my-input-box-1"
                />
                <Input
                  label="Agent ID"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  placeholder="Please type your Agent ID"
                  value={agentName}
                  onChange={(e) =>
                    setAgentName(inputUsernameSanitation(e.target.value).trim())
                  }
                  className="my-input-box-1"
                />
                <Input
                  label="Agent Email ID"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  placeholder="Please type your Agent Email ID"
                  value={agentEmail}
                  onChange={(e) => setAgentEmail(e.target.value.trim())}
                  className="my-input-box-1"
                />
                <Button variant="gradient" color="blue" type="submit" fullWidth>
                  Reset Password
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </main>
      <Footer />
    </div>
  );
};

export default PasswordReset;
