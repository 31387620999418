import React, { useState, useEffect } from "react";

import { useCatalogContext } from "../../contexts/CatalogContextProvider";
import { Loader, AllCatalogs, CatalogCard, SeoTags } from "..";

const Catalog = () => {
  const {
    isLoading,
    getSelectedCatalog,
    activeCatalogItems,
    activeCatalogId,
    getCatalogItemDetails,
  } = useCatalogContext();

  const [showAllCatalogs, setShowAllCatalogs] = useState(false);

  useEffect(() => {
    if (!showAllCatalogs) {
      getSelectedCatalog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllCatalogs]);

  useEffect(() => {
    if (
      !showAllCatalogs &&
      activeCatalogId !== "" &&
      activeCatalogItems?.length > 0
    ) {
      activeCatalogItems?.forEach((item) => {
        getCatalogItemDetails(true, item?.id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCatalogId]);

  return (
    <main className="w-full space-y-5">
      <SeoTags title="Catalog ‣ WaBM" />

      <div className="flex-1 my-1 space-y-3">
        <h1 className="text-2xl">WhatsApp Catalog</h1>
        <h3>View, Modify and Manage your WhatsApp Business Catalogs.</h3>
      </div>

      <div className="my-card-style-1 space-y-5">
        <span className="inline-flex gap-5">
          <button
            type="button"
            onClick={() => setShowAllCatalogs(false)}
            className={
              !showAllCatalogs
                ? "my-button-style-1-selected"
                : "my-button-style-5"
            }
          >
            Active Catalog
          </button>
          <button
            type="button"
            onClick={() => setShowAllCatalogs(true)}
            className={
              showAllCatalogs
                ? "my-button-style-1-selected"
                : "my-button-style-5"
            }
          >
            All Catalogs
          </button>
        </span>

        {showAllCatalogs && <AllCatalogs />}
      </div>

      {!showAllCatalogs && (
        <div className="rounded-lg">
          {isLoading ? (
            <Loader />
          ) : (
            <CatalogCard catalogItems={activeCatalogItems} />
          )}
        </div>
      )}
    </main>
  );
};

export default Catalog;
