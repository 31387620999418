import React from "react";

import imagePlaceholder from "../../images/props/imagePlaceholder.png";

const CatalogCard = ({ catalogItems }) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
      {catalogItems?.length > 0 &&
        catalogItems?.map((item) => (
          <div className="rounded-md overflow-hidden border-2 border-slate-300">
            <div className="bg-cover w-full h-48 overflow-hidden">
              {item?.image ? (
                <img src={item?.image} alt={item?.name} />
              ) : (
                <img src={imagePlaceholder} alt={item?.name} />
              )}
            </div>
            <div className="px-3 py-2">
              <p className="text-lg font-semibold">{item?.price}</p>
              <p className="">{item?.name}</p>
              <p className="text-sm">
                {item?.description ? item?.description : "..."}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CatalogCard;
