import React, { useState, useEffect, useRef } from "react";
import { HiUserGroup } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { PiNumberSquareSevenFill } from "react-icons/pi";
import { Ri24HoursLine } from "react-icons/ri";
import { MdPermContactCalendar } from "react-icons/md";
import {
  ButtonGroup,
  Button,
  Drawer,
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Select,
  Option,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";

import {
  UploadContacts,
  ManageAllContacts,
  ManageContactGroups,
  CreateContact,
  CustomLoader,
  SeoTags,
  CreateContactGroup,
} from "..";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { loadMoreContact } from "../../data/messageNotifierServices";

const Contacts = () => {
  const {
    importedContacts,
    isUploaded,
    setResetUploadComponent,
    apiCallUploadContacts,
    apiCallFetchUserContacts,
    apiCallFetchTablePaging,
    contactsInsights,
    apiCallContactsInsights,
    contactGroups,
    apiCallFetchContactGroups,
    isLoading,
  } = useContactsContext();

  const [openContactGroupsDrawer, setOpenContactGroupsDrawer] = useState(false);
  const [openAllContactsDrawer, setOpenAllContactsDrawer] = useState(false);
  const [assignUploadedContactsToGroup, setAssignUploadedContactsToGroup] =
    useState(false);
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);
  const [preferredGroup, setPreferredGroup] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);

  const groupBodyRef = useRef(null);
  const contactBodyRef = useRef(null);
  let subscribe;

  const handleUploadOnClick = async () => {
    setResetUploadComponent(true);
    apiCallUploadContacts(preferredGroup);
  };

  const callbackHideContactGroup = () => {
    setOpenContactGroupsDrawer(false);
  };

  const callbackHideAllContacts = () => {
    subscribe?.unsubscribe();
    setOpenAllContactsDrawer(false);
  };

  useEffect(() => {
    apiCallFetchUserContacts(1, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploaded]);

  useEffect(() => {
    apiCallContactsInsights();
    apiCallFetchContactGroups();
    if (openAllContactsDrawer) {
      apiCallFetchUserContacts(1, "");
      apiCallFetchTablePaging("");
      contactBodyRef.current.focus();
    } else {
      groupBodyRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscribe = loadMoreContact?.subscribe((data) => {
      setOpenContactGroupsDrawer(false);
      setOpenAllContactsDrawer(data?.drawerStatus);
      setGroupName(data?.groupName);
      setSelectedGroup(data?.contactInGroup);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAllContactsDrawer]);

  return (
    <main className="space-y-5">
      <SeoTags title="Contacts ‣ WaBM" />

      {isLoading && isUploaded && <CustomLoader />}
      {/* All Contacts Drawer | reverse cond. to overcome overlapping of drawers at same position */}
      {!openContactGroupsDrawer && (
        <Drawer
          ref={contactBodyRef}
          placement="bottom"
          open={openAllContactsDrawer}
          onClose={() => setOpenAllContactsDrawer(false)}
          size="91vh"
          className="rounded-t-xl z-[9996]"
        >
          <ManageAllContacts
            callbackHideAllContacts={callbackHideAllContacts}
            drawerStatus={openAllContactsDrawer}
            groupName={groupName}
            contactInGroup={selectedGroup}
          />
        </Drawer>
      )}

      {/* Contact Groups Drawer */}
      {!openAllContactsDrawer && (
        <Drawer
          ref={groupBodyRef}
          placement="bottom"
          open={openContactGroupsDrawer}
          onClose={() => setOpenContactGroupsDrawer(false)}
          size="91vh"
          className="p-5 rounded-t-xl z-[9996]"
        >
          <ManageContactGroups
            callbackHideContactGroup={callbackHideContactGroup}
            drawerStatus={openContactGroupsDrawer}
          />
        </Drawer>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
        <div className="col-span-3 space-y-5">
          <div className="space-y-2">
            <h1 className="text-2xl">Manage Contacts</h1>
            <p>
              Upload new contacts or manage the existing ones. Maybe manage your
              contact groups?
            </p>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="wbm-card-1 bg-blue-400 space-y-2">
              <MdPermContactCalendar size={30} />
              <p className="">Total Contacts</p>
              <p className="text-3xl">{contactsInsights?.totalContacts}</p>
            </div>
            <div className="wbm-card-1 bg-green-400 space-y-2">
              <HiUserGroup size={30} />
              <p className="">Total Saved Groups</p>
              <p className="text-3xl">{contactsInsights?.totalGroups}</p>
            </div>
            <div className="wbm-card-1 bg-amber-400 space-y-2">
              <Ri24HoursLine size={30} />
              <p className="">Contacted in Last 24 hrs</p>
              <p className="text-3xl">
                {contactsInsights?.totalLast24Contacted}
              </p>
            </div>
            <div className="wbm-card-1 bg-indigo-400 space-y-2">
              <PiNumberSquareSevenFill size={30} />
              <p className="">Contacted in Last 7 days</p>
              <p className="text-3xl">
                {contactsInsights?.totalContactedLast7Days}
              </p>
            </div>
          </div>

          <div className="space-y-3">
            <p>Click one of the following buttons view the required data.</p>
            <ButtonGroup variant="gradient" color="blue">
              <Button
                type="button"
                onClick={() => {
                  setSelectedGroup([]);
                  setGroupName("");
                  apiCallFetchContactGroups();
                  setOpenAllContactsDrawer(true);
                }}
                className="w-48"
              >
                All Contacts
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenContactGroupsDrawer(true);
                  apiCallFetchContactGroups();
                }}
                className="w-48"
              >
                Saved Groups
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className="col-span-2 space-y-10">
          <UploadContacts />
          <CreateContact />
        </div>
      </div>

      {isUploaded && (
        <Drawer
          placement="bottom"
          open={isUploaded}
          // onClose={() => setOpenContactsUploadDrawer(false)}
          size="91vh"
          className="rounded-t-xl z-[9996]"
        >
          {importedContacts !== null && importedContacts !== undefined && (
            <Card className="h-full w-full">
              <CardHeader
                floated={false}
                shadow={false}
                className="rounded-none"
              >
                <div className="mb-4 space-y-3">
                  <Typography variant="h5" color="blue-gray">
                    Data Preview
                  </Typography>
                  <p className="mt-1">
                    These are contact details from your attached Excel Sheet.
                  </p>
                </div>
              </CardHeader>
              <CardBody className="h-full px-0 overflow-scroll">
                <table className="w-full min-w-max table-auto">
                  <thead>
                    <tr>
                      {Object?.keys(importedContacts[0])?.map(
                        (value, index) => (
                          <th
                            key={index}
                            className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                          >
                            <Typography
                              variant="small"
                              className="leading-none font-bold"
                            >
                              {value}
                            </Typography>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {importedContacts?.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row)?.map((item, idx) => (
                          <td>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              key={idx}
                              className="font-normal"
                            >
                              {item}
                            </Typography>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>

              <CardFooter className="mx-auto w-full p-2 flex justify-between items-center gap-5">
                <div className="inline-flex gap-2">
                  {assignUploadedContactsToGroup ? (
                    <Button
                      type="button"
                      variant="gradient"
                      color="red"
                      onClick={() => {
                        setAssignUploadedContactsToGroup(false);
                        setPreferredGroup(0);
                      }}
                      className="whitespace-nowrap"
                    >
                      <span>Cancel</span>
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="gradient"
                      color="blue"
                      onClick={() => setAssignUploadedContactsToGroup(true)}
                      className="whitespace-nowrap"
                    >
                      <span>Assign Contacts to Group</span>
                    </Button>
                  )}

                  {assignUploadedContactsToGroup && (
                    <div className="flex flex-row gap-3 items-center">
                      <Select
                        variant="outlined"
                        label="Add to Contact Group (optional)"
                        color="blue"
                        value={preferredGroup}
                        onChange={(e) => setPreferredGroup(e)}
                        className="w-full md:w-96"
                      >
                        {contactGroups?.map((group) => (
                          <Option
                            key={group?.groupId}
                            value={group?.groupId}
                            className="hover:bg-blue-300 focus:bg-blue-300"
                          >
                            {group?.groupName} (
                            {group?.numberOfContacts
                              ? group.numberOfContacts
                              : 0}{" "}
                            contacts)
                          </Option>
                        ))}
                      </Select>
                      <Button
                        type="button"
                        variant="gradient"
                        color="blue"
                        onClick={() => setOpenGroupCreationDialog(true)}
                        className="whitespace-nowrap w-full md:w-52"
                      >
                        <span>Create New Group</span>
                      </Button>
                    </div>
                  )}
                </div>

                <Button
                  type="button"
                  variant="gradient"
                  color="green"
                  onClick={handleUploadOnClick}
                >
                  <span>Upload</span>
                </Button>
                <button
                  onClick={() => setResetUploadComponent(true)}
                  className="my-button-style-cancel absolute top-5 right-5"
                >
                  <IoClose size={18} />
                </button>
              </CardFooter>
            </Card>
          )}
        </Drawer>
      )}
      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <CreateContactGroup
            callbackHideContactGroup={() => {
              apiCallFetchContactGroups();
              setOpenGroupCreationDialog(!openGroupCreationDialog);
            }}
          ></CreateContactGroup>
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default Contacts;
