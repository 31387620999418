import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig.js";
import {
  emailValidationCheck,
  phoneNumberValidationCheck,
} from "./utils/inputValidations.js";
import {
  phoneNumberSanitation,
  inputNameSanitation,
  inputMessageSanitation,
} from "./utils/inputSanitation.js";

const ContactForm = ({ parentCallback }) => {
  const [formValues, setFormValues] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    message: "",
  });
  const [messageStatusSent, setMessageStatusSent] = useState(false);

  let formErrors = {
    userName: "",
    userEmail: "",
    userPhone: "",
  };
  let hasError = true;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let sanitizedValue = "";

    if (name === "userPhone") {
      if (value.length > 0) sanitizedValue = "+";
      sanitizedValue += phoneNumberSanitation(value);
    } else if (name === "userName") {
      sanitizedValue = inputNameSanitation(value);
    } else if (name === "message") {
      sanitizedValue = inputMessageSanitation(value);
    } else {
      sanitizedValue = value;
    }

    setFormValues({ ...formValues, [name]: sanitizedValue });
  };

  const validateInputs = (values) => {
    const errors = {};
    let numOfErrors = 0;

    if (!values.userName) {
      errors.userName = "Name is Required!";
      numOfErrors++;
    }

    if (!values.userEmail) {
      errors.userEmail = "Email is Required!";
    } else if (!emailValidationCheck(values.userEmail)) {
      errors.userEmail = "Enter a valid Email Address!";
      numOfErrors++;
    }

    if (!values.userPhone) {
      errors.userPhone = "Phone is Required!";
      numOfErrors++;
    } else if (!phoneNumberValidationCheck(values.userPhone)) {
      errors.userPhone = "Enter a valid Phone Number!";
      numOfErrors++;
    }

    if (numOfErrors === 0) {
      hasError = false;
    }

    return errors;
  };

  const sendRequestCallbackEmail = (e) => {
    e.preventDefault();
    formErrors = validateInputs(formValues);

    if (hasError === false) {
      setMessageStatusSent(!messageStatusSent);
      parentCallback(!messageStatusSent);

      emailjs
        .send(
          appSettings.emailjsServiceId,
          appSettings.emailjsTemplateId,
          formValues,
          appSettings.emailjsPublicKey
        )
        .then(
          () => {
            toast.success("Message Sent Successfully");
          },
          (err) => {
            toast.error(JSON.stringify(err));
          }
        );
    } else {
      toast.warn(formErrors[Object.keys(formErrors)[0]]);
    }
  };

  return (
    <form
      onSubmit={sendRequestCallbackEmail}
      className="space-y-5 drop-shadow-lg"
    >
      <div className="flex-1">
        <label htmlFor="message" className="text-white">
          Your Name <strong className="text-red-600">*</strong>
        </label>
        <input
          type="text"
          name="userName"
          placeholder="What can we call you?"
          value={formValues.userName}
          required
          onChange={handleInputChange}
          className="my-input-style-1 w-full"
        />
      </div>

      <div className="flex-1">
        <label htmlFor="userEmail" className="text-white">
          Your Email ID <strong className="text-red-600">*</strong>
        </label>
        <input
          type="email"
          name="userEmail"
          placeholder="something@email.com"
          value={formValues.userEmail}
          required
          onChange={handleInputChange}
          className="my-input-style-1 w-full"
        />
      </div>

      <div className="flex-1">
        <label htmlFor="userPhone" className="text-white">
          Your Contact No. <strong className="text-red-600">*</strong>
        </label>
        <input
          type="tel"
          name="userPhone"
          placeholder="+91 987654321"
          value={formValues.userPhone}
          required
          onChange={handleInputChange}
          className="my-input-style-1 w-full"
        />
      </div>

      <div>
        <label htmlFor="message" className="text-white">
          Your Message
        </label>
        <textarea
          type="text"
          name="message"
          placeholder="Please tell us, how can we help you?"
          value={formValues.message}
          onChange={handleInputChange}
          className="my-input-style-1 w-full h-36 py-3"
        />
      </div>

      <input
        type="submit"
        value="Request A Call Back"
        className="my-page-button-layout bg-wabmAiBlue hover:bg-black w-full md:w-fit hover:text-wabmGreen"
      />
    </form>
  );
};

export default ContactForm;
