import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";

import { passwordStrengthCheck } from "../utils/passwordStrengthCheck";
import { useUserContext } from "../../contexts/UserContextProvider";

const ChangePassword = ({ isChangePasswordCallback }) => {
  const { apiCallChangePassword } = useUserContext();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState("");
  const [progress, setProgress] = useState("");

  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPasswords, setHideNewPasswords] = useState(true);

  const handleNewPassword = (passwordValue) => {
    const passwordStrength = passwordStrengthCheck(passwordValue);
    setNewPassword1(passwordValue);
    setProgress(passwordStrength.progress);
    setPasswordStrengthMessage(passwordStrength.strength);
  };

  const changePasswordOnSubmit = (event) => {
    event.preventDefault();
    if (newPassword1 === newPassword2) {
      apiCallChangePassword(currentPassword, newPassword1);
      isChangePasswordCallback(false);
    } else {
      toast.warn("New Passwords Mismatch");
    }
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };

  return (
    <form onSubmit={changePasswordOnSubmit} className="space-y-3">
      <span className="bg-white flex">
        <input
          value={currentPassword}
          required
          onChange={({ target }) => {
            setCurrentPassword(target.value);
          }}
          type={hideCurrentPassword ? "password" : "text"}
          className="my-input-style-1 w-full drop-shadow-md"
          placeholder="Enter Current Password"
        />
        <button
          type="button"
          className="my-input-style-1"
          onClick={() => {
            setHideCurrentPassword(!hideCurrentPassword);
          }}
        >
          {!hideCurrentPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
        </button>
      </span>
      <span className="bg-white flex">
        <input
          value={newPassword1}
          required
          onChange={({ target }) => {
            handleNewPassword(target.value);
          }}
          type={hideNewPasswords ? "password" : "text"}
          className="my-input-style-1 w-full drop-shadow-md"
          placeholder="Enter New Strong Password"
        />
        <button
          type="button"
          className="my-input-style-1"
          onClick={() => {
            setHideNewPasswords(!hideNewPasswords);
          }}
        >
          {!hideNewPasswords ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
        </button>
      </span>
      <span className="bg-white flex">
        <input
          value={newPassword2}
          required
          onChange={({ target }) => {
            setNewPassword2(target.value);
          }}
          type={hideNewPasswords ? "password" : "text"}
          className="my-input-style-1 w-full drop-shadow-md"
          placeholder="Repeat New Strong Password"
        />
        <button
          type="button"
          className="my-input-style-1"
          onClick={() => {
            setHideNewPasswords(!hideNewPasswords);
          }}
        >
          {!hideNewPasswords ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
        </button>
      </span>
      {newPassword1.length !== 0 ? (
        <div className="bg-white w-full px-4 py-2 rounded-sm transition duration-300 ease-in-out">
          <div
            className="h-1"
            style={{
              width: progress,
              backgroundColor: getActiveColor(passwordStrengthMessage),
              transition: "all 0.5s ease-out",
            }}
          />

          <p
            className="passwordStrengthMessage"
            style={{ color: getActiveColor(passwordStrengthMessage) }}
          >
            Your password is {passwordStrengthMessage}
          </p>
        </div>
      ) : null}
      <span className="flex items-center gap-3">
        <button
          type="button"
          onClick={() => isChangePasswordCallback(false)}
          className="my-button-style-cancel"
        >
          <IoClose size={24} />
        </button>
        <input
          type="submit"
          value="Confirm New Password"
          className="my-button-style-5"
        />
      </span>
    </form>
  );
};

export default ChangePassword;
