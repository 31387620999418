import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import { UserContextProvider } from "./contexts/UserContextProvider";
import { FollowupContextProvider } from "./contexts/FollowupContextProvider";
import { ConversationContextProvider } from "./contexts/ConversationContextProvider";
import StartFromTop from "./helpers/StartFromTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserContextProvider>
      <ConversationContextProvider>
        <FollowupContextProvider>
          <StartFromTop />
          <App />
        </FollowupContextProvider>
      </ConversationContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);
