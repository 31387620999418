import React, { useState, useRef, useEffect } from "react";
import { usePapaParse } from "react-papaparse";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { FiUploadCloud, FiDownload } from "react-icons/fi";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { contactsHeaderKeys } from "../../data/contactsHeaderKeys";
import { contactUploadConfig } from "../../config/appConfig";
import { getCurrentDateTime } from "../utils/timeStampConversion";

const UploadContacts = () => {
  const { readString } = usePapaParse();
  const [fileContent, setFileContent] = useState(null);
  const [filePresent, setFilePresent] = useState(false);
  const [uploadedFileHasErrors, setUploadedFileHasErrors] = useState(false);
  const {
    setImportedContacts,
    setIsUploaded,
    resetUploadComponent,
    setResetUploadComponent,
    uploadDataError,
    setUploadDataError,
  } = useContactsContext();

  const fileInputRef = useRef(null);
  let errorDataArray = [];
  const resetFileStates = () => {
    setFileContent(null);
    setFilePresent(false);
    setUploadedFileHasErrors(false);
    setUploadDataError([]);
  };

  const handleFileOnChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFilePresent(true);
      setFileContent(file);
      e.target.value = null;
    }
  };

  const validateContactsData = (jsonObject, index) => {
    const skippedContacts = [];
    const errorDataArray = []; // Ensure this is defined

    const countryCodeRegex = /^[0-9]{1,3}$/g;
    const phoneNumberRegex = /^[0-9]{7,14}$/g;
    const headersToCheckForEmpty = [
      "first_name",
      "country_code",
      "phone_number",
    ];

    const addError = (field, error) => {
      errorDataArray.push({
        Title: field,
        Field: field,
        Error: error,
        RowNumber: index,
      });
    };

    const checkField = (field, regex, transform = (value) => value) => {
      if (!jsonObject.hasOwnProperty(field)) {
        addError(field, `${field} header does not exist`);
        return;
      }
      const value = jsonObject[field].trim().replace(/\s+$/, ""); // Trim end spaces
      if (headersToCheckForEmpty.includes(field) && !value) {
        addError(field, `${field} is empty`);
      } else if (regex && !regex.test(value)) {
        addError(field, `${field} is invalid`);
      } else {
        jsonObject[field] = transform(value);
      }
    };

    const transformYesNoToNumber = (value) =>
      value.toLowerCase().trim() === "yes" ? 1 : 0;

    // Skip data if phone_number field is missing
    if (!jsonObject.hasOwnProperty("phone_number")) {
      skippedContacts.push(jsonObject);
      return null;
    }

    // Trim spaces and check fields
    checkField("first_name");
    checkField("last_name");
    checkField("country_code", countryCodeRegex, (value) =>
      value.replace(/\s/g, "")
    );
    checkField("phone_number", phoneNumberRegex, (value) =>
      value.replace(/\s/g, "")
    );

    // Converting Yes or No to Number
    if (jsonObject.hasOwnProperty("wa_opt_in")) {
      jsonObject["wa_opt_in"] = transformYesNoToNumber(jsonObject["wa_opt_in"]);
    }
    if (jsonObject.hasOwnProperty("is_blocked")) {
      jsonObject["is_blocked"] = transformYesNoToNumber(
        jsonObject["is_blocked"]
      );
    }
    return jsonObject;
  };

  const downloadErrorExcelOnClick = () => {
    const worksheet = XLSX.utils.json_to_sheet(uploadDataError);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "contact_errors.xlsx");
    resetFileStates();
    setIsUploaded(false);
  };

  const handleUploadOnSubmit = (e) => {
    e.preventDefault();

    readString(fileContent, {
      worker: true,
      skipEmptyLines: true,
      header: true,
      complete: (results) => {
        if (
          results?.data.length > 0 &&
          results?.data.length <= contactUploadConfig.maximumUploadContact
        ) {
          const contactArray = results?.data.map((contact, index) => {
            if (
              errorDataArray.length <= contactUploadConfig.maximumUploadError
            ) {
              return validateContactsData(contact, index);
            } else {
              return contact;
            }
          });

          if (errorDataArray.length === 0) {
            setIsUploaded(true);
            setImportedContacts(contactArray);
            setFilePresent(false);
          } else {
            setUploadDataError(errorDataArray);
            setFilePresent(false);
            setUploadedFileHasErrors(true);
          }
        } else {
          if (results?.data.length === 0) {
            toast.error("Uploaded contacts file is empty");
            setFilePresent(false);
          } else if (
            results?.data.length > contactUploadConfig.maximumUploadContact
          ) {
            toast.error(
              `Maximum allowed no. of contacts for uploading is ${contactUploadConfig.maximumUploadContact}`
            );
            setFilePresent(false);
          }
        }
      },
    });
  };

  const downloadContactsTemplateCsv = (e) => {
    e.preventDefault();
    const fileName = `wabm_contacts_${getCurrentDateTime()}.csv`;
    const blob = new Blob([contactsHeaderKeys], { type: "text/csv" });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  useEffect(() => {
    if (resetUploadComponent) {
      setFilePresent(false);
      setFileContent(null);
      setIsUploaded(false);
      setImportedContacts(null);
      setResetUploadComponent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetUploadComponent]);

  return (
    <>
      <div className="wbm-card-2 text-center space-y-5">
        <p className="text-sm text-slate-500 dark:text-slate-400">
          Download contacts spreadsheet, populate it with desired data and
          upload.
        </p>
        <div className="inline-flex gap-2">
          <button
            type="button"
            onClick={downloadContactsTemplateCsv}
            className="my-secondary-button text-sm"
          >
            Excel Template <FiDownload className="w-5" />
          </button>
          <input
            type="file"
            accept=".csv, .xls, .xlsx"
            onChange={handleFileOnChange}
            ref={fileInputRef}
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="my-secondary-button text-sm"
          >
            Upload Excel <FiUploadCloud className="w-5" />
          </button>
        </div>
      </div>

      <Dialog open={filePresent} handler={() => setFilePresent(!filePresent)}>
        <span className="flex justify-between gap-3">
          <DialogHeader>Contacts Sheet Upload</DialogHeader>
        </span>
        <form onSubmit={handleUploadOnSubmit}>
          <DialogBody className="space-y-2">
            <p>Name : {fileContent?.name}</p>
            <p>File Size : {(fileContent?.size / 1024).toFixed(2)} KB</p>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="text"
              color="red"
              onClick={() => resetFileStates()}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button type="submit" variant="gradient" color="green">
              <span>Check & Upload</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>

      <Dialog
        open={uploadedFileHasErrors}
        handler={() => setUploadedFileHasErrors(!uploadedFileHasErrors)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Uploaded Contact has some Errors</DialogHeader>
        </span>
        <DialogBody className="space-y-2">
          <p>
            Uploaded contact file contains some errors. Please check the{" "}
            <strong>contact_errors.xlsx</strong> file to learn more.
          </p>
        </DialogBody>
        <DialogFooter>
          <Button
            type="button"
            onClick={downloadErrorExcelOnClick}
            variant="gradient"
            color="green"
          >
            <span>Download Error File</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default UploadContacts;
