import React from "react";

import { AppNavbar, Footer } from "../components";
import { contactLinks } from "../config/appConfig";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between gap-5 overflow-hidden">
      <AppNavbar />

      <main className="container mx-auto px-5 py-20 space-y-5">
        <h1 className="font-Montserrat text-wabmBlue text-3xl text-center font-bold">
          Terms and Conditions
        </h1>
        <div className="space-y-3">
          <p className="text-justify">
            Welcome to WaBM, a WhatsApp business API provider app designed to
            provide marketing opportunities to businesses. By accessing or using
            our app, you agree to be bound by these terms and conditions. If you
            do not agree to these terms and conditions, please do not use our
            app.
          </p>
          <h3 className="font-Montserrat text-lg">1. Use of WaBM</h3>
          <p className="text-justify">
            WaBM is intended for businesses to use as a marketing platform to
            communicate with their customers via WhatsApp. Users are responsible
            for their own compliance with local laws and regulations related to
            communication with customers.
          </p>
          <h3 className="font-Montserrat text-lg">2. User Accounts</h3>
          <p className="text-justify">
            To use WaBM, users must register for an account and provide accurate
            and complete information. Users are responsible for maintaining the
            confidentiality of their account information and are liable for any
            activities that occur under their account.
          </p>
          <h3 className="font-Montserrat text-lg">3. Prohibited Activities</h3>
          <p className="text-justify">
            Users may not use WaBM for any illegal or unauthorized purpose.
            Users may not engage in any activity that interferes with or
            disrupts the operation of WaBM or its services.
          </p>
          <h3 className="font-Montserrat text-lg">4. Intellectual Property</h3>
          <p className="text-justify">
            WaBM and its content are protected by copyright, trademark, and
            other laws. Users may not modify, distribute, reproduce, or create
            derivative works of WaBM or its content without our written
            permission.
          </p>
          <h3 className="font-Montserrat text-lg">5. Indemnification</h3>
          <p className="text-justify">
            Users agree to indemnify and hold harmless WaBM and its affiliates,
            officers, employees, and agents from any claims, damages, or
            expenses arising out of or related to their use of WaBM.
          </p>
          <h3 className="font-Montserrat text-lg">
            6. Disclaimer of Warranties
          </h3>
          <p className="text-justify">
            WaBM and its services are provided on an "as is" and "as available"
            basis. We do not warrant that WaBM will be uninterrupted or
            error-free or that any defects will be corrected.
          </p>
          <h3 className="font-Montserrat text-lg">
            7. Limitation of Liability
          </h3>
          <p className="text-justify">
            In no event shall WaBM or its affiliates, officers, employees, or
            agents be liable for any direct, indirect, incidental, special, or
            consequential damages arising out of or related to the use of WaBM.
          </p>
          <h3 className="font-Montserrat text-lg">
            8. Changes to Terms and Conditions
          </h3>
          <p className="text-justify">
            We may update these terms and conditions from time to time. Users
            will be notified of any material changes to these terms and
            conditions by email or by posting a notice on our website.
          </p>
          <h3 className="font-Montserrat text-lg">9. Governing Law</h3>
          <p className="text-justify">
            These terms and conditions are governed by and construed in
            accordance with the laws of the jurisdiction in which WaBM operates.
          </p>
          <h3 className="font-Montserrat text-lg">10. Contact Us</h3>
          <p className="text-justify">
            If you have any questions or concerns about these terms and
            conditions or our app, please contact us at{" "}
            {contactLinks.supportMail}
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
