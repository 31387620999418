import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
import { Card, CardBody, Typography } from "@material-tailwind/react";

import { usePaymentsContext } from "../../contexts/PaymentsContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { isoTimestampToLocalWithFormat } from "../utils/timeStampConversion";
import { SeoTags } from "..";

const ManageSubscription = () => {
  const { getSubscriptionDetails } = usePaymentsContext();

  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState([]);

  const loadSubscription = async () => {
    const subscriptions = await getSubscriptionDetails();
    setSubscriptionHistory(subscriptions?.result);
    setCurrentSubscription(subscriptions?.result[0]);
  };

  const subscriptionsTableColumn = [
    {
      name: "Current Package",
      selector: (row) => row?.package_name,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => isoTimestampToLocalWithFormat(row?.purchaseDate_dt),
      sortable: true,
    },
    {
      name: "Renewal Date",
      selector: (row) => isoTimestampToLocalWithFormat(row?.renewalDate_dt),
      sortable: true,
    },
    {
      name: "Conversation Allowed",
      selector: (row) => row?.conv_allowed_count,
      sortable: true,
      center: true,
    },
    {
      name: "Payment Mode",
      selector: (row) => row?.paymentMode_c?.toUpperCase(),
      sortable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row?.remarks_c,
      sortable: true,
    },
  ];

  useEffect(() => {
    loadSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SeoTags title="Subscriptions ‣ WaBM" />

      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Subscriptions</h1>
          <h3>
            These are details about all your current and past subscriptions.
          </h3>
        </div>
        <Card color="blue" className="text-white">
          <CardBody className="space-y-3">
            <Typography variant="h5" color="blue-gray">
              Current Subscription
            </Typography>

            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
              <span>Current Package: {currentSubscription?.package_name}</span>
              <span>
                Purchase Date:{" "}
                {currentSubscription?.purchaseDate_dt &&
                  isoTimestampToLocalWithFormat(
                    currentSubscription?.purchaseDate_dt
                  )}
              </span>
              <span>
                Renewal Date:{" "}
                {currentSubscription?.renewalDate_dt &&
                  isoTimestampToLocalWithFormat(
                    currentSubscription?.renewalDate_dt
                  )}
              </span>
              <span>
                Conversation Allowed: {currentSubscription?.conv_allowed_count}
              </span>
              <span>
                Payment Mode:{" "}
                {currentSubscription?.paymentMode_c?.toUpperCase()}
              </span>
              <span>
                Subscription Status:{" "}
                <span
                  className={
                    currentSubscription?.SubscriptionStatus === "Active"
                      ? "text-green-400"
                      : "text-red-500"
                  }
                >
                  {currentSubscription?.SubscriptionStatus}
                </span>
              </span>
            </div>
          </CardBody>
        </Card>

        <div className="mt-5">
          {currentSubscription?.SubscriptionStatus !== "Active" && (
            <NavLink to="/dashboard/subscribe">
              <Button variant="gradient" color="green">
                Subscribe Now
              </Button>
            </NavLink>
          )}
        </div>

        <div className="wbm-card-3 space-y-3">
          <h2 className="font-Montserrat text-lg">Subscription History</h2>
          <div className="rounded-md overflow-auto">
            <DataTable
              columns={subscriptionsTableColumn}
              data={subscriptionHistory}
              customStyles={subscriptionTableStyles}
              fixedHeader
              fixedHeaderScrollHeight="60vh"
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default ManageSubscription;

const subscriptionTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
