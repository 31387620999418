import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useUserContext } from "./UserContextProvider";
import { appSettings } from "../config/appConfig";
import { refreshMonitorData } from "../data/messageNotifierServices";

const ContactsContext = createContext();

export const ContactsContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;
  const { currentUserInfo } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [importedContacts, setImportedContacts] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [resetUploadComponent, setResetUploadComponent] = useState(false);

  const [contactsInsights, setContactsInsights] = useState();
  const [dbContacts, setDbContacts] = useState();
  const [selectedGroupContacts, setSelectedGroupContacts] = useState([]);
  const [contactHeaders, setContactHeaders] = useState();

  const [contactGroups, setContactGroups] = useState([]);
  const [uploadDataError, setUploadDataError] = useState([]);

  const [tablePaging, setTablePaging] = useState();
  const [totalPages, setTotalPages] = useState([]);

  const [scheduledCampaignsTrackingData, setScheduledCampaignsTrackingData] =
    useState([]);

  const apiCallContactsInsights = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/contacts-insights`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
          setIsLoading(false);
        }
        setContactsInsights(response.contactsInsights);
      })
      .catch((err) => {
        toast.error("Unable to get Contact Insights");
        setIsLoading(false);
      });
  };

  const apiCallUploadContacts = (preferredGroup) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/upload-user-contacts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contactsArray: importedContacts,
        groupId: preferredGroup,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else if (response.message) {
          toast.success(response.message);
          apiCallContactsInsights();
        } else {
          toast.warn("Contacts upload status unavailable");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to upload contacts");
        setIsLoading(false);
      });
  };

  const apiCallCreateContact = (contactInfo) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/create-contact`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactInfo),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          apiCallContactsInsights();
          toast.success(response.message);
        } else {
          toast.warn(
            response?.warning
              ? response.warning
              : "Contact creation status unknown"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to create contact");
      });
  };

  const apiCallUpdateContact = (contactInfo, pageSize) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/update-contact`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactInfo),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          apiCallFetchUserContacts(1, "", pageSize);
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to update contact");
        setIsLoading(false);
      });
  };

  const apiCallDeleteSelectedContacts = (contactsToBeDeleted, pageSize) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/delete-selected-contacts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactsToBeDeleted),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else if (response.message) {
          apiCallFetchUserContacts(1, "", pageSize);
          apiCallContactsInsights();
          toast.success(response.message);
        } else {
          toast.warn("Contacts deletion status unknown");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Couldn't delete selected contacts");
        setIsLoading(false);
      });
  };

  const apiCallFetchUserContacts = async (
    pageNumber,
    searchText,
    pageSize,
    include
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/fetch-user-contacts`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageNumber, searchText, pageSize, include }),
      });

      const data = await response.json();

      if (data?.Error) {
        toast.error(data.Error);
        return null;
      } else {
        setDbContacts(data);
        return data;
      }
    } catch (error) {
      toast.error("Unable to get contacts data");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const apiCallFetchTablePaging = (searchText, pageSize) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/fetch-table-paging`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchText: searchText, pageSize: pageSize }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          let pages = [];
          for (let i = 1; i <= parseInt(response[0].pageNumber); i++) {
            pages.push(i);
          }
          setTotalPages(pages);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to get contacts");
        setIsLoading(false);
      });
  };

  const apiCallFetchContactGroups = async () => {
    setIsLoading(true);
    try {
      const resp = await fetch(`${baseUrl}/api/fetch-contact-groups`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (resp.status === 200) {
        const data = await resp.json();
        setContactGroups(data);
      } else {
        setContactGroups([]);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch contact groups");
      setIsLoading(false);
    }
  };

  const apiCallFetchGroupContacts = (contactGroupName, searchContact) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/fetch-group-contacts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contactGroupName: contactGroupName,
        searchText: searchContact,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setSelectedGroupContacts(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.Error);
        setIsLoading(false);
      });
  };

  const apiCallAddContactGroup = async (newContactGroupName) => {
    setIsLoading(true);
    try {
      const resp = await fetch(`${baseUrl}/api/add-contact-group`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newContactGroupName, currentUserInfo }),
      });

      const response = await resp.json();

      if (response?.message) {
        toast.success(response.message);
        setIsLoading(false);
        return true;
      } else {
        toast.error(response?.Error);
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      toast.error("Failed to create contact group");
      setIsLoading(false);
      return false;
    }
  };

  const apiCallDeleteContactGroup = async (groupNameToBeDeleted) => {
    setIsLoading(true);
    try {
      const resp = await fetch(`${baseUrl}/api/delete-contact-group`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupNameToBeDeleted }),
      });

      const response = await resp.json();

      if (response?.message) {
        toast.success(response.message);
        setIsLoading(false);
        return true;
      } else {
        toast.error(response?.Error);
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      toast.error("Couldn't delete contact group");
      setIsLoading(false);
      return false;
    }
  };

  const apiCallAssignContactGroups = (contactsSelected, selectedGroupName) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/assign-contact-groups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contactsSelected,
        selectedGroupName,
      }),
    })
      .then((res) => res.json())
      .then(async (response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success(response.message);
          await apiCallFetchContactGroups();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Couldn't assign contact group");
        setIsLoading(false);
      });
  };

  const apiCallRemoveContactsFromGroup = (
    contactsSelected,
    selectedGroupName
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/remove-contacts-from-contact-group`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contactsSelected,
        selectedGroupName,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success(response.message);
          apiCallFetchGroupContacts(selectedGroupName, "");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error while removing contacts from selected group");
        setIsLoading(false);
      });
  };

  const apiCallGetContactHeaders = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/get-contact-headers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.data) {
          setContactHeaders(JSON.parse(response?.data));
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err || "Failed to get contact headers");
        setIsLoading(false);
      });
  };

  const apiCallScheduledCampaignsData = async (jobId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/scheduled-campaigns-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ scheduleId: jobId }),
    })
      .then((res) => res.json())
      .then((response) => {
        setScheduledCampaignsTrackingData(response);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err || "Failed to load tracking data");
        setIsLoading(false);
      });
  };

  const apiResendCampaign = async (campaignDetails) => {
    setIsProcessing(true);
    fetch(`${baseUrl}/wab/resend-campaign`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(campaignDetails),
    })
      .then((res) => res.json())
      .then((response) => {
        toast.success(response.message);
        setIsProcessing(false);
        refreshMonitorData.next({
          scheduleStatus: campaignDetails.scheduleStatus,
        });
      })
      .catch((err) => {
        toast.error(err || "Failed to load tracking data");
        setIsProcessing(false);
      });
  };

  const apiCallDownloadAllContacts = async () => {
    const resp = await fetch(`${baseUrl}/api/get-contacts-for-download`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const contactData = await resp.json();
    return contactData;
  };

  const apiCallCopyContactFromGroup = (fromGroup, toGroup, selectedContact) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/copy-contact-from-group`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fromGroup: fromGroup,
        toGroup: toGroup,
        selectedContact: selectedContact,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error while copy contact in group");
        setIsLoading(false);
      });
  };

  return (
    <ContactsContext.Provider
      value={{
        apiCallUploadContacts,
        apiCallContactsInsights,
        apiCallCreateContact,
        apiCallUpdateContact,
        apiCallDeleteSelectedContacts,
        apiCallFetchUserContacts,
        apiCallFetchContactGroups,
        apiCallFetchGroupContacts,
        apiCallAddContactGroup,
        apiCallDeleteContactGroup,
        apiCallAssignContactGroups,
        apiCallRemoveContactsFromGroup,
        apiCallFetchTablePaging,
        apiCallGetContactHeaders,
        apiCallScheduledCampaignsData,
        apiCallDownloadAllContacts,
        apiCallCopyContactFromGroup,
        importedContacts,
        isUploaded,
        resetUploadComponent,
        contactsInsights,
        dbContacts,
        selectedGroupContacts,
        contactHeaders,
        contactGroups,
        uploadDataError,
        tablePaging,
        totalPages,
        scheduledCampaignsTrackingData,
        setImportedContacts,
        setIsUploaded,
        setResetUploadComponent,
        setUploadDataError,
        setTablePaging,
        setTotalPages,
        isLoading,
        setIsLoading,
        apiResendCampaign,
        isProcessing,
        setIsProcessing,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContactsContext = () => useContext(ContactsContext);
