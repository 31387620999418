import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Button } from "@material-tailwind/react";

import { useFollowupContext } from "../../contexts/FollowupContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { SeoTags } from "..";

const FollowUps = () => {
  const { apiCallFetchContactFollowup, followupList } = useFollowupContext();
  const [searchText, setSearchText] = useState("");
  const followupTableColumn = [
    {
      name: "Contact Number",
      selector: (row) => row?.contactNumber,
      sortable: true,
      width: "25rem",
    },
    {
      name: "Follow-Up On",
      selector: (row) => row?.followUpOn,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Note",
      selector: (row) => row?.followUpRemarks,
      sortable: true,
      grow: 3,
    },
    {
      name: "Follow-Up By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupBy} </span>
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfFollowupBy === 1
                ? "Admin"
                : row?.agentTypeOfFollowupBy === 2
                ? "Super Admin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
      width: "15rem",
    },
    {
      name: "Status",
      selector: (row) => row?.followUpStatus,
      sortable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row?.followupTakenComments,
      sortable: true,
      width: "35rem",
    },
  ];

  const filterFollowupOnSubmit = (e) => {
    e.preventDefault();
    apiCallFetchContactFollowup("", "", searchText);
  };

  useEffect(() => {
    apiCallFetchContactFollowup("", "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
      <SeoTags title="Follow Ups ‣ WaBM" />

      <div className="w-full space-y-2">
        <h1 className="text-2xl">Follow-Ups</h1>
        <h3>Your WaBM Follow-Ups all at one place.</h3>
      </div>

      <div className="wbm-card-3 space-y-3">
        <form
          onSubmit={filterFollowupOnSubmit}
          className="relative lg:w-2/4 space-3"
        >
          <Input
            type="text"
            placeholder="Search by contact number, follow-up by, follow-up status or by date"
            label="Search Follow-Ups"
            onChange={(e) => setSearchText(e.target.value)}
            className="pr-10"
            containerProps={{
              className: "min-w-0",
            }}
          />
          <Button
            size="sm"
            type="submit"
            color="green"
            className="!absolute top-1 right-1 rounded"
          >
            Search
          </Button>
        </form>
        <div className="rounded-md overflow-auto">
          <DataTable
            columns={followupTableColumn}
            data={followupList}
            customStyles={followupTableStyles}
            fixedHeader
            pagination
            fixedHeaderScrollHeight="60vh"
          />
        </div>
      </div>
    </main>
  );
};

export default FollowUps;

const followupTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
