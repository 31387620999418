export const passwordStrengthCheck = (passwordValue) => {
  const strengthChecks = {
    length: 0,
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  };

  strengthChecks.length = passwordValue.length >= 8 ? true : false;
  strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
  strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
  strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
  strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

  let verifiedList = Object.values(strengthChecks).filter((value) => value);

  let strength =
    verifiedList.length === 5
      ? "Strong"
      : verifiedList.length >= 2
      ? "Medium"
      : "Weak";

  return {
    progress: `${(verifiedList.length / 5) * 100}%`,
    strength: strength,
  };
};
