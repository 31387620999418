import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Button,
} from "@material-tailwind/react";

import { AppNavbar, Footer, SeoTags } from "../components";
import { inputUsernameSanitation } from "../components/utils/inputSanitation";
import { useUserContext } from "../contexts/UserContextProvider";
import skyrocketYourGrowth from "../images/props/skyrocket-your-growth.png";

const LoginCard = () => {
  /************************* START of User Login Variables and Functions *************************/
  const [corporateId, setCorporateId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [password, setPassword] = useState("");

  const [hidePassword, setHidePassword] = useState(true);

  const { apiCallLogin } = useUserContext();

  const userLoginOnSubmit = (event) => {
    event.preventDefault();
    apiCallLogin(corporateId, agentName, password);
  };
  /************************* END of User Login Variables and Functions *************************/

  useEffect(() => {
    setCorporateId((prev) => prev.replace(" ", ""));
    setAgentName((prev) => prev.replace(" ", ""));
    setPassword((prev) => prev.replace(" ", ""));
  }, [corporateId, agentName, password]);

  return (
    <Card className="">
      <CardHeader
        variant="gradient"
        color="blue"
        className="p-4 grid h-28 place-items-center"
      >
        <Typography variant="h3" color="white">
          Log In
        </Typography>
      </CardHeader>
      <CardBody className="flex flex-col gap-4">
        <p>So, what are we doing today?</p>
        <Typography variant="h4" color="blue">
          Let's create a marketing campaign and boost your business.
        </Typography>

        <form
          onSubmit={userLoginOnSubmit}
          className="w-full flex flex-col space-y-5"
        >
          <div className="mt-2 flex flex-col gap-4">
            <Input
              label="Corporate ID"
              variant="standard"
              color="blue"
              labelProps={{
                className: "text-slate-800",
              }}
              required
              placeholder="Please type your Corporate ID"
              value={corporateId}
              onChange={(e) =>
                setCorporateId(inputUsernameSanitation(e.target.value).trim())
              }
              className="my-input-box-1"
            />
            <Input
              label="Agent ID"
              variant="standard"
              color="blue"
              labelProps={{
                className: "text-slate-800",
              }}
              required
              placeholder="Please type your Agent ID"
              value={agentName}
              onChange={(e) =>
                setAgentName(inputUsernameSanitation(e.target.value).trim())
              }
              className="my-input-box-1"
            />
            <Input
              label="Password"
              variant="standard"
              color="blue"
              labelProps={{
                className: "text-slate-800",
              }}
              type={hidePassword ? "password" : "text"}
              icon={
                <button
                  type="button"
                  className=""
                  onClick={() => {
                    setHidePassword(!hidePassword);
                  }}
                  tabIndex="-1"
                >
                  {!hidePassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                </button>
              }
              required
              placeholder="Please type your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="my-input-box-1"
            />
          </div>

          <NavLink to="/password-reset">
            <Typography
              variant="small"
              color="blue-gray"
              className="my-hover-effect font-semibold"
            >
              Forgot Password?
            </Typography>
          </NavLink>
          <Button type="submit" variant="gradient" color="blue" fullWidth>
            Login
          </Button>
        </form>
      </CardBody>
      <CardFooter className="pt-0">
        <div className="mt-6 flex justify-center font-semibold text-sm">
          Don&apos;t have an account?
          <NavLink to="/signup">
            <Typography variant="small" color="blue" className="ml-1 font-bold">
              Sign up
            </Typography>
          </NavLink>
        </div>
      </CardFooter>
    </Card>
  );
};

const Loginpage = () => {
  const { isLoggedIn, currentUserInfo, daypart, daypartFunction, loginCheck } =
    useUserContext();

  useEffect(() => {
    if (isLoggedIn) {
      daypartFunction();
    } else {
      loginCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className="bg-[url(../src/images/props/y-so-serious-white.png)] bg-repeat min-h-screen flex flex-col justify-between overflow-hidden">
      <SeoTags
        title="Login ‣ WaBM | Best WhatsApp Business management solution in Delhi"
        description="Let's carry out WhatsApp marketing campaigns and give your Business a boost."
      />

      <AppNavbar />
      <main className="container mx-auto px-5 py-16 grid grid-cols-1 md:grid-cols-5 gap-5">
        <img
          src={skyrocketYourGrowth}
          className="mx-auto md:col-span-3"
          alt="WaBM Login"
        />

        <div className="w-full self-center md:col-span-2">
          {isLoggedIn ? (
            <Card className="">
              <CardHeader
                variant="gradient"
                color="blue"
                className="p-4 grid h-28 place-items-center"
              >
                <Typography variant="h3" color="white">
                  Log In
                </Typography>
              </CardHeader>
              <CardBody className="flex flex-col gap-4">
                <p>
                  So, what are we doing today? Let's create a marketing campaign
                  and get some business.
                </p>
                <div className="flex md:flex-wrap gap-1 text-blue-600 text-xl">
                  <span>Good {daypart}</span>
                  <strong className="uppercase font-semibold">
                    {currentUserInfo?.agentName}!
                  </strong>
                </div>
                <p className="mt-1 mb-3">Happy to See you!</p>
                {currentUserInfo?.mandatoryUserDetailsExist &&
                currentUserInfo?.mandatoryWabApiCreds ? (
                  <NavLink to="/dashboard">
                    <Button variant="gradient" color="blue" fullWidth>
                      Goto Dashboard ⇒
                    </Button>
                  </NavLink>
                ) : (
                  <NavLink to="/setup">
                    <Button variant="gradient" color="blue" fullWidth>
                      Let's Get Started
                    </Button>
                  </NavLink>
                )}
              </CardBody>
            </Card>
          ) : (
            <LoginCard />
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Loginpage;
