import React from "react";
import { RiLoader3Line } from "react-icons/ri";

export const Loader = () => {
  return (
    <div className="w-full h-full flex justify-center items-center text-slate-900 z-50">
      <RiLoader3Line className="animate-spin h-5 w-5 mr-3" />
      Loading
    </div>
  );
};

export default Loader;
