import { appSettings } from "../config/appConfig";

export function initFacebookSdK() {
  return new Promise((resolve) => {
    // Wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appSettings.facebookAppId,
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });

      window.FB.AppEvents.logPageView();

      // Auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus((authResponse) => {
        // console.log("facebook sdk auth response ", authResponse);
        if (authResponse) {
          resolve(authResponse);
        } else {
          resolve("load");
        }
      });
    };

    // Load facebook sdk script
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
