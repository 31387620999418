import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import DataTable from "react-data-table-component";
import {
  Drawer,
  Button,
  Typography,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";

import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { Loader, SeoTags } from "..";

const ManageWelcomeMessages = () => {
  const { saveWelcomeMessage, getWelcomeMessages } = useConversationContext();
  const { isLoading } = useUserContext();

  const [toCreateWelcomeMessages, setToCreateWelcomeMessages] = useState(false);
  const [welcomeMessageList, setWelcomeMessageList] = useState([]);
  const [isReload, setIsReload] = useState(false);

  const blankMessage = {
    messageType: "text",
    bodyText: "",
    mediaValue: null,
  };

  const [listOfWelcomeMessages, setListOfWelcomeMessages] = useState([
    blankMessage,
  ]);

  const welcomeMessageTableColumns = [
    {
      name: "Message Type",
      selector: (row) => row?.message_type,
      sortable: true,
    },
    {
      name: "Message Text",
      selector: (row) => row?.message_body_t,
      sortable: true,
    },
  ];

  const addMoreWelcomeMessage = () => {
    const newMessageList = [...listOfWelcomeMessages, blankMessage];
    setListOfWelcomeMessages(newMessageList);
  };

  const uploadMedia = async (uploadedFile) => {
    try {
      const uniqueId = uuid();
      const uniqueFileName =
        uniqueId + "." + uploadedFile.name.split(".").pop().toLowerCase();
      const uploadParams = {
        fileName: uniqueFileName,
        mediaFile: uploadedFile,
        fileType: uploadedFile.type,
      };
      let response = await uploadMediaOnAWS(uploadParams);
      if (response === EnumFileUploadStatus.FileUploadSuccess) {
        return uniqueFileName;
      } else {
        return "";
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const handleWelcomeMessageOnClick = async () => {
    let welcomeMessages = [];
    for (let i = 0; i < listOfWelcomeMessages.length; i++) {
      const message = listOfWelcomeMessages[i];
      if (message.messageType !== "text" && message.mediaValue) {
        const file = message.mediaValue;
        const fileName = await uploadMedia(file);
        welcomeMessages.push({
          message_type: message.messageType,
          bodyText: fileName,
        });
      } else {
        welcomeMessages.push({
          message_type: message.messageType,
          bodyText: message.bodyText,
        });
      }
      if (i === listOfWelcomeMessages.length - 1) {
        saveWelcomeMessage(welcomeMessages);
        setToCreateWelcomeMessages(false);
      }
    }
  };

  const handleSelectOnChange = (index, newMessageType) => {
    const updatedMessages = listOfWelcomeMessages.map((item, i) =>
      i === index
        ? {
            ...item,
            messageType: newMessageType,
            bodyText: "",
            mediaValue: null,
          }
        : item
    );
    setListOfWelcomeMessages(updatedMessages);
  };

  const handleInputOnChange = (index, value, type) => {
    const updatedMessages = listOfWelcomeMessages.map((item, i) =>
      i === index ? { ...item, [type]: value } : item
    );
    setListOfWelcomeMessages(updatedMessages);
  };

  const loadWelcomeMessages = async () => {
    const result = await getWelcomeMessages();
    console.log("result ", result);
    setWelcomeMessageList(result);
  };

  const handleScrollOnScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  useEffect(() => {
    setIsReload(false);
    loadWelcomeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  return (
    <>
      <SeoTags title="Welcome Messages ‣ WaBM" />

      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Welcome Messages</h1>
          <h3>Create, Delete and Manage welcome messages.</h3>
        </div>

        <Button
          variant="gradient"
          color="blue"
          type="button"
          onClick={() => {
            setListOfWelcomeMessages([blankMessage]);
            setToCreateWelcomeMessages(true);
          }}
        >
          Create a New Welcome Message
        </Button>

        <div className="wbm-card-3 space-y-3">
          <h2 className="font-Montserrat text-lg">Welcome Messages</h2>
          <div className="rounded-md overflow-auto">
            {isLoading ? (
              <Loader />
            ) : welcomeMessageList?.length > 0 ? (
              <DataTable
                columns={welcomeMessageTableColumns}
                data={welcomeMessageList}
                customStyles={welcomeMessageTableStyles}
                fixedHeader
                fixedHeaderScrollHeight="60vh"
              />
            ) : (
              <div className="space-y-3">
                <p>No Welcome messages found.</p>
              </div>
            )}
          </div>
        </div>
      </main>

      <Drawer
        onScroll={handleScrollOnScroll}
        placement="right"
        open={toCreateWelcomeMessages}
        onClose={() => setToCreateWelcomeMessages(false)}
        size="500px"
        className="w-full px-4 z-[9996] overflow-y-scroll"
      >
        <div className="mt-3 flex flex-col justify-start items-center gap-3">
          <Typography className="text-green-700 text-lg font-bold">
            Create Welcome Message
          </Typography>
          <div className="w-full mb-3 flex-1 space-y-5">
            {listOfWelcomeMessages?.map((item, index) => (
              <div
                key={index}
                className="w-full mt-3 p-3 space-y-5 border border-slate-500 rounded"
              >
                <Select
                  label="Message Type"
                  variant="standard"
                  color="blue"
                  required
                  value={item?.messageType}
                  onChange={(e) => handleSelectOnChange(index, e)}
                  className="my-input-box-1"
                >
                  <Option
                    key="text"
                    value="text"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Text
                  </Option>
                  <Option
                    key="image"
                    value="image"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Image
                  </Option>
                  <Option
                    key="video"
                    value="video"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Video
                  </Option>
                  <Option
                    key="audio"
                    value="audio"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Audio
                  </Option>
                </Select>
                {item?.messageType !== "text" ? (
                  <Input
                    type="file"
                    label="Auto Reply Media"
                    variant="standard"
                    color="blue"
                    labelProps={{
                      className: "text-slate-800",
                    }}
                    autoComplete="off"
                    onChange={(e) =>
                      handleInputOnChange(
                        index,
                        e.target.files[0],
                        "mediaValue"
                      )
                    }
                    className="my-input-box-1"
                  />
                ) : (
                  <Input
                    label="Auto Reply Message"
                    variant="standard"
                    color="blue"
                    labelProps={{
                      className: "text-slate-800",
                    }}
                    autoComplete="off"
                    onChange={(e) =>
                      handleInputOnChange(index, e.target.value, "bodyText")
                    }
                    className="my-input-box-1"
                  />
                )}
              </div>
            ))}
          </div>

          <Button
            type="button"
            variant="standard"
            onClick={addMoreWelcomeMessage}
            className="self-center"
          >
            + Add More
          </Button>

          <Button
            type="button"
            variant="gradient"
            color="blue"
            fullWidth
            onClick={handleWelcomeMessageOnClick}
          >
            Create Welcome Message
          </Button>
        </div>
      </Drawer>
    </>
  );
};
export default ManageWelcomeMessages;

const welcomeMessageTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
