export const contactsHeaderKeys = [
  "first_name",
  "last_name",
  "country_code",
  "phone_number",
  "email",
  "company_name",
  "city",
  "country",
  "remarks",
];
