import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  Typography,
  Input,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

import { useUserContext } from "../../contexts/UserContextProvider";
import {
  emailValidationCheck,
  passwordValidationCheck,
} from "../utils/inputValidations";
import { inputUsernameSanitation } from "../utils/inputSanitation";
import { CustomLoader } from "..";

const CreateAgent = ({ setToCreateAgent, callbackCreateContact }) => {
  const {
    apiCallCreateAgent,
    apiCallGetUserAgents,
    currentUserInfo,
    isLoading,
  } = useUserContext();

  const [agentUsername, setAgentUsername] = useState("");
  const [agentPassword, setAgentPassword] = useState("");
  const [agentFullName, setAgentFullName] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentType, setAgentType] = useState("Agent");
  const [hidePassword, setHidePassword] = useState(true);

  const resetStates = () => {
    setAgentUsername("");
    setAgentPassword("");
    setAgentFullName("");
    setAgentEmail("");
    setAgentType("Agent");
    setHidePassword(true);
  };

  const createAgentOnSubmit = async (event) => {
    event.preventDefault();

    if (agentEmail && agentEmail.trim().length === 0) {
      toast.warn("Email ID is required to proceed!");
    } else if (agentEmail?.length > 0 && !emailValidationCheck(agentEmail)) {
      toast.warn("Please type a valid Email ID");
    } else if (!passwordValidationCheck(agentPassword)) {
      toast.warn(
        "Password must be ≥8 characters with ≥1 uppercase, ≥1 lowercase, ≥1 number, and ≥1 special character"
      );
    } else {
      const apiRes = await apiCallCreateAgent(
        agentUsername,
        agentPassword,
        agentFullName,
        agentEmail,
        agentType
      );

      if (apiRes) {
        resetStates();
        setToCreateAgent(false);
        apiCallGetUserAgents();
      }
    }
  };

  return (
    <>
      {isLoading && <CustomLoader />}
      <form onSubmit={createAgentOnSubmit} className="space-y-5">
        <div className="flex justify-center flex-row">
          <Typography
            variant="h4"
            color="blue"
            className="text-center items-center"
          >
            Create New Agent
          </Typography>
          <button
            onClick={() => {
              resetStates();
              callbackCreateContact();
            }}
            className="my-button-style-cancel absolute top-5 right-5 items-end"
          >
            <IoClose size={18} />
          </button>
        </div>

        <Input
          label="Agent Username"
          variant="standard"
          color="blue"
          labelProps={{
            className: "text-slate-800",
          }}
          required
          placeholder="Please type preferred Agent Username"
          value={agentUsername}
          onChange={(e) =>
            setAgentUsername(inputUsernameSanitation(e.target.value))
          }
          className="my-input-box-1"
          autoComplete="off"
        />
        <Input
          label="Agent Password"
          variant="standard"
          color="blue"
          labelProps={{
            className: "text-slate-800",
          }}
          type={hidePassword ? "password" : "text"}
          icon={
            <button
              type="button"
              className=""
              onClick={() => {
                setHidePassword(!hidePassword);
              }}
              tabIndex="-1"
            >
              {!hidePassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
            </button>
          }
          required
          placeholder="Please type the preferred Password"
          value={agentPassword}
          onChange={(e) => setAgentPassword(e.target.value)}
          className="my-input-box-1"
          autoComplete="off"
        />
        <Input
          label="Agent Name"
          variant="standard"
          color="blue"
          labelProps={{
            className: "text-slate-800",
          }}
          required
          placeholder="Please type Agent's Name"
          value={agentFullName}
          onChange={(e) => setAgentFullName(e.target.value)}
          className="my-input-box-1"
          autoComplete="off"
        />
        <Input
          label="Agent Email"
          variant="standard"
          color="blue"
          labelProps={{
            className: "text-slate-800",
          }}
          required
          placeholder="Please type Agent's Email"
          value={agentEmail}
          onChange={(e) => setAgentEmail(e.target.value.trim())}
          className="my-input-box-1"
          autoComplete="off"
        />
        <Select
          label="Agent Type"
          variant="standard"
          color="blue"
          value={agentType}
          required
          onChange={(e) => setAgentType(e)}
          className="my-input-box-1"
        >
          <Option
            key="Agent"
            value="Agent"
            className={`hover:bg-blue-300 focus:bg-blue-300 ${
              agentType === "Agent" ? "bg-wabmGreen" : ""
            }`}
          >
            Agent
          </Option>
          {currentUserInfo?.isAdmin === 2 ? (
            <Option
              key="Admin"
              value="Admin"
              className={`hover:bg-blue-300 focus:bg-blue-300 ${
                agentType === "Admin" ? "bg-wabmGreen" : ""
              }`}
            >
              Admin
            </Option>
          ) : (
            <></>
          )}
        </Select>
        <Button variant="gradient" color="blue" type="submit" fullWidth>
          Create Agent
        </Button>
      </form>
    </>
  );
};

export default CreateAgent;
