import React, { useState } from "react";

import { WhatsappUi, MessagingViaTemplate, SeoTags } from "..";

const Campaign = () => {
  const [displayMessageContent, setDisplayMessageContent] = useState();

  return (
    <div className="w-full h-screen absolute top-0 left-0 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <SeoTags title="Campaign ‣ WaBM" />

      <div className="w-full h-full p-5 md:col-span-2 lg:col-span-3 space-y-3 md:overflow-auto">
        <h1 className="text-2xl">Start a Marketing Campaign</h1>
        <h3>
          Initiate the Conversation with your attractive message templates.
        </h3>
        <MessagingViaTemplate
          setTemplate={(displayMessageContent) =>
            setDisplayMessageContent(displayMessageContent)
          }
        />
      </div>
      <WhatsappUi currTemplate={displayMessageContent} />
    </div>
  );
};

export default Campaign;
