import React, { useState } from "react";
import { Input, Button } from "@material-tailwind/react";

import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { inputMessageSanitation } from "../utils/inputSanitation";

const CreateContactGroup = ({ callbackHideContactGroup }) => {
  const {
    apiCallAddContactGroup,
    apiCallContactsInsights,
    apiCallFetchContactGroups,
  } = useContactsContext();
  const [newContactGroupName, setNewContactGroupName] = useState("");

  const resetStates = () => {
    setNewContactGroupName("");
  };

  const handleAddGroupOnSubmit = async (event) => {
    event.preventDefault();

    if (newContactGroupName !== "") {
      const resp = await apiCallAddContactGroup(
        newContactGroupName.replace(/\s{2,}/g, " ").trim()
      );

      if (resp) {
        resetStates();
        apiCallContactsInsights();
        await apiCallFetchContactGroups();
        callbackHideContactGroup();
      }
    }
  };

  return (
    <form
      onSubmit={handleAddGroupOnSubmit}
      className="flex flex-col justify-start gap-3"
    >
      <Input
        label="New Group Name"
        variant="standard"
        color="blue"
        labelProps={{
          className: "text-slate-800",
        }}
        placeholder="Please type a unique Group Name"
        required
        value={newContactGroupName}
        onChange={(e) =>
          setNewContactGroupName(inputMessageSanitation(e.target.value))
        }
        className="my-input-box-1"
      />
      <div className="flex flex-row gap-3 self-end">
        <Button type="submit" color="blue" className="w-48 whitespace-nowrap">
          Create Group
        </Button>
        <Button
          type="button"
          variant="filled"
          color="red"
          onClick={() => callbackHideContactGroup()}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  );
};

export default CreateContactGroup;
