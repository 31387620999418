import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { isoTimestampToLocal } from "../utils/timeStampConversion";

const CampaignStatuses = () => {
  const { getCampaignRepliedStatuses, campaignRepliedStatuses } =
    useConversationContext();

  const campaignStatusColumns = [
    {
      name: "Name",
      selector: (row) => row?.schedule_name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Type",
      selector: (row) => row?.s_type,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => isoTimestampToLocal(row?.schedule_date_time),
      sortable: true,
      width: "200px",
    },
    {
      name: "Contacts",
      selector: (row) => row?.contactSendCount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Delivered",
      selector: (row) => row?.deliveredCount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Received Replies",
      selector: (row) => row?.repliedCount,
      sortable: true,
      width: "200px",
    },
  ];

  useEffect(() => {
    getCampaignRepliedStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wbm-card-2 space-y-2">
      <h3 className="font-Montserrat text-lg text-wabmBlue">
        Campaign Statuses
      </h3>
      <div className="rounded-md">
        <DataTable
          columns={campaignStatusColumns}
          data={campaignRepliedStatuses}
          customStyles={campaignStatusesStyles}
          fixedHeader
          fixedHeaderScrollHeight="50vh"
          className="bg-slate-600 text-sm"
        />
      </div>
    </div>
  );
};

export default CampaignStatuses;

const campaignStatusesStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
