import React from "react";
import { Spinner } from "@material-tailwind/react";

export const CustomLoader = () => {
  return (
    <div style={loaderOverlayStyle}>
      <div className="flex flex-col justify-center items-center">
        <Spinner color="green" className="h-16 w-16 text-white" />
        <div className="text-white text-lg">Processing</div>
      </div>
    </div>
  );
};

const loaderOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust transparency here
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "9999",
};

export default CustomLoader;
