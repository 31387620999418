import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@material-tailwind/react";

const SubscribeStrip = () => {
  return (
    <div className="bg-infokeyYellow bg-opacity-95 w-full px-2 py-1 fixed bottom-0 flex flex-wrap items-center justify-center gap-x-7 gap-y-1 z-10">
      <p className="text-center">
        👀 Don't miss out! <strong>Subscribe now</strong> to continue enjoying
        enhanced experience and exclusive benefits.
      </p>
      <NavLink to="/dashboard/subscribe">
        <Button className="my-hover-effect bg-wabmAiBlue">Subscribe Now</Button>
      </NavLink>
    </div>
  );
};

export default SubscribeStrip;
