import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { HiMiniPencil } from "react-icons/hi2";
import { IoMdSearch } from "react-icons/io";
import { IoPersonCircle, IoClose } from "react-icons/io5";
import * as XLSX from "xlsx";

import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { isoTimestampToLocal } from "../utils/timeStampConversion";
import { CustomLoader, CreateContactGroup } from "..";

const ManageAllContacts = ({
  drawerStatus,
  callbackHideAllContacts,
  groupName = "",
  contactInGroup = [],
  tableDialogSize = "80vh",
}) => {
  const allContactsTableHeads = [
    "Select All",
    "S.No.",
    "Contact Name",
    "Phone No.",
    "Last Contacted",
    "Remarks",
    "",
  ];

  const {
    dbContacts,
    totalPages,
    contactGroups,
    apiCallFetchUserContacts,
    apiCallFetchTablePaging,
    apiCallAssignContactGroups,
    apiCallDeleteSelectedContacts,
    apiCallDownloadAllContacts,
    apiCallUpdateContact,
    isLoading,
    apiCallFetchContactGroups,
  } = useContactsContext();
  const { currentUserInfo } = useUserContext();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchContact, setSearchContact] = useState("");
  const [toEditContact, setToEditContact] = useState({});
  const [deleteContactId, setContactForDelete] = useState([]);
  const [openEditContact, setOpenEditContact] = useState(false);
  const [targetContactGroup, setTargetContactGroup] = useState("");
  const [contactsSelected, setContactsSelected] = useState([]);
  const [filterContact, setFilterContact] = useState([]);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [contactPageSize, setContactPageSize] = useState("100");
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);
  const pageSizeArray = ["50", "100", "500", "1000", "5000"];

  const resetStates = () => {
    setCurrentPage(1);
    setSearchContact("");
    setToEditContact({});
    setContactForDelete([]);
    setOpenEditContact(false);
    setTargetContactGroup("");
    setContactsSelected([]);
    setContactPageSize("50");
    setSelectAll(false);
    setTotalSelectedCount(0);
  };

  const getContactsOnClick = async (page) => {
    setCurrentPage(page);
    const contact = await apiCallFetchUserContacts(
      page,
      searchContact,
      contactPageSize
    );
    setFilterContact(contact);
    apiCallFetchTablePaging(searchContact, contactPageSize);
    setTotalSelectedCount(0);
    setSelectAll(false);
    setContactsSelected([]);
  };

  const loadContactInGroup = async () => {
    if (groupName && groupName !== "") {
      let includeContact = [];
      // eslint-disable-next-line array-callback-return
      contactInGroup?.map((contact) => {
        includeContact.push(contact?.contactId);
      });
      const filterData = await apiCallFetchUserContacts(
        1,
        searchContact,
        contactPageSize,
        includeContact.join(",")
      );
      setFilterContact(filterData);
      apiCallFetchTablePaging(searchContact, contactPageSize);
      setTotalSelectedCount(0);
      setSelectAll(false);
      setContactsSelected([]);
      setTargetContactGroup(groupName);
    } else {
      const filterData = await apiCallFetchUserContacts(
        1,
        searchContact,
        contactPageSize,
        ""
      );
      setFilterContact(filterData);
    }
  };

  const handlePrevious = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const contact = await apiCallFetchUserContacts(
        currentPage - 1,
        searchContact,
        contactPageSize
      );
      setFilterContact(contact);
      setTotalSelectedCount(0);
      setSelectAll(false);
      setContactsSelected([]);
    }
  };

  const handleNext = async () => {
    if (currentPage < totalPages.length) {
      setCurrentPage(currentPage + 1);
      const contact = await apiCallFetchUserContacts(
        currentPage + 1,
        searchContact,
        contactPageSize
      );
      setFilterContact(contact);
      setTotalSelectedCount(0);
      setSelectAll(false);
      setContactsSelected([]);
    }
  };

  const getVisiblePages = () => {
    const start = Math.floor((currentPage - 1) / 5) * 5 + 1;
    const end = Math.min(start + 4, totalPages.length);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const selectMultipleContactsOnClick = (event, selectedData) => {
    const isChecked = event.target.checked;
    setContactsSelected((prevSelectedContacts) => {
      const updatedSelectedContacts = isChecked
        ? [...prevSelectedContacts, selectedData]
        : prevSelectedContacts.filter((data) => data !== selectedData);
      setTotalSelectedCount(updatedSelectedContacts?.length);
      setSelectAll(updatedSelectedContacts.length === filterContact.length);
      return updatedSelectedContacts;
    });
  };

  const assignContactGroupsOnSubmit = async (event) => {
    event.preventDefault();

    if (contactGroups.filter((x) => x.groupName === targetContactGroup)) {
      apiCallAssignContactGroups(contactsSelected, targetContactGroup);
    }

    callbackHideAllContacts();
    resetStates();
  };

  const deleteSelectedContactsOnClick = (event) => {
    event.preventDefault();
    apiCallDeleteSelectedContacts(deleteContactId);
    setOpenEditContact(!openEditContact);
    getContactsOnClick(currentPage);
  };

  const getContactsOnSubmit = (event) => {
    event.preventDefault();
    if (!handleContactSearch()) {
      getContactsOnClick(currentPage);
    }
  };

  const handleContactSearch = () => {
    const numberRegex = /\d{3,}/g;
    const letterRegex = /[A-z]{3,}/gi;

    if (searchContact && searchContact?.trim() !== "") {
      let contacts = dbContacts.filter(
        (currentContact) =>
          (numberRegex.test(searchContact) &&
            currentContact?.phoneNo &&
            currentContact?.phoneNo.includes(searchContact)) ||
          (letterRegex.test(searchContact) &&
            (currentContact?.firstName || currentContact?.lastName) &&
            (currentContact?.firstName + " " + currentContact?.lastName)
              .toLowerCase()
              .includes(searchContact.toLowerCase()))
      );

      if (contacts.length > 0) {
        setFilterContact(contacts);
        return true;
      } else {
        return false;
      }
    } else if (dbContacts && dbContacts.length > 0) {
      setFilterContact(dbContacts);
      return true;
    } else {
      return false;
    }
  };

  const changeSearchText = (text) => {
    setSearchContact(text.replace("+", ""));
    setCurrentPage(1);
  };

  const handleContactEditOnChange = (e) => {
    const { name, value } = e.target;

    setToEditContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleContactEditOnSubmit = (e) => {
    e.preventDefault();

    apiCallUpdateContact({
      contactId: toEditContact?.contactId,
      firstName: toEditContact?.firstName,
      lastName: toEditContact?.lastName,
      email: toEditContact?.email,
      waOptIn: toEditContact?.waOptIn,
      isBlocked: toEditContact?.isBlocked,
      remarks: toEditContact?.remarks,
      companyName: toEditContact?.companyName,
      city: toEditContact?.city,
      country: toEditContact?.country,
    });
    setSearchContact("");
    setOpenEditContact(!openEditContact);
  };

  const downloadAllContactSOnClick = async () => {
    const allContactsForDownload = await apiCallDownloadAllContacts();
    const worksheet = XLSX.utils.json_to_sheet(allContactsForDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "phoneContacts.xlsx");
  };

  const handleScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  const handleSelectAllOnChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const selectedContact = isChecked
      ? filterContact.map((contact) => {
          return JSON.stringify({
            countryCode: contact?.countryCode,
            phoneNo: contact?.phoneNo,
          });
        })
      : [];
    setContactsSelected(selectedContact);
    setTotalSelectedCount(selectedContact?.length);
  };

  useEffect(() => {
    if (!drawerStatus) {
      resetStates();
    } else {
      loadContactInGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerStatus]);

  return (
    <>
      {isLoading && <CustomLoader />}
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Manage All Contacts
              </Typography>
              <p className="mt-1">
                These are details about all your saved contacts
              </p>
            </div>
            <div className="flex items-center gap-5 text-sm">
              <span>
                <span>Selected: </span>
                <span>{totalSelectedCount}</span>
              </span>
              |
              <span>
                <span>Max Contacts allowed in a Group: </span>
                <span>{currentUserInfo?.maxContactsInGroup}</span>
              </span>
            </div>
            <div className="flex w-full gap-5 md:w-max">
              {currentUserInfo?.isAdmin === 2 && (
                <Button
                  color="blue"
                  onClick={() => downloadAllContactSOnClick()}
                  className="w-48 whitespace-nowrap"
                >
                  Download Contacts
                </Button>
              )}
              <form
                onSubmit={getContactsOnSubmit}
                className="w-full flex gap-2"
              >
                <Input
                  label="Search Phone No. or Name"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  icon={<IoMdSearch className="h-4 w-4" />}
                  placeholder="Type Phone No. or Name to Search"
                  value={searchContact}
                  onChange={(e) =>
                    changeSearchText(e.target.value.replace("+", ""))
                  }
                  className="my-input-box-1 md:w-72"
                />
              </form>
              <button
                onClick={() => callbackHideAllContacts()}
                className="my-button-style-cancel right-5"
              >
                <IoClose size={18} />
              </button>
            </div>
          </div>
        </CardHeader>
        <CardBody
          onScroll={handleScroll}
          style={{ height: tableDialogSize }}
          className="p-0 overflow-y-scroll"
        >
          <table className="w-full min-w-max table-auto text-left">
            <thead className="bg-slate-200 sticky -top-1 z-10">
              <tr className="text-center">
                {allContactsTableHeads.map((head, index) => {
                  const isLast = index === filterContact?.length - 1;
                  const classes = isLast
                    ? "px-2"
                    : "px-2 border-b border-blue-gray-50";
                  return index > 0 ? (
                    <th
                      key={head}
                      className="p-4 border-r border-white bg-blue-gray-50/50"
                    >
                      <Typography
                        variant="small"
                        className="font-normal leading-none"
                      >
                        {head}
                      </Typography>
                    </th>
                  ) : (
                    <th className={classes}>
                      <Checkbox
                        text="Select All"
                        id="ripple-on"
                        className="border-green-700"
                        ripple={true}
                        checked={selectAll}
                        onChange={handleSelectAllOnChange}
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {filterContact?.map((contact, index) => {
                const isLast = index === filterContact?.length - 1;
                const classes = isLast
                  ? "px-2"
                  : "px-2 border-b border-blue-gray-50";

                return (
                  <tr key={index} className="text-center">
                    <td className={classes}>
                      <Checkbox
                        id="ripple-on"
                        ripple={true}
                        checked={contactsSelected.includes(
                          JSON.stringify({
                            countryCode: contact?.countryCode,
                            phoneNo: contact?.phoneNo,
                          })
                        )}
                        onChange={(e) =>
                          selectMultipleContactsOnClick(
                            e,
                            JSON.stringify({
                              countryCode: contact?.countryCode,
                              phoneNo: contact?.phoneNo,
                            })
                          )
                        }
                      />
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="px-5 flex items-center gap-3">
                        <IoPersonCircle size={20} className="text-slate-800" />
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {`${contact?.firstName} ${contact?.lastName}`}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {`+${contact?.countryCode} ${contact?.phoneNo}`}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {contact?.lastContacted
                          ? isoTimestampToLocal(contact.lastContacted)
                          : "-"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {contact?.remarks}
                      </Typography>
                    </td>
                    {/* <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={status}
                          color={
                            status === "paid"
                              ? "green"
                              : status === "pending"
                              ? "amber"
                              : "red"
                          }
                        />
                      </div>
                    </td> */}
                    <td className={classes}>
                      <button
                        title="Edit Contact"
                        onClick={() => {
                          setOpenEditContact(!openEditContact);
                          setToEditContact(contact);
                          setContactForDelete([
                            {
                              countryCode: contact?.countryCode,
                              phoneNo: contact?.phoneNo,
                            },
                          ]);
                        }}
                      >
                        <IconButton variant="text">
                          <HiMiniPencil className="h-4 w-4" />
                        </IconButton>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="p-3 border-t border-blue-gray-50">
          <div className="w-full flex flex-row items-center gap-3 ">
            <div className="self-start z-50">
              <Select
                variant="outlined"
                label="Page Size"
                color="blue"
                required
                value={contactPageSize}
                onChange={async (pageSize) => {
                  setContactPageSize(pageSize);
                  const contact = await apiCallFetchUserContacts(
                    currentPage,
                    searchContact,
                    pageSize
                  );
                  setFilterContact(contact);
                  apiCallFetchTablePaging(searchContact, pageSize);
                  setTotalSelectedCount(0);
                  setSelectAll(false);
                  setContactsSelected([]);
                }}
                className="w-full md:w-52 z-50"
              >
                {pageSizeArray?.map((pageSize, index) => (
                  <Option key={index} value={pageSize}>
                    {pageSize}
                  </Option>
                ))}
              </Select>
            </div>
            {contactsSelected?.length > 0 && (
              <form
                onSubmit={assignContactGroupsOnSubmit}
                className="flex flex-row md:flex-nowrap items-center gap-3"
              >
                <Select
                  variant="outlined"
                  label="Select Contact Group"
                  color="blue"
                  required
                  value={targetContactGroup}
                  onChange={(e) => setTargetContactGroup(e)}
                  className="w-full md:w-96"
                >
                  {contactGroups?.map((group, index) => (
                    <Option key={index} value={group?.groupName}>
                      {group?.groupName} (
                      {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                      contacts)
                    </Option>
                  ))}
                </Select>
                <div className="flex flex-row items-center gap-2">
                  {targetContactGroup !== "" && (
                    <Button type="submit" variant="gradient" color="green">
                      <span className="whitespace-nowrap">Add to Group</span>
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="filled"
                    color="blue"
                    onClick={() => {
                      setOpenGroupCreationDialog(true);
                    }}
                    className="mr-1 w-48"
                  >
                    <span>Create New Group</span>
                  </Button>
                  <Button
                    type="button"
                    variant="filled"
                    color="red"
                    onClick={() => {
                      setTargetContactGroup("");
                      setContactsSelected(() => []);
                    }}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                </div>
              </form>
            )}
            <div className="flex space-x-2 self-end">
              <button
                onClick={handlePrevious}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded ${
                  currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
                }`}
              >
                Previous
              </button>
              {getVisiblePages().map((page) => (
                <button
                  key={page}
                  onClick={() => getContactsOnClick(page)}
                  className={`px-4 py-2 rounded ${
                    currentPage === page
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages.length}
                className={`px-4 py-2 rounded ${
                  currentPage === totalPages.length
                    ? "bg-gray-300"
                    : "bg-blue-500 text-white"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>

      <Dialog
        open={openEditContact}
        handler={() => setOpenEditContact(!openEditContact)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>
            Edit contact{" "}
            {`+${toEditContact?.countryCode} ${toEditContact?.phoneNo}`}
          </DialogHeader>
        </span>
        <form onSubmit={handleContactEditOnSubmit}>
          <DialogBody className="grid grid-cols-2 gap-3">
            {Object.entries(toEditContact).map(([key, value]) =>
              [
                "countryCode",
                "phoneNo",
                "createdBy",
                "isVerifiedNumber",
                "updatedBy",
              ]?.includes(key) ? (
                <div
                  key={key}
                  className="bg-slate-200 p-2 grid grid-cols-2 gap-2 rounded-md"
                >
                  <p className="border-r-2">{key}</p>
                  <p>{value === 1 ? "YES" : value === 0 ? "NO" : value}</p>
                </div>
              ) : ["waOptIn", "isBlocked"]?.includes(key) ? (
                <div
                  key={key}
                  className="px-2 py-1 grid grid-cols-2 gap-2 rounded-md border border-slate-200"
                >
                  <p className="border-r-2">{key}</p>
                  <select
                    name={key}
                    value={value}
                    onChange={handleContactEditOnChange}
                    className="wbm-select-1"
                  >
                    <option value="2" hidden ng-disabled="!!model">
                      ~ Not Set ~
                    </option>
                    <option key="0" value="0">
                      No
                    </option>
                    <option key="1" value="1">
                      Yes
                    </option>
                  </select>
                </div>
              ) : ["lastContacted"]?.includes(key) ? (
                <div
                  key={key}
                  className="bg-slate-200 p-2 grid grid-cols-2 gap-2 rounded-md"
                >
                  <p className="border-r-2">{key}</p>
                  <p>{value ? isoTimestampToLocal(value) : "-"}</p>
                </div>
              ) : (
                !["contactData", "contactId"]?.includes(key) &&
                key !== "indx" && (
                  <div key={key}>
                    <Input
                      name={key}
                      label={key}
                      color="blue"
                      value={value || ""}
                      onChange={handleContactEditOnChange}
                    />
                  </div>
                )
              )
            )}
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="text"
              color="red"
              onClick={() => setOpenEditContact(!openEditContact)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button
              onClick={(e) => {
                deleteSelectedContactsOnClick(e);
              }}
              className="mr-1"
              variant="gradient"
              color="red"
            >
              <span>Delete</span>
            </Button>
            <Button type="submit" variant="gradient" color="green">
              <span>Save Edits</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <CreateContactGroup
            callbackHideContactGroup={() => {
              apiCallFetchContactGroups();
              setOpenGroupCreationDialog(!openGroupCreationDialog);
            }}
          ></CreateContactGroup>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ManageAllContacts;
