import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

const Footer = () => {
  return (
    <div className="bg-wabmAiBlue mx-auto my-1 px-10 py-3 border-t border-blue-500 rounded-lg">
      <footer className="w-full flex flex-row flex-wrap justify-center md:justify-between items-center gap-y-6 gap-x-12 text-center text-white">
        <Typography color="blue" className="font-semibold">
          &copy; 2024 Infokey Technology Private Limited
        </Typography>
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) =>
                isActive ? "text-wabmGreen" : "my-hover-effect"
              }
            >
              <Typography className="font-normal">Privacy Policy</Typography>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/terms-and-conditions"
              className={({ isActive }) =>
                isActive ? "text-wabmGreen" : "my-hover-effect"
              }
            >
              <Typography className="font-normal">
                Terms & Conditions
              </Typography>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/cancellation-refund-policy"
              className={({ isActive }) =>
                isActive ? "text-wabmGreen" : "my-hover-effect"
              }
            >
              <Typography className="font-normal">
                Cancellation & Refund
              </Typography>
            </NavLink>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
