export const emailValidationCheck = (email) => {
  let userEmailRegex = /[a-z0-9]+@[a-z0-9]+\.[a-z]{2,}/g;
  if (email.length >= 6 && userEmailRegex.test(email)) return true;
  return false;
};

export const phoneNumberValidationCheck = (phoneNumber) => {
  let phoneNumberRegex = /^\+?[0-9]{8,15}$/g;

  // let phoneNumberRegex = /^[0-9]{1,3}$/g;
  // let phoneNumberRegex = /^[0-9]{7,14}$/g;
  return phoneNumberRegex.test(phoneNumber);
};

export const passwordValidationCheck = (password) => {
  let userPasswordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=.])(?!.*\s).{8,}$/g;
  if (password.length >= 8 && userPasswordRegex.test(password)) return true;
  return false;
};
