import React, { useState, useCallback } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMailOpen } from "react-icons/io5";
import {
  RiInstagramFill,
  RiTwitterFill,
  RiLinkedinBoxFill,
} from "react-icons/ri";

import {
  AppNavbar,
  Footer,
  ContactForm,
  ScrollToTop,
  SeoTags,
} from "../components";
import { contactLinks } from "../config/appConfig";
import salesTeam from "../images/props/salesTeam.png";
import techSupport from "../images/props/techSupport.png";

const Contact = () => {
  const [messageStatusSent, setMessageStatusSent] = useState(false);

  const callback = useCallback((messageStatusSent) => {
    setMessageStatusSent(messageStatusSent);
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between overflow-x-hidden">
      <SeoTags
        title="Contact ‣ WaBM | WhatsApp Business Management API"
        description="Contact WaBM team now to discuss about the massive growth potential of your business."
      />

      <AppNavbar />
      <main className="pt-36 space-y-20">
        <section className="container w-full mx-auto px-5">
          <div className="mb-12 flex flex-col justify-center items-center gap-3">
            <h1 className="font-Montserrat text-3xl text-wabmBlue font-bold">
              Contact Us
            </h1>
            <p className="text-lg text-center">
              Curious about our services or facing any issues, we are here to
              help you.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-5">
            <div className="my-card-style-1 drop-shadow-md md:w-2/5 text-center space-y-3">
              <img
                src={salesTeam}
                className="w-24 mx-auto pb-5"
                alt="sales team"
              />
              <h2 className="text-xl text-wabmBlue font-bold">
                Talk to our Sales Team
              </h2>
              <p>
                We'll guide you the best suited products for your business. Just
                ping us on email or simply WhatsApp.
              </p>
              <div className="flex justify-center gap-5">
                <a
                  href={contactLinks.salesWhatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-wabmAiBlue p-2 rounded-md text-white my-hover-effect cursor-pointer"
                >
                  <IoLogoWhatsapp size={24} />
                </a>
                <a
                  href={`mailto:${contactLinks.salesMail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-wabmAiBlue p-2 rounded-md text-white my-hover-effect cursor-pointer"
                >
                  <IoMailOpen size={24} />
                </a>
              </div>
            </div>
            <div className="my-card-style-1 drop-shadow-md md:w-2/5 text-center space-y-3">
              <img
                src={techSupport}
                className="w-24 mx-auto pb-5"
                alt="tech support"
              />
              <h2 className="text-xl text-wabmBlue font-bold">
                Get Technical Support
              </h2>
              <p>
                Facing any technical issues or need help managing your WaBM
                Account? We'll be happy to assist you!
              </p>
              <div className="flex justify-center gap-5">
                <a
                  href={contactLinks.supportWhatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-wabmAiBlue p-2 rounded-md text-white my-hover-effect cursor-pointer"
                >
                  <IoLogoWhatsapp size={24} />
                </a>
                <a
                  href={`mailto:${contactLinks.supportMail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-wabmAiBlue p-2 rounded-md text-white my-hover-effect cursor-pointer"
                >
                  <IoMailOpen size={24} />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-20 flex flex-col items-center gap-5">
            <h3 className="font-medium text-2xl">
              Let's connect over Social Media and share our growth journey.
            </h3>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-1 text-white">
              <a
                href={contactLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="my-smooth-transition-1 bg-[#00acee] bg-opacity-80 hover:bg-opacity-100 w-full px-3 py-5 flex flex-col justify-center items-center rounded-md"
              >
                <RiTwitterFill size={72} />
                <p>{contactLinks.twitterUsername}</p>
              </a>
              <a
                href={contactLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="my-smooth-transition-1 bg-gradient-to-br from-[#feda75] hover:from-[#f7d168] via-[#d62976] hover:via-[#eb247e] to-[#962fbf] hover:to-[#4f5bd5] w-full px-3 py-5 flex flex-col justify-center items-center rounded-md"
              >
                <RiInstagramFill size={72} />
                <p>{contactLinks.instagramUsername}</p>
              </a>
              <a
                href={contactLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="my-smooth-transition-1 bg-[#0A66C2] bg-opacity-80 hover:bg-opacity-100 w-full px-3 py-5 flex flex-col justify-center items-center rounded-md"
              >
                <RiLinkedinBoxFill size={72} />
                <p>{contactLinks.linkedinUsername}</p>
              </a>
            </div>
          </div>
        </section>

        <section className="bg-wabmAiBlue w-full text-white">
          <div className="container mx-auto px-5 flex flex-col lg:flex-row justify-evenly items-center gap-20 lg:gap-5">
            <iframe
              title="WaBM's office address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55992.47790120267!2d77.08552038031623!3d28.703706795627262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03d5b0619f3f%3A0x2208402cf282fb02!2sPitam%20Pura%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1682146665762!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />

            <div className="pb-20 lg:pb-0 lg:pl-10 self-center space-y-5">
              <div className="space-y-1">
                <span className="inline-flex flex-wrap items-baseline gap-2">
                  <h2 className="font-medium text-wabmGreen text-2xl">WaBM</h2>
                  <h3 className="text-xl">is a brand of</h3>
                </span>
                <h2 className="font-medium text-infokeyYellow text-3xl">
                  Infokey Technology Private Limited
                </h2>
              </div>
              <div className="space-y-1">
                <p className="text-wabmBlue">Corp. Address</p>
                <p className="text-xl">Pitampura, New Delhi, India 🇮🇳</p>
              </div>
              <div className="space-y-1">
                <p className="text-wabmBlue">Reg. Address</p>
                <p className="text-xl">Rohini, New Delhi, India 🇮🇳</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <section className="bg-wabmBlue w-full">
        <div className="bg-[url(../src/images/props/bgBarTexture.png)] bg-cover bg-center">
          <div className="container min-h-[80vh] mx-auto px-5 py-24 space-y-5">
            <div className="my-glassmorphic-effect bg-white bg-opacity-20 w-full lg:w-6/12 mx-auto">
              {!messageStatusSent ? (
                <>
                  <h2 className="font-Montserrat text-3xl text-white text-center">
                    Want us to Contact you?
                  </h2>
                  <p className="pb-10 text-white text-center">
                    Just fill out the form and we'll contact you in no time.
                  </p>
                  <ContactForm parentCallback={callback} />
                </>
              ) : (
                <>
                  <h2 className="text-xl text-center text-white font-bold">
                    Message Sent Successfully!
                  </h2>
                  <p className="text-white">
                    You are on the right track. We'll connect with you soon.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default Contact;
