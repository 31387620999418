import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import {
  Input,
  Drawer,
  Select,
  Option,
  Button,
} from "@material-tailwind/react";
import { BsBroadcastPin } from "react-icons/bs";
import { IoSendSharp, IoClose } from "react-icons/io5";
import { RiTimerLine, RiContactsBookFill } from "react-icons/ri";
import { v4 as uuid } from "uuid";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import allCountryCodes from "../../data/countryAndCodes.json";
import { ShowAllContacts, CampaignScheduler, CustomLoader } from "..";
import { uploadLimits } from "../../config/appConfig";
import { awsBucketConfig } from "../../config/awsBucketConfig";
import {
  couponCodeSanitation,
  phoneNumberSanitation,
} from "../utils/inputSanitation.js";
import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";

// Template Parameters Getter and Setter Component
const TemplateParametersGetterSetter = ({
  selectedTemplate,
  setBodyVarFields,
  setHeaderVarFields,
  parametersToBeSent,
  setUploadedMedia,
}) => {
  const { contactHeaders } = useContactsContext();

  const [templateComponents, setTemplateComponents] = useState([]);

  // Input Header variables
  const [headerMediaType, setHeaderMediaType] = useState("");
  const [isHeaderTextVariable, setIsHeaderTextVariable] = useState(false);
  const [headerVarType, setHeaderVarType] = useState("");
  const [buttonType, setButtonType] = useState("");

  // Input Body variables
  const [numberOfBodyVariables, setNumberOfBodyVariables] = useState(0);
  const [bodyVarType, setBodyVarType] = useState([]);

  const resetAllParameters = () => {
    setHeaderMediaType("");
    setIsHeaderTextVariable(false);
    setHeaderVarType("");
    setNumberOfBodyVariables(0);
    setButtonType("");
    setBodyVarType([]);
    setBodyVarFields([]);
    setHeaderVarFields([]);
    setTemplateComponents([]);
  };

  const variableChecker = (textToBeChecked) => {
    const variableRegex = /\{[\d]+\}/g;
    return textToBeChecked?.match(variableRegex)?.length;
  };

  // Basic template parameters handler
  const templateParametersSetter = () => {
    resetAllParameters();
    selectedTemplate?.components?.forEach((component) => {
      if (component?.type === "HEADER") {
        if (component.format === "IMAGE") {
          setHeaderMediaType("image");
          setTemplateComponents((current) => [
            ...current,
            {
              type: "header",
              parameters: [
                {
                  type: "image",
                  image: { link: "" },
                },
              ],
            },
          ]);
        } else if (component.format === "VIDEO") {
          setHeaderMediaType("video");
          setTemplateComponents((current) => [
            ...current,
            {
              type: "header",
              parameters: [
                {
                  type: "video",
                  video: { link: "" },
                },
              ],
            },
          ]);
        } else if (component.format === "DOCUMENT") {
          setHeaderMediaType("document");
          setTemplateComponents((current) => [
            ...current,
            {
              type: "header",
              parameters: [
                {
                  type: "document",
                  document: { link: "" },
                },
              ],
            },
          ]);
        } else if (component.format === "TEXT") {
          const variablesCount = variableChecker(component.text);

          if (variablesCount > 0) {
            setIsHeaderTextVariable(true);
            setTemplateComponents((current) => [
              ...current,
              {
                type: "header",
                parameters: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ]);
          }
        }
      }

      if (component?.type === "BODY") {
        const variablesCount = variableChecker(component.text);
        if (variablesCount > 0) {
          setNumberOfBodyVariables(variablesCount);

          setTemplateComponents((current) => [
            ...current,
            {
              type: "body",
              parameters: Array.from({ length: variablesCount }, () => ({
                type: "text",
                text: "",
              })),
            },
          ]);

          // Setting empty fields as '' by default
          setBodyVarType(Array.from({ length: variablesCount }, () => ""));
          setBodyVarFields(Array.from({ length: variablesCount }, () => ""));
        }
      }

      if (
        component?.type === "BUTTONS" &&
        component?.buttons?.length > 0 &&
        component?.buttons.find((btnType) => btnType?.type === "COPY_CODE")
      ) {
        setTemplateComponents((current) => [
          ...current,
          {
            type: "button",
            index: component?.buttons.findIndex(
              (btnType) => btnType.type === "COPY_CODE"
            ),
            sub_type: "COPY_CODE",
            parameters: [{ type: "coupon_code", coupon_code: "" }],
          },
        ]);
        setButtonType("COPY_CODE");
      }
    });
  };

  // To update Template Component fixed header
  const updateCompHeaderFixed = (e) => {
    setTemplateComponents((prev) => {
      const updatedComponents = prev.map((component) => {
        if (component.type === "header") {
          // Check if parameters exist, if not, initialize with an empty array
          const parameters = component.parameters || [];
          return {
            ...component,
            parameters: [
              {
                ...parameters[0], // If parameters exist, copy the first element
                text: e.target.value,
              },
              ...parameters.slice(1),
            ],
          };
        }
        return component;
      });

      return updatedComponents;
    });
  };

  // To update Template Component variable header fallback
  const updateCompHeaderVariable = (e) => {
    setTemplateComponents((prev) => {
      const updatedComponents = prev.map((component) => {
        if (component.type === "header") {
          // Check if parameters exist, if not, initialize with an empty array
          const parameters = component.parameters || [];

          return {
            ...component,
            parameters: [
              {
                ...parameters[0], // If parameters exist, copy the first element
                text: e.target.value,
              },
            ],
          };
        }
        return component;
      });

      return updatedComponents;
    });
  };

  // To update Template Component fixed body
  const updateCompBodyFixed = (data, indx) => {
    setTemplateComponents((prev) => {
      const updatedComponents = prev.map((component) => {
        if (component.type === "body") {
          return {
            ...component,
            parameters: [
              ...component.parameters.slice(0, indx), // copy elements before indx
              {
                type: "text",
                text: data,
              },
              ...component.parameters.slice(indx + 1), // copy elements after indx
            ],
          };
        }
        return component;
      });

      return updatedComponents;
    });
  };

  // To update Template Component variable body fallback
  const updateCompBodyVariable = (data, indx) => {
    setTemplateComponents((prev) => {
      const updatedComponents = prev.map((component) => {
        if (component.type === "body") {
          return {
            ...component,
            parameters: [
              ...component.parameters.slice(0, indx), // copy elements before indx
              {
                type: "text",
                text: data,
              },
              ...component.parameters.slice(indx + 1), // copy elements after indx
            ],
          };
        }
        return component;
      });

      return updatedComponents;
    });
  };

  // To update Template Copy code value
  const updateCopyCodeValue = (data, indx) => {
    setTemplateComponents((prev) => {
      const updatedComponents = prev.map((component) => {
        if (component.type === "button") {
          return {
            ...component,
            parameters: [
              {
                type: "coupon_code",
                coupon_code: data,
              },
              ...component.parameters.slice(indx + 1), // copy elements after indx
            ],
          };
        }
        return component;
      });

      return updatedComponents;
    });
  };

  const mediaUploadOnChange = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const uploadedFile = event.target.files[0];

        const uniqueId = uuid();
        const uniquFileName =
          uniqueId + "." + uploadedFile.name.split(".").pop().toLowerCase();
        setUploadedMedia({
          mediaFile: uploadedFile,
          fileName: uniquFileName,
          fileType: uploadedFile.type,
        });
        const awsFileName =
          awsBucketConfig.openUploadedFilePrefix + uniquFileName;

        const headerComponent = templateComponents.find(
          (element) => element.type === "header"
        );

        // Setting Template Header Media
        if (headerComponent && headerMediaType === "image")
          headerComponent.parameters[0].image.link = awsFileName;
        else if (headerComponent && headerMediaType === "video")
          headerComponent.parameters[0].video.link = awsFileName;
        else if (headerComponent && headerMediaType === "location")
          headerComponent.parameters[0].location.link = awsFileName;
        else if (headerComponent && headerMediaType === "document")
          headerComponent.parameters[0].document.link = awsFileName;
      } catch (err) {
        console.error("File upload error", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateComponents, headerMediaType]
  );

  useEffect(() => {
    templateParametersSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useEffect(() => {
    parametersToBeSent(templateComponents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateComponents]);

  return (
    <div className="space-y-2">
      {headerMediaType === "image" && (
        <div className="flex flex-col gap-y-2">
          <label className="font-bold">
            Header Image <strong className="text-red-600">*</strong>
          </label>
          <input
            accept="image/png, image/jpeg"
            max={uploadLimits.image}
            id="image-button"
            type="file"
            required
            onChange={mediaUploadOnChange}
            className="dropzone-box"
          />
        </div>
      )}
      {headerMediaType === "video" && (
        <div className="flex flex-col gap-y-2">
          <label className="font-bold">
            Header Video <strong className="text-red-600">*</strong>
          </label>
          <input
            accept="video/mp4, video/3gp"
            max={uploadLimits.video}
            id="video-button"
            type="file"
            required
            onChange={mediaUploadOnChange}
            className="dropzone-box"
          />
        </div>
      )}
      {headerMediaType === "document" && (
        <div className="flex flex-col gap-y-2">
          <label className="font-bold">
            Header Document <strong className="text-red-600">*</strong>
          </label>
          <input
            accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            max={uploadLimits.document}
            id="file-button"
            type="file"
            required
            onChange={mediaUploadOnChange}
            className="dropzone-box"
          />
        </div>
      )}
      {headerMediaType === "location" && (
        <div className="flex flex-col gap-y-2">
          <label className="font-bold">
            Header Location <strong className="text-red-600">*</strong>
          </label>
          * Coming Soon *
        </div>
      )}

      {/* Header Variable Selection */}
      {isHeaderTextVariable && (
        <div className="flex flex-col gap-y-2">
          <label htmlFor="inputHeaderTextVariable" className="font-bold">
            Header Variable <strong className="text-red-600">*</strong>
          </label>
          <select
            name="headerVarType"
            value={headerVarType}
            required
            onChange={(e) => setHeaderVarType(e.target.value)}
            className="wbm-select-1"
          >
            <option value="" hidden ng-disabled="!!model">
              ~ Variable Type ~
            </option>
            <option value="fixed">Fixed</option>
            <option value="variable">Variable</option>
          </select>

          {headerVarType === "fixed" && (
            <Input
              label="Enter variable {{1}} value"
              variant="standard"
              color="blue"
              labelProps={{
                className: "text-slate-800",
              }}
              required
              onChange={(e) => {
                setHeaderVarFields((prev) => {
                  const newState = [...prev];
                  newState[0] = "Fixed";
                  return newState;
                });
                updateCompHeaderFixed(e);
              }}
              className="my-input-box-1"
            />
          )}
          {headerVarType === "variable" && contactHeaders?.length > 0 ? (
            <span className="flex items-center gap-2">
              <select
                name="inputHeaderTextVariable"
                required
                onChange={(e) =>
                  setHeaderVarFields((prev) => {
                    const newState = [...prev];
                    newState[0] = e.target.value;
                    return newState;
                  })
                }
                className="wbm-select-1"
              >
                <option value="" hidden ng-disabled="!!model">
                  ~ Select Contact Header ~
                </option>
                {contactHeaders?.map((header, index) => (
                  <option key={index} value={header}>
                    {header}
                  </option>
                ))}
              </select>

              <input
                type="text"
                placeholder={`Fallback value for variable 1`}
                required
                onChange={updateCompHeaderVariable}
                className="my-input-style-1 bg-white w-full flex-1"
              />
            </span>
          ) : headerVarType === "variable" ? (
            <p>
              No Contact Headers present. You can add some contacts{" "}
              <NavLink to="/dashboard/contacts" className="text-wabmBlue">
                here
              </NavLink>
            </p>
          ) : (
            <></>
          )}
        </div>
      )}

      {/* Body Variables Selection */}
      {numberOfBodyVariables > 0 && (
        <div className="flex flex-col gap-y-2">
          <label htmlFor="bodyParameters" className="font-bold">
            Body Parameters <strong className="text-red-600">*</strong>
          </label>
          {templateComponents
            .flatMap((item) => (item.type === "body" ? item.parameters : []))
            .map((variable, index) => (
              <div key={index} className="space-y-2">
                <span className="flex items-center gap-x-5">
                  <label htmlFor="bodyParameters">
                    Variable &#123;{index + 1}&#125;
                  </label>
                  <select
                    name="bodyVarType"
                    value={bodyVarType[index]}
                    required
                    onChange={(e) => {
                      setBodyVarType((prev) => {
                        const newState = [...prev];
                        newState[index] = e.target.value;
                        return newState;
                      });
                      variable.text = "";
                    }}
                    className="wbm-select-1"
                  >
                    <option value="" hidden ng-disabled="!!model">
                      ~ Variable Type ~
                    </option>
                    <option value="fixed">Fixed</option>
                    <option value="variable">Variable</option>
                  </select>
                </span>

                <div className="space-y-1">
                  {bodyVarType[index] === "fixed" && (
                    <Input
                      label={`Enter variable {{${index + 1}}} value`}
                      variant="standard"
                      color="blue"
                      labelProps={{
                        className: "text-slate-800",
                      }}
                      required
                      // value={firstName}
                      onChange={(e) => {
                        setBodyVarFields((prev) => {
                          const newState = [...prev];
                          newState[index] = "Fixed";
                          return newState;
                        });

                        updateCompBodyFixed(e.target.value, index);
                      }}
                      className="my-input-box-1"
                    />
                    // <input
                    //   type="text"
                    //   placeholder={`Enter variable {{${index + 1}}} value`}
                    //   onChange={(e) => {
                    //     setBodyVarFields((prev) => {
                    //       const newState = [...prev];
                    //       newState[index] = "Fixed";
                    //       return newState;
                    //     });

                    //     updateCompBodyFixed(e.target.value, index);
                    //   }}
                    //   className="my-input-style-1 bg-white w-full flex-1"
                    // />
                  )}
                  {bodyVarType[index] === "variable" && (
                    <span className="flex items-center gap-2">
                      <select
                        name="bodyParameters"
                        required
                        onChange={(e) =>
                          setBodyVarFields((prev) => {
                            const newState = [...prev];
                            newState[index] = e.target.value;
                            return newState;
                          })
                        }
                        className="wbm-select-1"
                      >
                        <option value="" hidden ng-disabled="!!model">
                          ~ Select Contact Header ~
                        </option>
                        {contactHeaders?.map((header, indx) => (
                          <option key={indx} value={header}>
                            {header}
                          </option>
                        ))}
                      </select>

                      <Input
                        label={`Fallback value for variable {{${index + 1}}}`}
                        variant="standard"
                        color="blue"
                        labelProps={{
                          className: "text-slate-800",
                        }}
                        required
                        // value={firstName}
                        onChange={(e) =>
                          updateCompBodyVariable(e.target.value, index)
                        }
                        className="my-input-box-1"
                      />
                      {/* <input
                        type="text"
                        placeholder={`Fallback value for variable {{${
                          index + 1
                        }}}`}
                        onChange={(e) =>
                          updateCompBodyVariable(e.target.value, index)
                        }
                        className="my-input-style-1 bg-white w-full flex-1"
                      /> */}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Copy Code Button */}
      {buttonType !== "" && buttonType === "COPY_CODE" && (
        <div className="flex flex-col gap-y-2">
          <label htmlFor="bodyParameters" className="font-bold">
            Coupon Code
          </label>

          {templateComponents
            .flatMap((item) => (item.type === "button" ? item.buttons : []))
            .map((variable, index) => (
              <div key={index} className="space-y-2">
                <label htmlFor="bodyParameters">
                  Variable &#123;{index + 1}&#125;
                </label>

                <Input
                  label="Coupon Code"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  value={
                    templateComponents?.find(
                      (item) =>
                        item?.type === "button" &&
                        item?.sub_type === "COPY_CODE"
                    )?.parameters[index]?.coupon_code
                  }
                  onChange={(e) =>
                    updateCopyCodeValue(
                      couponCodeSanitation(e.target.value),
                      index
                    )
                  }
                  className="my-input-box-1"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const MessagingViaTemplate = ({ setTemplate }) => {
  const {
    getTemplates,
    allTemplates,
    sendMessageViaTemplate,
    startGroupCampaign,
    toShowScheduleSettings,
    setToShowScheduleSettings,
    isLoading,
  } = useWhatsappContext();
  const {
    contactGroups,
    apiCallFetchContactGroups,
    apiCallGetContactHeaders,
    apiCallFetchUserContacts,
  } = useContactsContext();

  const [showAllContacts, setShowAllContacts] = useState(false);

  const [contactType, setContactType] = useState("");
  const [bodyVarFields, setBodyVarFields] = useState([]);
  const [headerVarFields, setHeaderVarFields] = useState([]);
  const [selectedContactGroup, setSelectedContactGroup] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState("");

  const selectContactGroupRef = useRef();
  const selectTemplateRef = useRef();

  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [parametersToBeSent, setParametersToBeSent] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState({});

  const resetOnClick = () => {
    setContactType("");
    setBodyVarFields([]);
    setHeaderVarFields([]);
    setSelectedContactGroup("");
    setCountryCode("91");
    setPhoneNumber("");
    setSelectedMedia({});
    // selectContactGroupRef.current.value = "";
    selectTemplateRef.current.value = "";
    setSelectedTemplate({});
    setParametersToBeSent([]);
    setToShowScheduleSettings(false);
  };

  const handleMessageSendOnSubmit = async (e) => {
    e.preventDefault();
    let templateVariableValues = [];
    if (headerVarFields.length > 0) {
      templateVariableValues = headerVarFields;
    }
    const mediaUploadStatus = await uploadMediaOnAWS(selectedMedia);
    if (contactType === "group") {
      if (
        mediaUploadStatus === EnumFileUploadStatus.FileUploadSuccess ||
        mediaUploadStatus === EnumFileUploadStatus.FileNotAvailable
      ) {
        startGroupCampaign(
          selectedContactGroup,
          selectedTemplate,
          parametersToBeSent,
          templateVariableValues.concat(bodyVarFields)
        );
      } else {
        console.error("Error in AWS bucket");
      }
    } else if (contactType === "individual") {
      if (
        mediaUploadStatus === EnumFileUploadStatus.FileUploadSuccess ||
        mediaUploadStatus === EnumFileUploadStatus.FileNotAvailable
      ) {
        sendMessageViaTemplate(
          countryCode + phoneNumber,
          selectedTemplate,
          parametersToBeSent,
          templateVariableValues.concat(bodyVarFields),
          countryCode
        );
      } else {
        console.error("Error in AWS bucket");
      }
    }
    resetOnClick();
  };

  const selectedContactCallback = (value) => {
    setCountryCode(value.countryCode);
    setPhoneNumber(value.phoneNo);

    setShowAllContacts(false);
  };

  const closeContactDrawer = () => {
    setShowAllContacts(false);
  };

  useEffect(() => {
    getTemplates();
    apiCallFetchContactGroups();
    apiCallGetContactHeaders();
    apiCallFetchUserContacts(1, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTemplate(selectedTemplate);
    setBodyVarFields([]);
    setHeaderVarFields([]);
    setParametersToBeSent([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  return (
    <div className="w-full my-10 lg:my-2">
      {isLoading && <CustomLoader />}

      <Drawer
        placement="right"
        open={showAllContacts}
        onClose={() => setShowAllContacts(false)}
        size="500px"
        className="z-[9996]"
      >
        <ShowAllContacts
          callbackHideContact={closeContactDrawer}
          selectedContactCallback={selectedContactCallback}
        />
      </Drawer>

      {toShowScheduleSettings ? (
        <div className="my-card-style-1 space-y-3">
          <CampaignScheduler
            contactType={contactType}
            contactDetail={
              contactType === "group"
                ? selectedContactGroup
                : countryCode + phoneNumber
            }
            selectedTemplate={selectedTemplate}
            parametersToBeSent={parametersToBeSent}
            bodyVal={bodyVarFields}
            headerVal={headerVarFields}
            selectedMedia={selectedMedia}
          />
          <Button
            type="button"
            variant="gradient"
            color="gray"
            onClick={() => setToShowScheduleSettings(false)}
            className="inline-flex items-center gap-2"
          >
            ↤ Back
          </Button>
        </div>
      ) : (
        <form
          onSubmit={handleMessageSendOnSubmit}
          className="my-card-style-1 space-y-5"
        >
          <div className="space-y-2">
            <p className="font-bold">Who do you want to send the message?</p>
            <span className="inline-flex gap-1">
              <Button
                onClick={() => setContactType("individual")}
                color={contactType === "individual" ? "green" : "blue"}
                className="w-48"
              >
                Individual Contact
              </Button>
              <Button
                onClick={() => setContactType("group")}
                color={contactType === "group" ? "green" : "blue"}
                className="w-48"
              >
                Contact Group
              </Button>
            </span>
          </div>

          {contactType === "group" && (
            <div className="flex flex-col gap-y-2">
              <label className="font-bold">
                Contact Group <strong className="text-red-600">*</strong>
              </label>
              {contactGroups?.length > 0 ? (
                <Select
                  label="Select Contact Group"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  required
                  ref={selectContactGroupRef}
                  value={selectedContactGroup}
                  onChange={(e) => setSelectedContactGroup(e)}
                  className="my-input-box-1"
                >
                  {contactGroups?.map((group, index) => (
                    <Option
                      key={index}
                      value={group?.groupName}
                      className={`hover:bg-blue-300 focus:bg-blue-300`}
                      disabled={!group?.numberOfContacts}
                    >
                      {group?.groupName} (
                      {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                      contacts)
                    </Option>
                  ))}
                </Select>
              ) : (
                <p>
                  You don't have any contact groups available yet. Please create
                  a contact group first.
                </p>
              )}
            </div>
          )}

          {contactType === "individual" && (
            <div className="flex flex-col gap-y-2">
              <label className="font-bold">
                Individual Contact <strong className="text-red-600">*</strong>
              </label>
              <div className="flex gap-2">
                <span className="w-fit">
                  <Select
                    label="Country Code"
                    variant="standard"
                    color="blue"
                    required
                    value={countryCode}
                    onChange={(e) => setCountryCode(e)}
                    className="my-input-box-1"
                  >
                    {allCountryCodes.map((country) => (
                      <Option
                        key={country.code}
                        value={country.dial_code}
                        className={`hover:bg-blue-300 focus:bg-blue-300 ${
                          countryCode === country.dial_code
                            ? "bg-wabmGreen"
                            : ""
                        }`}
                      >
                        {country.code + " +" + country.dial_code}
                      </Option>
                    ))}
                  </Select>
                </span>
                <span className="w-fit">
                  <Input
                    label="Enter Phone Number"
                    variant="standard"
                    color="blue"
                    required
                    labelProps={{
                      className: "text-slate-800",
                    }}
                    value={phoneNumber}
                    onChange={(e) =>
                      setPhoneNumber(phoneNumberSanitation(e.target.value))
                    }
                    className="my-input-box-1"
                  />
                </span>
                <button
                  type="button"
                  onClick={() => setShowAllContacts(!showAllContacts)}
                  className="my-hover-effect text-wabmBlue"
                >
                  <RiContactsBookFill size={28} />
                </button>
              </div>
            </div>
          )}

          {contactType !== "" && (
            <div className="flex flex-col gap-y-2">
              <label className="font-bold">
                Message Template <strong className="text-red-600">*</strong>
              </label>
              <Select
                label="Select Message Template"
                variant="standard"
                color="blue"
                labelProps={{
                  className: "text-slate-800",
                }}
                required
                ref={selectTemplateRef}
                onChange={(e) => setSelectedTemplate(JSON.parse(e))}
                className="my-input-box-1"
              >
                {allTemplates
                  ?.filter((template) => template?.status === "APPROVED")
                  .map((template) => (
                    <Option
                      key={template?.id}
                      value={JSON.stringify(template)}
                      className={`hover:bg-blue-300 focus:bg-blue-300 ${
                        selectedTemplate === JSON.stringify(template)
                          ? "bg-wabmGreen"
                          : ""
                      }`}
                    >
                      {template?.name}
                    </Option>
                  ))}
              </Select>
            </div>
          )}

          <TemplateParametersGetterSetter
            selectedTemplate={selectedTemplate}
            setBodyVarFields={setBodyVarFields}
            setHeaderVarFields={setHeaderVarFields}
            setUploadedMedia={setSelectedMedia}
            parametersToBeSent={(parameters) =>
              setParametersToBeSent(parameters)
            }
          />

          {selectedTemplate?.name &&
            selectedContactGroup !== "" &&
            contactType === "group" && (
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={resetOnClick}
                  className="my-button-style-cancel"
                >
                  <IoClose size={24} />
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="my-button-style-1 flex items-center gap-2 disabled:bg-slate-400"
                >
                  <p>Broadcast</p>
                  <BsBroadcastPin size={18} />
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => setToShowScheduleSettings(true)}
                  className="my-button-style-5 flex items-center gap-2 disabled:bg-slate-400"
                >
                  <p>Schedule</p>
                  <RiTimerLine />
                </button>
              </div>
            )}

          {selectedTemplate?.name &&
            phoneNumber !== "" &&
            contactType === "individual" && (
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={resetOnClick}
                  className="my-button-style-cancel"
                >
                  <IoClose size={24} />
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="my-button-style-1 flex items-center gap-2 disabled:bg-slate-400"
                >
                  <p>Send</p>
                  <IoSendSharp />
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => setToShowScheduleSettings(true)}
                  className="my-button-style-5 flex items-center gap-2 disabled:bg-slate-400"
                >
                  <p>Schedule</p>
                  <RiTimerLine />
                </button>
              </div>
            )}
        </form>
      )}
    </div>
  );
};

export default MessagingViaTemplate;
