import React from "react";

import { useContextMenu } from "../contexts/ContextMenuContextProvider";

const ContextMenu = () => {
  const { xPos, yPos, showMenu, menuItems, hideContextMenu, menuRef } =
    useContextMenu();

  if (!showMenu) return null;

  return (
    <ul
      className="context-menu"
      style={{ top: yPos, left: xPos }}
      ref={menuRef}
      onClick={hideContextMenu}
    >
      {menuItems.map((item, index) => (
        <li key={index} onClick={item.onClick}>
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
