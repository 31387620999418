import React, { useEffect } from "react";

import { RecentChats, ChatUi, SeoTags } from "..";
import { useUserContext } from "../../contexts/UserContextProvider";
import { useConversationContext } from "../../contexts/ConversationContextProvider";

const Chats = () => {
  const { requestNotificationPermission } = useUserContext();
  const { isShowChatHistory } = useConversationContext();

  useEffect(() => {
    requestNotificationPermission();
  }, [requestNotificationPermission]);

  return (
    <main className="w-full md:h-screen absolute top-0 left-0 grid grid-cols-1 md:grid-cols-6">
      <SeoTags title="Chats ‣ WaBM" />

      <RecentChats />
      <div className="bg-[url(../src/images/backgrounds/lightPattern1.webp)] bg-repeat bg-center w-full h-full md:col-span-3 lg:col-span-4 md:overflow-auto">
        {isShowChatHistory ? (
          <ChatUi />
        ) : (
          <div className="mx-auto h-full flex justify-center items-center">
            <p className="wabm-font-style-2">Open a Chat to see what's up.</p>
          </div>
        )}
      </div>
    </main>
  );
};

export default Chats;
