import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { NavLink, useLocation } from "react-router-dom";
import { IoPersonCircle, IoMenu, IoClose } from "react-icons/io5";
import { FaPowerOff } from "react-icons/fa";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  Button,
} from "@material-tailwind/react";

import { useUserContext } from "../contexts/UserContextProvider";
import { aboutApp } from "../config/appConfig";
import wabmLogo from "../images/logo/WaBM-192px.png";

const AppNavbar = () => {
  const { isLoggedIn, apiCallLogout } = useUserContext();
  const currentLocation = useLocation()?.pathname;

  const [openNav, setOpenNav] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  const NavList = () => {
    return (
      <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
        <li>
          <a href={aboutApp.website} className="my-hover-effect">
            ⇐ Back to Website
          </a>
        </li>
        <li>
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive ? "text-wabmGreen" : "my-hover-effect"
            }
          >
            <Typography className="font-normal">FAQs</Typography>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? "text-wabmGreen" : "my-hover-effect"
            }
          >
            <Typography className="font-normal">Contact</Typography>
          </NavLink>
        </li>

        {isLoggedIn ? (
          <>
            <NavLink
              to="/dashboard/overview"
              className="my-button-style-2 px-5"
            >
              <IoPersonCircle size={28} />
            </NavLink>

            <Popup
              trigger={
                <button className="my-button-style-1 px-5">
                  <FaPowerOff size="1.4rem" />
                </button>
              }
              position="bottom right"
            >
              <div className="my-popup-style-1">
                <p>Are you sure you want to log out?</p>
                <button
                  onClick={apiCallLogout}
                  className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
                >
                  <FaPowerOff size="0.8rem" />
                  Log Out
                </button>
              </div>
            </Popup>
          </>
        ) : currentLocation === "/login" ? (
          <NavLink to="/signup">
            <Button variant="gradient" color="blue" ripple={true}>
              Sign Up
            </Button>
          </NavLink>
        ) : (
          <NavLink to="/login">
            <Button variant="gradient" color="blue" ripple={true}>
              Login
            </Button>
          </NavLink>
        )}
      </ul>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {}, [isLoggedIn]);

  return (
    <Navbar className="bg-wabmAiBlue/100 mx-auto px-6 py-3">
      <div className="flex items-center justify-between">
        <NavLink to="/login">
          <span className="inline-flex items-center gap-5">
            <img src={wabmLogo} alt="WaBM Logo" className="w-12" />
            <Typography variant="h6" className="mr-4 cursor-pointer py-1.5">
              WaBM
            </Typography>
          </span>
        </NavLink>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <IoClose className="h-6 w-6" strokeWidth={2} />
          ) : (
            <IoMenu className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
};

export default AppNavbar;
