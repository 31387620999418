import React, { useState } from "react";

import { ChangePassword, SeoTags } from "..";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { aboutApp } from "../../config/appConfig.js";

const Settings = () => {
  const { wabInfo } = useWhatsappContext();
  const { currentUserInfo, isUserAdminOrSuperadmin } = useUserContext();

  const [isChangePassword, setIsChangePassword] = useState(false);

  const isChangePasswordCallback = (value) => {
    setIsChangePassword(value);
  };

  return (
    <main className="w-full min-h-screen mt-5 mb-10 lg:my-1 flex flex-col gap-3">
      <SeoTags title="Settings ‣ WaBM" />

      <div className="space-y-2">
        <h1 className="text-2xl">Settings</h1>
        <h3>
          Your personalized settings for WaBM Business Manager all at one place.
        </h3>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="wbm-card-2 h-fit flex flex-col gap-3">
          <h2 className="font-Montserrat text-lg">User Information</h2>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Corporate Id</h3>
            <p>{currentUserInfo?.corporateId}</p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Agent Name</h3>
            <p>{currentUserInfo?.agentName}</p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Agent Type</h3>
            <p>
              {currentUserInfo?.isAdmin === 2
                ? "Super Admin"
                : currentUserInfo?.isAdmin === 1
                ? "Admin"
                : "Agent"}
            </p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">
              Subscription Status
            </h3>
            <p>
              {currentUserInfo?.isActiveSubscription
                ? "Active"
                : currentUserInfo?.isTrialLapsed
                ? "Trial Active"
                : "Inactive"}
            </p>
          </span>
        </div>

        {isUserAdminOrSuperadmin() && (
          <div className="wbm-card-2 h-fit flex flex-col gap-3">
            <h2 className="font-Montserrat text-lg">
              WhatsApp Business Information
            </h2>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                WhatsApp Number
              </h3>
              <p>
                {wabInfo?.wabPhoneNumber === null
                  ? "-"
                  : "+" + wabInfo.wabPhoneNumber}
              </p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">Meta App ID</h3>
              <p>{wabInfo?.appId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">Account ID</h3>
              <p>{wabInfo?.wabAccountId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Phone Number ID
              </h3>
              <p>{wabInfo?.wabPhoneNumberId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                WhatsApp Business API
              </h3>
              <p>
                {currentUserInfo?.mandatoryWabApiCreds
                  ? "Configured"
                  : "Not Configured"}
              </p>
            </span>
          </div>
        )}

        <div className="wbm-card-2 h-fit space-y-2">
          <h3 className="font-Montserrat text-lg">Change Password</h3>
          <p>Set a new secure password for your WaBM Agent Account</p>
          {isChangePassword ? (
            <ChangePassword
              isChangePasswordCallback={isChangePasswordCallback}
            />
          ) : (
            <button
              onClick={() => setIsChangePassword(true)}
              className="my-button-style-1"
            >
              Change Password
            </button>
          )}
        </div>
      </div>

      <p className="my-5 text-sm text-slate-600">{aboutApp.version}</p>
    </main>
  );
};

export default Settings;
