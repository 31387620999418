import React, { useState } from "react";
import { toast } from "react-toastify";
import { RiTimerFlashFill } from "react-icons/ri";
import {
  Typography,
  Input,
  Select,
  Option,
  Button,
} from "@material-tailwind/react";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";

const CampaignScheduler = ({
  contactType,
  contactDetail,
  selectedTemplate,
  parametersToBeSent,
  bodyVal,
  headerVal,
  selectedMedia,
}) => {
  const { scheduleGroupCampaign, setToShowScheduleSettings, isLoading } =
    useWhatsappContext();

  const addHoursToCurrentDateTime = (hours) => {
    let date = new Date();
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().substring(0, 16);
  };

  const [scheduleName, setScheduleName] = useState("");
  const [scheduleDescription, setScheduleDescription] = useState("");
  const [scheduleType, setScheduleType] = useState("oneTime");

  const [selectedDateTime, setSelectedDateTime] = useState(
    addHoursToCurrentDateTime(0)
  );

  // Currently implemented for Contact Group. Modify it later to cater both
  const scheduleMessageOnSubmit = async (event) => {
    event.preventDefault();
    const mediaUploadStatus = await uploadMediaOnAWS(selectedMedia);
    if (
      mediaUploadStatus === EnumFileUploadStatus.FileUploadSuccess ||
      mediaUploadStatus === EnumFileUploadStatus.FileNotAvailable
    ) {
      const scheduleTime = new Date(selectedDateTime);
      const allowedTime = new Date();

      let headerField = [];
      if (headerVal.length > 0) {
        headerField = headerVal;
      } else {
        headerField = ["emptyHeader"];
      }

      if (scheduleTime.getTime() > allowedTime.getTime() + 10000) {
        scheduleGroupCampaign(
          scheduleName,
          scheduleDescription,
          scheduleType,
          scheduleTime.toISOString().substring(0, 16),
          contactDetail,
          selectedTemplate,
          parametersToBeSent,
          headerField.concat(bodyVal),
          contactType
        );
        setToShowScheduleSettings(false);
      } else {
        toast.warn("Please schedule campaign atleast 10 min from now");
      }
    }
  };

  return (
    <>
      <form onSubmit={scheduleMessageOnSubmit} className="space-y-5">
        <Typography variant="h4" color="blue">
          Schedule Marketing Campaign
        </Typography>
        <div className="space-y-3">
          <Input
            label="Schedule Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            required
            value={scheduleName}
            onChange={(e) => setScheduleName(e.target.value)}
            className="my-input-box-1"
          />
          <Input
            label="Schedule Description"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            value={scheduleDescription}
            onChange={(e) => setScheduleDescription(e.target.value)}
            className="my-input-box-1"
          />

          <Select
            label="Schedule Type"
            variant="standard"
            color="blue"
            required
            value={scheduleType}
            onChange={(e) => setScheduleType(e)}
            className="my-input-box-1"
          >
            <Option
              value="oneTime"
              className={`hover:bg-blue-300 focus:bg-blue-300`}
            >
              One Time
            </Option>
            <Option
              value="recurring"
              className={`hover:bg-blue-300 focus:bg-blue-300`}
            >
              Recurring
            </Option>
          </Select>

          {scheduleType === "oneTime" ? (
            <div className="space-y-5">
              <span className="grid grid-cols-2 gap-3">
                <p className="border-r border-slate-400">Schedule Time</p>
                <input
                  type="datetime-local"
                  value={selectedDateTime}
                  onChange={(event) => setSelectedDateTime(event.target.value)}
                  min={addHoursToCurrentDateTime(0.25)}
                  max={addHoursToCurrentDateTime(1080)}
                  className="my-input-style-1"
                />
              </span>
              <Button
                type="submit"
                variant="gradient"
                color="green"
                disabled={isLoading}
                className="inline-flex items-center gap-2"
              >
                <span>Confirm Schedule</span>
                <RiTimerFlashFill />
              </Button>
            </div>
          ) : (
            <p className="text-red-600 text-center">
              Recurring Notifications Coming Soon!
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default CampaignScheduler;
