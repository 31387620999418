import React, { useState } from "react";
import { RiTimerFlashFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";

const UpdateScheduleDate = ({ jobId }) => {
  const { rescheduleGroupCampaign } = useWhatsappContext();
  const addHoursToCurrentDateTime = (hours) => {
    let date = new Date();
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().substring(0, 16);
  };

  const [selectedDateTime, setSelectedDateTime] = useState(
    addHoursToCurrentDateTime(0)
  );

  // Currently implemented for Contact Group. Modify it later to cater both
  const scheduleMessageOnSubmit = (event) => {
    event.preventDefault();
    const scheduleTime = new Date(selectedDateTime);
    const allowedTime = new Date();

    if (scheduleTime.getTime() > allowedTime.getTime() + 10000) {
      rescheduleGroupCampaign(
        jobId,
        scheduleTime.toISOString().substring(0, 16)
      );
    } else {
      toast.warn("Please schedule campaign atleast 10 min from now");
    }
  };

  return (
    <main className="p-5 space-y-3">
      <h2 className="wabm-font-style-2 text-left">Reschedule Job</h2>
      <div className="w-full max-h-[70vh] rounded-md overflow-auto">
        <div className="space-y-10">
          <span className="grid grid-cols-2 gap-3">
            <p className="border-r border-slate-400">Schedule Time</p>
            <input
              type="datetime-local"
              value={selectedDateTime}
              onChange={(event) => setSelectedDateTime(event.target.value)}
              min={addHoursToCurrentDateTime(0.25)}
              max={addHoursToCurrentDateTime(1080)}
              className="my-input-style-1"
            />
          </span>
          <button
            onClick={scheduleMessageOnSubmit}
            type="button"
            className="my-button-style-1 mx-auto flex items-center gap-2 text-sm"
          >
            <p>Confirm Schedule</p>
            <RiTimerFlashFill />
          </button>
        </div>
      </div>
    </main>
  );
};

export default UpdateScheduleDate;
