export const businessTypes = [
  "Agriculture & Forestry",
  "Auto Components",
  "Automobile",
  "Aviation",
  "Fintech & Financial Services",
  "Biotechnology",
  "Capital Goods",
  "Chemicals",
  "Construction",
  "Defence Manufacturing",
  "Electronic Systems",
  "Fisheries & Aquaculture",
  "Food Processing",
  "Healthcare",
  "IT & BPM",
  "Leather",
  "Media",
  "Medical Devices",
  "Metals & Mining",
  "Oil & Gas",
  "Paper & Packaging",
  "Pharmaceuticals",
  "Ports & Shipping",
  "Railways",
  "Renewable Energy",
  "Retail & E-commerce",
  "Roads & Highways",
  "Telecom",
  "Textiles & Apparel",
  "Tourism & Hospitality",
  "Other",
  "Individual (Not a Business)",
];
