import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { GiBrokenPottery, GiSpinningWheel } from "react-icons/gi";
import { PiWarningDiamondFill } from "react-icons/pi";

import { usePaymentsContext } from "../../contexts/PaymentsContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import wabmLogo from "../../images/logo/WaBM-192px.png";

const PaymentResponse = () => {
  const { transactionId } = useParams();
  const { phonePePaymentDetailsById, phonePaymentDetails, isLoading } =
    usePaymentsContext();

  const { loginCheck } = useUserContext();

  const loadPaymentDetails = () => {
    phonePePaymentDetailsById(transactionId);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    loadPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [phonePaymentDetails]);

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="bg-wabmBlue w-full h-2" />

      <main className="space-y-5">
        {phonePaymentDetails?.length > 0 ? (
          phonePaymentDetails?.map((payment, index) => {
            if (payment.payment_status === 1) {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center gap-1"
                >
                  <img
                    src={wabmLogo}
                    alt="thanks for subscribing to WaBM"
                    className="w-24 mx-auto mb-5"
                  />
                  <h2 className="text-xl font-semibold">Payment Successful</h2>
                  <p>Thanks for Subscribing to WaBM!</p>

                  <div className="my-5 p-5 grid grid-cols-2 gap-2 border border-slate-300 rounded-md">
                    <h4 className="border-r border-slate-300">
                      Payment Status
                    </h4>
                    <p>{payment.payment_response.state}</p>

                    <h4 className="border-r border-slate-300">
                      Merchant Transaction ID
                    </h4>
                    <p>{payment.payment_response.merchantTransactionId}</p>

                    <h4 className="border-r border-slate-300">Payment Date</h4>
                    <p>{payment.create_date}</p>

                    <h4 className="border-r border-slate-300">
                      Payment Amount
                    </h4>
                    <p>{(payment.payment_response.amount / 100).toFixed(2)}</p>

                    <h4 className="border-r border-slate-300">
                      Transaction ID
                    </h4>
                    <p>{payment.payment_response.transactionId}</p>

                    <h4 className="border-r border-slate-300">
                      Payment Method
                    </h4>
                    <p>{payment.payment_response.paymentInstrument.type}</p>
                  </div>

                  <NavLink to="/login">
                    <button onClick={loginCheck} className="my-button-style-1">
                      ⇐ Back to WaBM
                    </button>
                  </NavLink>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center gap-1"
                >
                  <PiWarningDiamondFill size={64} className="text-amber-300" />

                  <h2 className="text-xl font-semibold">Payment in Progress</h2>
                  <p>
                    Payment is in progress, please check the status after
                    sometime.
                  </p>

                  <NavLink to="/login">
                    <button
                      onClick={loginCheck}
                      className="my-button-style-1 mt-1"
                    >
                      ⇐ Back to WaBM
                    </button>
                  </NavLink>

                  <button
                    onClick={refreshPage}
                    className="my-button-style-1 mt-1"
                  >
                    ↻ Refresh
                  </button>
                </div>
              );
            }
          })
        ) : (
          <>
            {isLoading === 1 ? (
              <div className="flex flex-col justify-center items-center gap-1">
                <GiBrokenPottery size={64} className="text-red-500" />
                <h2 className="text-xl font-semibold">Are you Lost?</h2>
                <p>Looks like you're trying to access Invalid Page</p>

                <NavLink to="/login">
                  <button
                    onClick={loginCheck}
                    className="my-button-style-1 mt-1"
                  >
                    ⇐ Back to WaBM
                  </button>
                </NavLink>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center gap-1">
                <GiSpinningWheel size={64} className="text-blue-600" />
                <p>Loading....</p>
              </div>
            )}
          </>
        )}
      </main>

      <div className="bg-wabmBlue w-full h-2" />
    </div>
  );
};
export default PaymentResponse;
