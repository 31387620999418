import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsMeta } from "react-icons/bs";
import { Switch } from "@material-tailwind/react";

import { AppNavbar, Footer } from "../components";
import { useUserContext } from "../contexts/UserContextProvider";
import { initFacebookSdK } from "../helpers/FacebookSDKInit.js";
import {
  phoneNumberSanitation,
  inputMessageSanitation,
} from "../components/utils/inputSanitation";
import { appSettings } from "../config/appConfig";
import wabmSetup from "../images/props/wabm-setup.png";

const AppSetup = () => {
  const { apiCallSaveWabCreds } = useUserContext();
  const navigate = useNavigate();

  const [enableEmbeddedSignup, setEnableEmbeddedSignup] = useState(true);

  const [wabPhoneNumber, setWabPhoneNumber] = useState("");
  const [wabPhoneNumberId, setWabPhoneNumberId] = useState("");
  const [wabAccountId, setWabAccountId] = useState("");
  const [wabAccessToken, setWabAccessToken] = useState("");
  const [wabAppId, setWabAppId] = useState("");

  // Facebook Login with JavaScript SDK
  const facebookLogin = () => {
    // Launch Facebook login
    window.FB.login(
      async (response) => {
        // console.log("facebook Login response", response);

        if (response?.authResponse) {
          const code = response?.authResponse.code;
          let result = await apiCallSaveWabCreds("", "", "", "", "", code);
          if (result?.message) {
            resetStates();
            setTimeout(() => navigate("/login"), 2000);
          }
        } else {
          console.log("User cancelled login or FB login did not authorize.");
        }
      },
      {
        config_id: appSettings.facebookConfigId, // configuration ID goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          sessionInfoVersion: 2,
          setup: {},
        },
      }
    );
  };

  const resetStates = () => {
    setWabPhoneNumber("");
    setWabPhoneNumberId("");
    setWabAccountId("");
    setWabAccessToken("");
    setWabAppId("");
  };

  const saveWabCredsOnSubmit = (event) => {
    event.preventDefault();

    apiCallSaveWabCreds(
      wabPhoneNumber,
      wabPhoneNumberId,
      wabAccountId,
      wabAccessToken,
      wabAppId,
      ""
    );

    resetStates();
    setTimeout(() => navigate("/login"), 2000);
  };

  useEffect(() => {
    setWabPhoneNumber((prev) => prev.replace(" ", ""));
    setWabPhoneNumberId((prev) => prev.replace(" ", ""));
    setWabAccountId((prev) => prev.replace(" ", ""));
    setWabAccessToken((prev) => prev.replace(" ", ""));
    setWabAppId((prev) => prev.replace(" ", ""));
  }, [
    wabPhoneNumber,
    wabPhoneNumberId,
    wabAccountId,
    wabAccessToken,
    wabAppId,
  ]);

  useEffect(() => {
    initFacebookSdK().then((x) => {
      console.log("initFacebookSdK x", x);
    });
  }, []);

  return (
    <div className="bg-[url(../src/images/props/y-so-serious-white.png)] bg-repeat min-h-screen flex flex-col justify-between overflow-x-hidden">
      <AppNavbar />
      <main className="container mx-auto px-5 pt-36 pb-24 space-y-20">
        <div className="mb-12 flex flex-col justify-center items-center gap-3">
          <h1 className="font-Montserrat text-3xl text-wabmBlue font-bold">
            Let's get you started with sending WhatsApp Business Messages
          </h1>
          <p className="text-lg text-center">
            Just Login with Meta's Business Account and setup your account.
          </p>
        </div>

        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10">
          <img src={wabmSetup} className="mx-auto" alt="WaBM Setup" />

          <div>
            <div className="my-card-style-3 flex items-center gap-3">
              <Switch
                ripple={true}
                className="bg-slate-300 checked:bg-wabmGreen h-full w-full"
                containerProps={{
                  className: "w-12 h-8",
                }}
                circleProps={{
                  className: "before:hidden left-0.5 border-none",
                }}
                onClick={() => setEnableEmbeddedSignup(!enableEmbeddedSignup)}
              />
              {enableEmbeddedSignup
                ? "Switch to Manual Setup"
                : "Toggle Back to Easy Onboarding with Meta"}
            </div>

            {enableEmbeddedSignup ? (
              <div className="py-10 space-y-1">
                <p>
                  🚀 Get ready to supercharge your customer interactions with
                  WaBM, powered by our seamless WhatsApp Business API
                  integration.
                </p>
                <div className="py-5">
                  <BsMeta size={84} className="text-wabmBlue" />
                </div>
                <p className="text-md font-semibold">
                  Get onboarded quickly with Meta's easy process.
                </p>
                <button
                  onClick={facebookLogin}
                  className="my-button-style-5"
                  type="button"
                >
                  Setup WhatsApp Business
                </button>
              </div>
            ) : (
              <div className="py-10 space-y-3">
                <p>
                  Head over to your Facebook Apps Dashboard, extract some basic
                  info and connect WaBM with it.
                </p>
                <a
                  href="https://developers.facebook.com/apps/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="my-hover-effect text-wabmBlue text-lg underline italic"
                >
                  Go to Facebook Developers ⇒
                </a>

                <form
                  onSubmit={saveWabCredsOnSubmit}
                  className="my-card-style-2 w-full flex flex-col gap-5 drop-shadow-md"
                >
                  <span className="space-y-1">
                    <label className="text-wabmAiBlue font-medium">
                      WhatsApp Phone Number{" "}
                      <strong className="text-red-600">*</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your WhatsApp Phone Number"
                      value={wabPhoneNumber}
                      required
                      onChange={(e) =>
                        setWabPhoneNumber(phoneNumberSanitation(e.target.value))
                      }
                      className="my-input-style-1 w-full drop-shadow-lg"
                    />
                  </span>
                  <span className="space-y-1">
                    <label className="text-wabmAiBlue font-medium">
                      Phone Number ID{" "}
                      <strong className="text-red-600">*</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Business ID"
                      value={wabPhoneNumberId}
                      required
                      onChange={(e) =>
                        setWabPhoneNumberId(
                          phoneNumberSanitation(e.target.value)
                        )
                      }
                      className="my-input-style-1 w-full drop-shadow-lg"
                    />
                  </span>
                  <span className="space-y-1">
                    <label className="text-wabmAiBlue font-medium">
                      Business Account ID{" "}
                      <strong className="text-red-600">*</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Account ID"
                      value={wabAccountId}
                      required
                      onChange={(e) =>
                        setWabAccountId(phoneNumberSanitation(e.target.value))
                      }
                      className="my-input-style-1 w-full drop-shadow-lg"
                    />
                  </span>
                  <span className="space-y-1">
                    <label className="text-wabmAiBlue font-medium">
                      App ID
                      <strong className="text-red-600">*</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Meta App ID"
                      value={wabAppId}
                      required
                      onChange={(e) =>
                        setWabAppId(phoneNumberSanitation(e.target.value))
                      }
                      className="my-input-style-1 w-full drop-shadow-lg"
                    />
                  </span>
                  <span className="space-y-1">
                    <label className="text-wabmAiBlue font-medium">
                      WhatsApp Access Token{" "}
                      <strong className="text-red-600">*</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your WhatsApp Access Token"
                      value={wabAccessToken}
                      required
                      onChange={(e) =>
                        setWabAccessToken(
                          inputMessageSanitation(e.target.value)
                        )
                      }
                      className="my-input-style-1 w-full drop-shadow-lg"
                    />
                  </span>

                  <input
                    type="submit"
                    value="Set Up"
                    className="my-button-style-1 w-full"
                  />
                </form>

                <div>
                  <p className="text-md font-semibold">
                    Connect with our Support team if you want us to set this up
                    for you quickly.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      <section className="bg-wabmBlue h-2/3 w-full">
        <div className="container m-auto px-5 py-24 text-center space-y-5">
          <h2 className="text-3xl text-white">
            Trouble setting up your Account?
          </h2>
          <p className="pb-10">
            Connect with us. We'll be happy to assist you!
          </p>
          <Link to="/contact">
            <button className="my-button-style-3 hover:bg-wabmAiBlue">
              Get Support
            </button>
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AppSetup;
