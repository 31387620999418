export const waSupportedLanguages = {
  af: "Afrikaans",
  sq: "Shqip", // Albanian
  ar: "العربية", // Arabic
  az: "Azərbaycan dili", // Azerbaijani
  bn: "বাংলা", // Bengali
  bg: "Български", // Bulgarian
  ca: "Català", // Catalan
  zh_CN: "中文 (中国)", // Chinese (CHN)
  zh_HK: "中文 (香港)", // Chinese (HKG)
  zh_TW: "中文 (台灣)", // Chinese (TAI)
  hr: "Hrvatski", // Croatian
  cs: "Čeština", // Czech
  da: "Dansk", // Danish
  nl: "Nederlands", // Dutch
  en: "English",
  en_GB: "English (UK)",
  en_US: "English (US)",
  et: "Eesti", // Estonian
  fil: "Filipino",
  fi: "Suomi", // Finnish
  fr: "Français", // French
  ka: "ქართული", // Georgian
  de: "Deutsch", // German
  el: "Ελληνικά", // Greek
  gu: "ગુજરાતી", // Gujarati
  ha: "Hausa",
  he: "עברית", // Hebrew
  hi: "हिन्दी", // Hindi
  hu: "Magyar", // Hungarian
  id: "Bahasa Indonesia", // Indonesian
  ga: "Irish",
  it: "Italiano", // Italian
  ja: "日本語", // Japanese
  kn: "ಕನ್ನಡ", // Kannada
  kk: "Қазақ тілі", // Kazakh
  rw_RW: "Ikinyarwanda", // Kinyarwanda
  ko: "한국어", // Korean
  ky_KG: "Кыргыз тили (Кыргызстан)", // Kyrgyz (Kyrgyzstan)
  lo: "ລາວ", // Lao
  lv: "Latviešu", // Latvian
  lt: "Lietuvių", // Lithuanian
  mk: "Македонски", // Macedonian
  ms: "Bahasa Melayu", // Malay
  ml: "മലയാളം", // Malayalam
  mr: "मराठी", // Marathi
  nb: "Norsk bokmål", // Norwegian
  fa: "فارسی", // Persian
  pl: "Polski", // Polish
  pt_BR: "Português (Brasil)", // Portuguese (BR)
  pt_PT: "Português (Portugal)", // Portuguese (POR)
  pa: "ਪੰਜਾਬੀ", // Punjabi
  ro: "Română", // Romanian
  ru: "Русский", // Russian
  sr: "Српски", // Serbian
  sk: "Slovenčina", // Slovak
  sl: "Slovenščina", // Slovenian
  es: "Español", // Spanish
  es_AR: "Español (Argentina)", // Spanish (ARG)
  es_ES: "Español (España)", // Spanish (SPA)
  es_MX: "Español (México)", // Spanish (MEX)
  sw: "Kiswahili", // Swahili
  sv: "Svenska", // Swedish
  ta: "தமிழ்", // Tamil
  te: "తెలుగు", // Telugu
  th: "ไทย", // Thai
  tr: "Türkçe", // Turkish
  uk: "Українська", // Ukrainian
  ur: "اردو", // Urdu
  uz: "O‘zbekcha", // Uzbek
  vi: "Tiếng Việt", // Vietnamese
  zu: "isiZulu", // Zulu
};
