import React, { useState, useEffect } from "react";
import {
  Typography,
  CardBody,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { IoMdSearch } from "react-icons/io";
import { IoClose, IoPersonCircle, IoArrowBackSharp } from "react-icons/io5";
import { PiCopyFill } from "react-icons/pi";
import { RiDeleteBin7Fill } from "react-icons/ri";

import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { isoTimestampToReadable } from "../utils/timeStampConversion";
import { loadMoreContact } from "../../data/messageNotifierServices";
import { CreateContactGroup } from "..";

const ManageContactGroups = ({ drawerStatus, callbackHideContactGroup }) => {
  const selectedGroupTableHeads = [
    "",
    "S.No.",
    "Contact Name",
    "Phone No.",
    "Last Contacted",
    "Remarks",
  ];

  const {
    contactGroups,
    selectedGroupContacts,
    apiCallDeleteContactGroup,
    apiCallFetchGroupContacts,
    apiCallRemoveContactsFromGroup,
    apiCallContactsInsights,
    apiCallFetchContactGroups,
    apiCallCopyContactFromGroup,
  } = useContactsContext();
  const { currentUserInfo } = useUserContext();

  const [showSelectedGroup, setShowSelectedGroup] = useState(false);
  const [selectedGroupToView, setSelectedGroupToView] = useState("");
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);
  const [contactsSelected, setContactsSelected] = useState([]);
  const [searchContact, setSearchContact] = useState("");
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);
  const [showCopyContactDialog, setShowCopyContactDialog] = useState(false);
  const [targetContactGroup, setTargetContactGroup] = useState("");

  const resetStates = () => {
    setShowSelectedGroup(false);
    setOpenDeleteGroupDialog(false);
    setSelectedGroupToView("");
    setContactsSelected([]);
    setSearchContact("");
    setOpenGroupCreationDialog();
  };

  const changeSearchText = (text) => {
    setSearchContact(text.replace("+", ""));
  };

  const handleGroupToViewOnClick = (event, selectedGroup) => {
    event.preventDefault();
    if (selectedGroup !== "") {
      apiCallFetchGroupContacts(selectedGroup, searchContact);
      setShowSelectedGroup(true);
    }
  };

  const selectMultipleContactsOnClick = (selectedData) => {
    if (contactsSelected.includes(selectedData)) {
      setContactsSelected((current) =>
        current.filter((data) => data !== selectedData)
      );
    } else {
      setContactsSelected((current) => [...current, selectedData]);
    }
  };

  const removeContactsFromGroupOnClick = async (event) => {
    event.preventDefault();

    if (
      contactGroups.filter(
        (x) => x.groupName === selectedGroupToView?.groupName
      )
    ) {
      apiCallRemoveContactsFromGroup(
        contactsSelected,
        selectedGroupToView?.groupName
      );
    }

    setContactsSelected(() => []);
  };

  const handelCopyContact = async (event) => {
    event.preventDefault();
    const fromGroup = selectedGroupToView.groupName;
    const toGroup = targetContactGroup;
    await apiCallCopyContactFromGroup(fromGroup, toGroup, contactsSelected);
    setShowCopyContactDialog(false);
    setTargetContactGroup("");
  };

  const handleDeleteOnClick = async (groupNameToBeDeleted) => {
    const resp = await apiCallDeleteContactGroup(groupNameToBeDeleted);
    if (resp) {
      resetStates();
      apiCallContactsInsights();
      await apiCallFetchContactGroups();
    }
  };

  const handleScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  useEffect(() => {
    if (!drawerStatus) resetStates();
  }, [drawerStatus]);

  return (
    <>
      {showSelectedGroup ? (
        <div className="h-full w-full flex flex-col justify-between">
          <div className="space-y-5">
            <div className="flex justify-between items-center gap-1">
              <span className="flex items-center gap-5">
                <button
                  type="button"
                  onClick={() => {
                    setShowSelectedGroup(false);
                    setContactsSelected([]);
                  }}
                >
                  <IoArrowBackSharp
                    size={20}
                    className="my-smooth-transition-1 text-wabmBlue hover:text-wabmGreen"
                  />
                </button>
                <Typography variant="h5" color="blue-gray">
                  {selectedGroupToView?.groupName}
                </Typography>
              </span>
              <span className="text-sm">
                <span>Max contacts allowed in a group: </span>
                <span>{currentUserInfo?.maxContactsInGroup}</span>
              </span>
              <form
                onSubmit={(e) => {
                  handleGroupToViewOnClick(e, selectedGroupToView?.groupName);
                }}
                className="mt-2 flex items-left gap-1 text-sm"
              >
                <Input
                  label="Search Phone No. or Name"
                  variant="standard"
                  color="blue"
                  icon={<IoMdSearch className="h-4 w-4" />}
                  value={searchContact}
                  onChange={(e) =>
                    changeSearchText(e.target.value.replace("+", ""))
                  }
                  className="w-full md:w-72"
                />
              </form>
            </div>
            <CardBody
              onScroll={handleScroll}
              className="max-h-[70vh] p-0 overflow-y-scroll"
            >
              {selectedGroupContacts?.length > 0 ? (
                <table className="w-full min-w-max table-auto text-left">
                  <thead className="bg-slate-200 sticky -top-1 z-10">
                    <tr className="text-center">
                      {selectedGroupTableHeads.map((head) => (
                        <th
                          key={head}
                          className="p-4 border-r border-white bg-blue-gray-50/50"
                        >
                          <Typography
                            variant="small"
                            className="font-normal leading-none"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedGroupContacts?.map((contact, index) => {
                      const isLast =
                        index === selectedGroupContacts?.length - 1;
                      const classes = isLast
                        ? "px-2"
                        : "px-2 border-b border-blue-gray-50";

                      return (
                        <tr key={contact?.indx} className="text-center">
                          <td className={classes}>
                            <Checkbox
                              id="ripple-on"
                              ripple={true}
                              checked={contactsSelected.includes(
                                JSON.stringify({
                                  countryCode: contact?.countryCode,
                                  phoneNo: contact?.phoneNo,
                                })
                              )}
                              onClick={() =>
                                selectMultipleContactsOnClick(
                                  JSON.stringify({
                                    countryCode: contact?.countryCode,
                                    phoneNo: contact?.phoneNo,
                                  })
                                )
                              }
                            />
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {index + 1}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <div className="px-5 flex items-center gap-3">
                              <IoPersonCircle
                                size={20}
                                className="text-slate-800"
                              />
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {`${contact?.firstName} ${contact?.lastName}`}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {`+${contact?.countryCode} ${contact?.phoneNo}`}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {contact?.lastContacted
                                ? isoTimestampToReadable(contact.lastContacted)
                                : "-"}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {contact?.remarks}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="pt-3 pl-4 text-sm italic">
                  This contact group does not have any contact numbers yet. You
                  can add some from <strong>All Contacts</strong>.
                </p>
              )}
            </CardBody>
          </div>
          <div className="my-card-style-1 flex justify-between gap-3">
            {contactsSelected?.length === 0 && (
              <span className="flex items-center gap-3">
                <Button
                  type="button"
                  variant="gradient"
                  color="blue"
                  onClick={async (e) => {
                    loadMoreContact.next({
                      groupName: selectedGroupToView.groupName,
                      contactInGroup: selectedGroupContacts,
                      drawerStatus: true,
                    });
                  }}
                  className="inline-flex items-center gap-2"
                >
                  <PiCopyFill size="16px" />
                  Add Contacts in this Group
                </Button>
                <Button
                  type="button"
                  variant="gradient"
                  color="red"
                  onClick={() =>
                    setOpenDeleteGroupDialog(!openDeleteGroupDialog)
                  }
                  className="inline-flex items-center gap-2"
                >
                  <RiDeleteBin7Fill size="16px" />
                  Delete this Group
                </Button>
              </span>
            )}
            {contactsSelected?.length > 0 && (
              <span className="flex items-center gap-3">
                <Button
                  type="button"
                  variant="gradient"
                  color="blue"
                  onClick={async (e) => {
                    await apiCallFetchContactGroups();
                    setShowCopyContactDialog(true);
                  }}
                  className="inline-flex items-center gap-2"
                >
                  <PiCopyFill size="16px" />
                  Copy contacts to another group
                </Button>
                <Button
                  type="button"
                  variant="gradient"
                  color="red"
                  onClick={removeContactsFromGroupOnClick}
                >
                  <span>Remove selected contacts from this group</span>
                </Button>
              </span>
            )}
            {contactsSelected?.length > 0 && (
              <button
                onClick={() => setContactsSelected([])}
                className="my-button-style-cancel inline-flex items-center gap-1"
              >
                <IoClose size={18} /> Cancel
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="h-full w-full space-y-5">
          <div className="flex justify-between items-center gap-5">
            <div>
              <Typography variant="h5" color="blue-gray">
                Manage Contact Groups
              </Typography>
              <p className="mt-1">
                These are details about all your saved contact groups
              </p>
            </div>

            <div className="flex items-center gap-5">
              <Button
                color="blue"
                onClick={() => setOpenGroupCreationDialog(true)}
                className="w-48 whitespace-nowrap"
              >
                Create New Group
              </Button>
              <button
                onClick={() => callbackHideContactGroup()}
                className="my-button-style-cancel"
              >
                <IoClose size={18} />
              </button>
            </div>
          </div>
          <CardBody
            onScroll={handleScroll}
            style={{ height: "80vh" }}
            className="p-0 overflow-y-scroll"
          >
            {contactGroups.length > 0 ? (
              <table className="w-full min-w-max mb-5 table-auto text-left">
                <thead className="bg-slate-200 sticky -top-1 z-10">
                  <tr className="grid grid-cols-6 gap-3 text-center">
                    <th className="p-4 border-r border-white bg-blue-gray-50/50">
                      <Typography
                        variant="small"
                        className="font-normal leading-none"
                      >
                        S.No.
                      </Typography>
                    </th>
                    <th className="col-span-3 p-4 border-r border-white bg-blue-gray-50/50">
                      <Typography
                        variant="small"
                        className="font-normal leading-none"
                      >
                        Group Name
                      </Typography>
                    </th>
                    <th className="p-4 border-r border-white bg-blue-gray-50/50">
                      <Typography
                        variant="small"
                        className="font-normal leading-none"
                      >
                        No. of Contacts
                      </Typography>
                    </th>
                    <th className="p-4">
                      <Typography
                        variant="small"
                        className="font-normal leading-none"
                      >
                        Action
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contactGroups?.map((group, index) => {
                    const isLast = index === contactGroups?.length - 1;
                    const classes = isLast
                      ? "p-2"
                      : "p-2 border-b border-blue-gray-50";

                    return (
                      <tr
                        key={index}
                        className="grid grid-cols-6 gap-3 text-center"
                      >
                        <td className={`${classes}`}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={`${classes} col-span-3 text-left`}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {group?.groupName}
                          </Typography>
                        </td>
                        <td className={`${classes}`}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {group?.numberOfContacts}
                          </Typography>
                        </td>
                        <td className={`${classes}`}>
                          <button
                            type="button"
                            onClick={(e) => {
                              setSelectedGroupToView(group);
                              handleGroupToViewOnClick(e, group?.groupName);
                            }}
                            className="wbm-cta-btn-2"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="pt-3 pl-4 text-sm italic">
                You don't have any contact groups yet. Create one to simplify
                your Campaign Management.
              </p>
            )}
          </CardBody>
        </div>
      )}

      <Dialog
        open={openDeleteGroupDialog}
        handler={() => setOpenDeleteGroupDialog(!openDeleteGroupDialog)}
      >
        <DialogHeader>Delete Contact Group</DialogHeader>
        <DialogBody>
          Do you want to delete the contact group{" "}
          <strong>"{selectedGroupToView?.groupName}"</strong>? Please note that
          this action is irreversible but the contacts in this group will still
          be present in <strong>All Contacts</strong>.
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpenDeleteGroupDialog(!openDeleteGroupDialog)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleDeleteOnClick(selectedGroupToView?.groupName)}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <CreateContactGroup
            callbackHideContactGroup={() => {
              setOpenGroupCreationDialog(!openGroupCreationDialog);
            }}
          />
        </DialogBody>
      </Dialog>
      <Dialog
        open={showCopyContactDialog}
        handler={() => setShowCopyContactDialog(!showCopyContactDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>
            Select group to copy contact from{" "}
            <span className="text-green-700">
              {" "}
              ({selectedGroupToView.groupName})
            </span>
          </DialogHeader>
        </span>
        <form onSubmit={handelCopyContact}>
          <DialogBody>
            <Select
              variant="outlined"
              label="Select Contact Group"
              color="blue"
              required
              value={targetContactGroup}
              onChange={(e) => setTargetContactGroup(e)}
              className="w-full md:w-96"
            >
              {contactGroups
                .filter((x) => x.groupName !== selectedGroupToView.groupName)
                ?.map((group, index) => (
                  <Option key={index} value={group?.groupName}>
                    {group?.groupName} (
                    {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                    contacts)
                  </Option>
                ))}
            </Select>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="text"
              color="red"
              onClick={() => setShowCopyContactDialog(false)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button
              type="submit"
              color="blue"
              className="w-48 whitespace-nowrap"
            >
              Copy Contact to Group
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
};

export default ManageContactGroups;
