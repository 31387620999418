import React, { useState, useEffect } from "react";
import { Input, Card, Typography } from "@material-tailwind/react";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import { useContactsContext } from "../../contexts/ContactsContextProvider";

const ShowAllContacts = ({ selectedContactCallback, callbackHideContact }) => {
  const {
    dbContacts,
    totalPages,
    apiCallFetchUserContacts,
    apiCallFetchTablePaging,
    apiCallFetchContactGroups,
  } = useContactsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContact, setSearchContact] = useState("");
  const [filterContact, setFilterContact] = useState([]);

  const getContactsOnClick = (page) => {
    setCurrentPage(page);
    apiCallFetchUserContacts(page, searchContact);
    apiCallFetchTablePaging(searchContact);
  };

  const handleContactSearch = () => {
    const numberRegex = /\d{3,}/g;
    const letterRegex = /[A-z]{3,}/gi;

    if (searchContact && searchContact?.trim() !== "") {
      let contacts = dbContacts.filter(
        (currentContact) =>
          (numberRegex.test(searchContact) &&
            currentContact?.phoneNo &&
            currentContact?.phoneNo.includes(searchContact)) ||
          (letterRegex.test(searchContact) &&
            (currentContact?.firstName || currentContact?.lastName) &&
            (currentContact?.firstName + " " + currentContact?.lastName)
              .toLowerCase()
              .includes(searchContact.toLowerCase()))
      );

      if (contacts.length > 0) {
        setFilterContact(contacts);
        return true;
      } else {
        return false;
      }
    } else if (dbContacts && dbContacts.length > 0) {
      setFilterContact(dbContacts);
      return true;
    } else {
      return false;
    }
  };

  const getContactsOnSubmit = (event) => {
    event.preventDefault();
    if (!handleContactSearch()) {
      getContactsOnClick(currentPage);
    }
  };

  const handlePrevious = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const contact = await apiCallFetchUserContacts(
        currentPage - 1,
        searchContact
      );
      setFilterContact(contact);
    }
  };

  const handleNext = async () => {
    if (currentPage < totalPages.length) {
      setCurrentPage(currentPage + 1);
      const contact = await apiCallFetchUserContacts(
        currentPage + 1,
        searchContact
      );
      setFilterContact(contact);
    }
  };

  const getVisiblePages = () => {
    const start = Math.floor((currentPage - 1) / 5) * 5 + 1;
    const end = Math.min(start + 4, totalPages.length);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  useEffect(() => {
    apiCallFetchContactGroups();
    apiCallFetchUserContacts(1, searchContact);
    apiCallFetchTablePaging(searchContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterContact(dbContacts);
  }, [dbContacts]);

  return (
    <Card className="h-full w-full p-3 space-y-3">
      <div className="space-y-3">
        <div>
          <Typography variant="h5" color="blue-gray">
            Manage All Contacts
          </Typography>
          <button
            onClick={() => callbackHideContact()}
            className="my-button-style-cancel absolute top-5 right-5"
          >
            <IoClose size={18} />
          </button>
          <p className="mt-1">
            These are details about all your saved contacts
          </p>
        </div>
        <form onSubmit={getContactsOnSubmit} className="w-full flex gap-2">
          <Input
            label="Search Phone No. or Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            icon={<IoMdSearch className="h-4 w-4" />}
            placeholder="Type Phone No. or Name to Search"
            value={searchContact}
            onChange={(event) =>
              setSearchContact(event.target.value.replace("+", ""))
            }
            className="my-input-box-1"
          />
        </form>
      </div>
      <div className="h-full space-y-1 overflow-y-scroll">
        {filterContact?.length > 0 &&
          filterContact?.map((contact, index) => {
            return (
              <button
                key={index}
                onClick={() =>
                  selectedContactCallback({
                    countryCode: contact.countryCode,
                    phoneNo: contact.phoneNo,
                  })
                }
                className="my-data-row-1"
              >
                <p className="">{contact.firstName + " " + contact.lastName}</p>
                <p className="">
                  +{contact.countryCode + " " + contact.phoneNo}
                </p>
              </button>
            );
          })}
      </div>
      <div className="flex items-center gap-2">
        <div className="flex space-x-2">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
            }`}
          >
            Previous
          </button>
          {getVisiblePages().map((page) => (
            <button
              key={page}
              onClick={() => getContactsOnClick(page)}
              className={`px-4 py-2 rounded ${
                currentPage === page ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages.length}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages.length
                ? "bg-gray-300"
                : "bg-blue-500 text-white"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ShowAllContacts;
