import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Select, Option } from "@material-tailwind/react";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import {
  convertUnixTimestampToYearMonth,
  getUnixTimestamps,
} from "../utils/timeStampConversion";
import { colorsConfig } from "../../config/colorsConfig";

const WhatsAppConversationInsights = () => {
  const { conversationInsights, getConversationInsights } =
    useWhatsappContext();
  const [convDurInMo, setConvDurInMo] = useState("3");

  const wabInsightsColumns = [
    {
      name: "Month",
      selector: (row) => convertUnixTimestampToYearMonth(row.start),
      sortable: true,
    },
    {
      name: "Conversation",
      selector: (row) => row.conversation,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) =>
        row.conversation_type === "REGULAR" ? (
          <p className="text-green-600 px-3 py-2 rounded-md">
            {row.conversation_type.replace(/_/g, " ")}
          </p>
        ) : row.conversation_type === "FREE_TIER" ? (
          <p className="text-sky-600 px-3 py-2 rounded-md">
            {row.conversation_type.replace(/_/g, " ")}
          </p>
        ) : (
          <p className="text-slate-600 px-3 py-2 rounded-md">
            {row.conversation_type.replace(/_/g, " ")}
          </p>
        ),
      sortable: true,
    },
    // {
    //   name: "Initiated By",
    //   selector: (row) => row.conversation_direction.replace("_INITIATED", ""),
    //   sortable: true,
    // },
    {
      name: "Cost",
      selector: (row) => Math.round(row.cost, 2),
      sortable: true,
    },
  ];

  useEffect(() => {
    const timestamps = getUnixTimestamps(convDurInMo);
    getConversationInsights("MONTHLY", timestamps.start, timestamps.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convDurInMo]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-end gap-3">
        <h3 className="font-Montserrat text-lg text-wabmBlue">
          Conversation Insights
        </h3>
        <span className="w-full md:w-44 mr-5">
          <Select
            variant="outlined"
            label="Select Contact Group"
            color="blue"
            required
            value={convDurInMo}
            onChange={(e) => setConvDurInMo(e)}
          >
            <Option value="3" className="hover:bg-blue-300 focus:bg-blue-300">
              3 months
            </Option>
            <Option value="6" className="hover:bg-blue-300 focus:bg-blue-300">
              6 months
            </Option>
            <Option value="12" className="hover:bg-blue-300 focus:bg-blue-300">
              12 months
            </Option>
          </Select>
        </span>
      </div>

      <div className="rounded-md">
        <DataTable
          columns={wabInsightsColumns}
          data={conversationInsights}
          customStyles={wabInsightsStyles}
          fixedHeader
          fixedHeaderScrollHeight="50vh"
          className="text-sm bg-slate-600"
        />
      </div>
    </div>
  );
};

export default WhatsAppConversationInsights;

const wabInsightsStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
