import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Select, Option } from "@material-tailwind/react";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { getUnixTimestamps } from "../utils/timeStampConversion";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MessageSentReceivedGraph = ({ conversationData }) => {
  // Step 1: Parse timestamps and group data by month and year
  const dataByMonth = conversationData.reduce((acc, curr) => {
    const startDate = new Date(curr.start * 1000); // Convert UNIX timestamp to milliseconds
    const monthYear = `${startDate.getMonth() + 1}/${startDate.getFullYear()}`;

    if (!acc[monthYear]) {
      acc[monthYear] = { sent: 0, delivered: 0 };
    }

    acc[monthYear].sent += curr.sent;
    acc[monthYear].delivered += curr.delivered;

    return acc;
  }, {});

  // Step 2: Prepare data for rendering
  const labels = Object.keys(dataByMonth);
  const sentData = labels.map((month) => dataByMonth[month].sent);
  const deliveredData = labels.map((month) => dataByMonth[month].delivered);

  // Step 3: Render the bar graph
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sent Messages",
        backgroundColor: "rgba(75,192,192,0.5)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.8)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: sentData,
      },
      {
        label: "Delivered Messages",
        backgroundColor: "rgba(255,99,132,0.5)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.8)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: deliveredData,
      },
    ],
  };

  return <Bar data={data} />;
};

const WhatsAppAnalytics = () => {
  const { getWabAnalytics, wabAnalytics } = useWhatsappContext();
  const [analyticsDurInMo, setAnalyticsDurInMo] = useState("6");

  useEffect(() => {
    const timestamps = getUnixTimestamps(analyticsDurInMo);
    getWabAnalytics("MONTH", timestamps.start, timestamps.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsDurInMo]);

  return (
    <div className="wbm-card-2 space-y-2">
      <div className="flex justify-between items-end gap-3">
        <h3 className="font-Montserrat text-lg text-wabmBlue">
          Message Analytics
        </h3>
        <span className="w-full md:w-44 mr-5">
          <Select
            variant="outlined"
            label="Select Contact Group"
            color="blue"
            required
            value={analyticsDurInMo}
            onChange={(e) => setAnalyticsDurInMo(e)}
          >
            <Option value="3" className="hover:bg-blue-300 focus:bg-blue-300">
              3 months
            </Option>
            <Option value="6" className="hover:bg-blue-300 focus:bg-blue-300">
              6 months
            </Option>
            <Option value="12" className="hover:bg-blue-300 focus:bg-blue-300">
              12 months
            </Option>
          </Select>
        </span>
      </div>

      <MessageSentReceivedGraph conversationData={wabAnalytics} />
    </div>
  );
};

export default WhatsAppAnalytics;
