import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TbPlugConnected } from "react-icons/tb";

import {
  Sidebar,
  Overview,
  Templates,
  Contacts,
  AgentManagement,
  Settings,
  Subscribe,
  Campaign,
  Monitor,
  Chats,
  Catalog,
  Notifications,
  FollowUps,
  ManageAssignedChats,
  SubscribeStrip,
  ManageQuickReply,
  AddonSubscriptions,
  ManageSubscription,
  ManageWelcomeMessages,
} from "../components";
import { ContactsContextProvider } from "../contexts/ContactsContextProvider";
import { useUserContext } from "../contexts/UserContextProvider";
import { useWhatsappContext } from "../contexts/WhatsappContextProvider";
import { subject } from "../data/messageNotifierServices";

const Dashboard = () => {
  const location = useLocation();
  const { isLoggedIn, loginCheck, currentUserInfo, isUserAdminOrSuperadmin } =
    useUserContext();
  const { apiFetchWabApiCreds } = useWhatsappContext();
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    loginCheck();
    apiFetchWabApiCreds();
    subject.subscribe((data) => {
      if (data) {
        setIsSocketConnected(data.IsConnected);
      } else {
        setIsSocketConnected(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isLoggedIn && (
      <ContactsContextProvider>
        <div className="max-w-screen h-screen flex flex-col md:flex-row overflow-hidden">
          <Sidebar />
          <div className="w-full min-h-screen relative overflow-y-auto">
            {!currentUserInfo?.isActiveSubscription &&
              location.pathname !== "/dashboard/subscribe" && (
                <SubscribeStrip />
              )}

            <TbPlugConnected
              title={`Message receiver ${
                isSocketConnected ? "connected" : "disconnected"
              }`}
              size={24}
              className={`fixed top-5 right-6 ${
                isSocketConnected ? "text-green-500" : "text-red-600"
              }`}
            />

            <div className="p-5">
              <Routes>
                <Route path="/" element={<Navigate to="overview" />} />
                <Route path="overview" element={<Overview />} />
                <Route path="chats" element={<Chats />} />
                <Route path="templates" element={<Templates />} />
                <Route
                  path="campaign"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <Campaign />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                <Route
                  path="monitor"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <Monitor />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                <Route
                  path="contacts"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      isUserAdminOrSuperadmin() ? (
                        <Contacts />
                      ) : (
                        <Navigate replace to="/page-not-found" />
                      )
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                <Route
                  path="follow-ups"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <FollowUps />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                {isUserAdminOrSuperadmin() && (
                  <Route
                    path="assigned-chats"
                    element={
                      currentUserInfo?.isActiveSubscription ? (
                        <ManageAssignedChats />
                      ) : (
                        <Navigate replace to="/dashboard/subscribe" />
                      )
                    }
                  />
                )}
                <Route path="notifications" element={<Notifications />} />
                <Route
                  path="quick-reply"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <ManageQuickReply />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                <Route
                  path="agents"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      isUserAdminOrSuperadmin() ? (
                        <AgentManagement />
                      ) : (
                        <Navigate replace to="/page-not-found" />
                      )
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                {isUserAdminOrSuperadmin() && (
                  <Route
                    path="subscriptions"
                    element={<ManageSubscription />}
                  />
                )}
                <Route path="settings" element={<Settings />} />

                <Route path="subscribe" element={<Subscribe />} />

                {isUserAdminOrSuperadmin() && (
                  <Route
                    path="welcome-messages"
                    element={<ManageWelcomeMessages />}
                  />
                )}
                <Route
                  path="catalog"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <Catalog />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
                <Route
                  path="addons"
                  element={
                    currentUserInfo?.isActiveSubscription ? (
                      <AddonSubscriptions />
                    ) : (
                      <Navigate replace to="/dashboard/subscribe" />
                    )
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </ContactsContextProvider>
    )
  );
};

export default Dashboard;
