import React, { useState, useEffect } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { toast } from "react-toastify";
import { Button, Input } from "@material-tailwind/react";

import { useContactsContext } from "../../contexts/ContactsContextProvider";
import {
  inputNameSanitation,
  contactNameSanitation,
} from "../utils/inputSanitation";
import { phoneNumberValidationCheck } from "../utils/inputValidations";

const AddNewContact = ({ contactInfo, setIsAddNewContact }) => {
  const { apiCallCreateContact, isLoading, setIsLoading } =
    useContactsContext();

  const [countryCode, setCountryCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const resetOnClick = () => {
    setCountryCode("");
    setPhoneNo("");
    setFirstName("");
    setLastName("");
    setCompanyName("");
  };

  const saveContactOnSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (phoneNumberValidationCheck(phoneNo)) {
      apiCallCreateContact({
        countryCode,
        phoneNo,
        firstName,
        lastName,
        companyName,
      });

      setIsAddNewContact(false);
      resetOnClick();
    } else {
      toast.warn("Please enter a valid Phone Number!");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const parsedNumber = parsePhoneNumber("+" + contactInfo?._id);

    if (parsedNumber.valid) {
      setCountryCode(parsedNumber.countryCode);
      setPhoneNo(parsedNumber.number.significant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  return (
    <form onSubmit={saveContactOnSubmit} className="w-full space-y-5">
      <p className="font-semibold text-xl text-center">Add New Contact</p>

      <div className="bg-slate-200 px-5 py-1 flex justify-center items-center gap-2 rounded-md">
        <p>Phone Number :</p>
        <p className="font-semibold">{`+${countryCode} ${phoneNo}`}</p>
      </div>

      <div className="px-5 flex flex-col justify-center gap-3">
        <Input
          variant="standard"
          label="First Name"
          color="blue"
          required
          value={firstName}
          onChange={(e) => setFirstName(inputNameSanitation(e.target.value))}
          className="my-input-box-1"
        />
        <Input
          variant="standard"
          label="Last Name"
          color="blue"
          value={lastName}
          onChange={(e) => setLastName(inputNameSanitation(e.target.value))}
          className="my-input-box-1"
        />
        <Input
          variant="standard"
          label="Company Name"
          color="blue"
          labelProps={{
            className: "text-slate-800",
          }}
          required
          value={companyName}
          onChange={(e) =>
            setCompanyName(contactNameSanitation(e.target.value))
          }
          className="my-input-box-1"
        />
        <Button
          type="submit"
          variant="gradient"
          color="blue"
          disabled={isLoading}
          className="mx-auto"
        >
          Save Contact
        </Button>
      </div>
    </form>
  );
};

export default AddNewContact;
