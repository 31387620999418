import React, { useState, useEffect } from "react";
import { BsPersonSquare, BsPersonFillCheck } from "react-icons/bs";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { MdFilterAltOff } from "react-icons/md";
import { RiSendPlane2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Input } from "@material-tailwind/react";

import { useUserContext } from "../../contexts/UserContextProvider";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { Loader, ContextMenu } from "..";

import { refreshMonitorData } from "../../data/messageNotifierServices";
import { useContextMenu } from "../../contexts/ContextMenuContextProvider";
// import { showNotification } from "../utils/showNotification";

const RecentChats = () => {
  const { currentUserInfo } = useUserContext();
  const {
    convGetChatContacts,
    recentContactsList,
    convGetChatHistory,
    selectedChatContactToShow,
    setSelectedChatContactToShow,
    setChatContact,
    socketConnection,
    setChatHistory,
    chatContact,
    updateMessageReadStatus,
  } = useConversationContext();
  const { showContextMenu } = useContextMenu();

  const [searchContact, setSearchContact] = useState("");
  const [filterBy, setFilterBy] = useState("");

  let subscribe;

  const convertToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleContextMenu = (event, currentContact) => {
    showContextMenu(event, [
      {
        label: "Mark as unread",
        onClick: () => {
          updateMessageReadStatus(currentContact?._id);
        },
      },
    ]);
  };

  const handleContactSearch = (currentContact) => {
    const numberRegex = /\d{3,}/g;
    const letterRegex = /[A-z]{3,}/gi;

    if (searchContact === "" || searchContact.length < 3) return true;
    else if (
      numberRegex.test(searchContact) &&
      currentContact?._id &&
      currentContact?._id.includes(searchContact)
    )
      return true;
    else if (
      letterRegex.test(searchContact) &&
      currentContact?.fullName &&
      currentContact?.fullName
        .toLowerCase()
        .includes(searchContact.toLowerCase())
    )
      return true;
    else return false;
  };

  const handleContactFilter = (currentContact) => {
    if (filterBy === "unread") {
      if (!currentContact?.readStatus) return true;
      return false;
    } else if (filterBy === "replied") {
      if (currentContact?.messageData?.agent === currentUserInfo?.agentName)
        return true;
      return false;
    } else if (filterBy === "assignedUser") {
      if (
        currentContact?.assignedUser &&
        JSON.parse(currentContact?.assignedUser).indexOf(
          currentUserInfo?.agentId
        ) !== -1
      )
        return true;
      return false;
    }
    return true;
  };

  const fetchChatOnClick = (contact) => {
    if (contact.messageData.from) convGetChatHistory(contact.messageData.from);
    else if (contact.messageData.to) convGetChatHistory(contact.messageData.to);
    else {
      toast.warn("Couldn't fetch Chat of Invalid No.");
    }
    setChatContact(contact);
  };

  const socketMessageListener = (msg) => {
    if (recentContactsList.length > 0) {
      let contactIndex = recentContactsList?.findIndex(
        (x) => x?._id === msg?._id
      );

      if (contactIndex !== -1) {
        recentContactsList[contactIndex].messageData = msg.messageData;
        recentContactsList[contactIndex].profile = msg.profile;
        recentContactsList[contactIndex].readStatus = msg.readStatus;
      } else {
        convGetChatContacts("");
      }
    } else {
      convGetChatContacts("");
    }
  };

  useEffect(() => {
    if (socketConnection?.connected) {
      socketConnection.on(currentUserInfo?.wabPhoneId, socketMessageListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContact]);

  useEffect(() => {
    if (searchContact === "") {
      convGetChatContacts("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscribe = refreshMonitorData?.subscribe(() => {
      convGetChatContacts("");
    });
    return () => {
      subscribe?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContact]);

  return (
    <main className="bg-wabmBlue w-full h-[40vh] md:h-screen px-1 pb-2 md:col-span-3 lg:col-span-2 drop-shadow-md overflow-y-auto">
      <div className="px-1 pt-2 flex justify-between items-center gap-5">
        <h1 className="wabm-font-style-2 text-white">Chats</h1>
        <ContextMenu />
        <div className="w-fit space-x-1">
          <button
            type="button"
            onClick={() => setFilterBy("unread")}
            title="Unread"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "unread" && "bg-black"
            }`}
          >
            <FaEyeSlash size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("replied")}
            title="Replied to"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "replied" && "bg-black"
            }`}
          >
            <RiSendPlane2Fill size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("assignedUser")}
            title="Assigned to you"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "assignedUser" && "bg-black"
            }`}
          >
            <BsPersonFillCheck size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("")}
            title="Clear Filter"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "" && "bg-black"
            }`}
          >
            <MdFilterAltOff size={20} />
          </button>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          convGetChatContacts(searchContact);
        }}
        className="bg-wabmBlue w-full mt-3 px-1 pt-3 flex sticky -top-1 z-10 mb-5"
      >
        <Input
          label="Search phone number or name"
          variant="standard"
          color="white"
          placeholder="Example: Sam"
          value={searchContact}
          onChange={(e) => setSearchContact(e.target.value.replace("+", ""))}
          className="pr-20 placeholder:text-blue-200"
          containerProps={{
            className: "min-w-0 w-[77%]",
          }}
        />
        <button
          type="submit"
          className="my-smooth-transition-1 text-white hover:text-wabmAiBlue"
        >
          <IoMdSearch size={20} />
        </button>
      </form>

      {recentContactsList?.length === 0 ? (
        <Loader />
      ) : (
        <div className="overflow-y-auto">
          {recentContactsList[0].Empty ? (
            <div className="grid place-content-center text-white text-center">
              <p>Wow, so empty!</p>
              <p>Start a Campaign or Chat Now.</p>
            </div>
          ) : (
            recentContactsList?.map(
              (contact) =>
                handleContactSearch(contact) &&
                handleContactFilter(contact) && (
                  <button
                    onContextMenu={(e) => {
                      handleContextMenu(e, contact);
                    }}
                    key={contact?._id}
                    onClick={() => {
                      if (
                        (contact?.assignedUser &&
                          JSON.parse(contact?.assignedUser).indexOf(
                            currentUserInfo?.agentId
                          ) !== -1) ||
                        currentUserInfo?.canViewChat === 1 ||
                        currentUserInfo?.isAdmin === 1 ||
                        currentUserInfo?.isAdmin === 2
                      ) {
                        setChatHistory(true);
                        setSelectedChatContactToShow(contact?._id);
                        fetchChatOnClick(contact);
                      } else {
                        toast.warning(
                          "You are not authorized to open this chat"
                        );
                      }
                    }}
                    className={`my-smooth-transition-1 bg-wabmAiBlue w-full h-16 mb-[2px] pl-5 relative flex items-center gap-5 text-white rounded-sm border-l-8 ${
                      contact?._id === selectedChatContactToShow
                        ? "bg-opacity-90 border-wabmGreen"
                        : "bg-opacity-60 hover:bg-opacity-80 border-wabmAiBlue border-opacity-0 hover:border-infokeyYellow"
                    }`}
                  >
                    <div className="absolute top-2 right-2 flex items-center gap-2">
                      {contact?.messageData?.agent ===
                        currentUserInfo?.agentName && (
                        <RiSendPlane2Fill
                          title="You replied to this chat at last"
                          size={20}
                          className="text-wabmGreen"
                        />
                      )}
                      {contact?.assignedUser &&
                        JSON.parse(contact?.assignedUser).indexOf(
                          currentUserInfo?.agentId
                        ) !== -1 && (
                          <BsPersonFillCheck
                            title="This chat is assigned to you!"
                            size={20}
                            className="text-infokeyYellow"
                          />
                        )}
                    </div>
                    <BsPersonSquare
                      size={32}
                      className={
                        contact?._id === selectedChatContactToShow &&
                        "text-wabmGreen"
                      }
                    />
                    <div className="text-sm">
                      <span className="flex gap-1 flex-wrap">
                        {contact?.fullName !== "" &&
                        contact?.fullName !== undefined ? (
                          <h3>{contact?.fullName.substring(0, 40)}</h3>
                        ) : (
                          "+" + contact?._id
                        )}
                      </span>
                      {contact?.messageData?.type === "text" && (
                        <p className="text-slate-200 text-sm text-left">
                          {typeof contact?.messageData?.text?.body !== "string"
                            ? String(
                                contact?.messageData?.text?.body
                              ).substring(0, 25)
                            : contact?.messageData?.text?.body.substring(0, 25)}
                        </p>
                      )}
                      {contact?.messageData?.type === "template" && (
                        <p className="text-slate-200 text-sm text-left uppercase">
                          {contact?.messageData?.template?.name.substring(
                            0,
                            25
                          ) + "..."}
                        </p>
                      )}
                    </div>
                    <div
                      className={`absolute bottom-1 right-1 flex items-center gap-2 ${
                        contact?.readStatus
                          ? "text-gray-300"
                          : "text-infokeyYellow"
                      } text-xs`}
                    >
                      {!contact?.readStatus && (
                        <span
                          title="This message is unread"
                          className="bg-infokeyYellow w-2 h-2 rounded-full"
                        />
                      )}
                      <p>
                        {convertToLocalTime(contact?.messageData?.timestamp)}
                      </p>
                    </div>
                  </button>
                )
            )
          )}
        </div>
      )}
    </main>
  );
};

export default RecentChats;
