import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";

const CatalogContext = createContext();

export const CatalogContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;

  const [isLoading, setIsLoading] = useState(false);
  const [allCatalogsList, setAllCatalogsList] = useState([]);
  const [currentCatalogId, setCurrentCatalogId] = useState("");
  const [catalogItems, setCatalogItems] = useState([]);

  const [activeCatalogId, setActiveCatalogId] = useState("");
  const [activeCatalogItems, setActiveCatalogItems] = useState([]);

  const getAllCatalogs = async () => {
    setIsLoading(true);
    fetch(`${baseUrl}/catalog/all-catalogs`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setActiveCatalogId(response.activeCatalogId);
        setAllCatalogsList(response.catalogList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to load All Catalogs");
      });
  };

  const getSelectedCatalog = async (isActiveCatalog, catalogId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/catalog/selected-catalog`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isActiveCatalog, catalogId }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (isActiveCatalog) {
          setActiveCatalogId(response.catalogId);
          setActiveCatalogItems(response.data);
        } else {
          setCurrentCatalogId(response.catalogId);
          setCatalogItems(response.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("API Call Error | Selected Catalog");
      });
  };

  const getCatalogItemDetails = async (isActiveCatalog, itemId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/catalog/catalog-item-details`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ itemId: itemId }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (isActiveCatalog) {
          setActiveCatalogItems((prevItems) =>
            prevItems.map((item) =>
              item.id === itemId
                ? {
                    ...item,
                    price: response?.price,
                    image: response?.image_url,
                  }
                : item
            )
          );
        } else {
          setCatalogItems((prevItems) =>
            prevItems.map((item) =>
              item.id === itemId
                ? {
                    ...item,
                    price: response?.price,
                    image: response?.image_url,
                  }
                : item
            )
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("API Call Error | Selected Catalog");
      });
  };

  return (
    <CatalogContext.Provider
      value={{
        getAllCatalogs,
        getSelectedCatalog,
        getCatalogItemDetails,
        allCatalogsList,
        currentCatalogId,
        catalogItems,
        activeCatalogId,
        activeCatalogItems,
        isLoading,
        setAllCatalogsList,
        setCurrentCatalogId,
        setCatalogItems,
        setActiveCatalogId,
        setActiveCatalogItems,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => useContext(CatalogContext);
