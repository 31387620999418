import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { SeoTags } from "..";

// const CustomBody = ({ row }) => (
//   <div>
//     {}
//     <div>
//       <div
//         data-tag="allowRowEvents"
//         style={{
//           color: "black",
//           overflow: "hidden",
//           whiteSpace: "wrap",
//           textOverflow: "ellipses",
//         }}
//       >
//         {}
//         <span dangerouslySetInnerHTML={{ __html: row?.body }}></span>
//       </div>
//     </div>
//   </div>
// );

const Notifications = () => {
  const { getNotifications, notificationList } = useConversationContext();
  const { currentUserInfo } = useUserContext();
  const notificationColumn = [
    {
      name: "Notification title",
      selector: (row) => {
        return row?.title;
      },
      sortable: true,
      grow: 3,
    },
    {
      name: "On web",
      maxWidth: "120px",
      selector: (row) => {
        const receiverDetails = row?.receivers.find(
          (x) => x.receiverId === currentUserInfo?.agentId
        );
        if (receiverDetails?.onWeb === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "On email",
      maxWidth: "120px",
      selector: (row) => {
        const receiverDetails = row?.receivers.find(
          (x) => x.receiverId === currentUserInfo?.agentId
        );
        if (receiverDetails?.onEmail === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.notificationType,
      sortable: true,
    },
    {
      name: "Priority",
      selector: (row) => row?.priority,
      sortable: true,
    },
    {
      name: "Notification Time",
      selector: (row) => {
        let notificationTime = new Date(row?.timeStamp * 1000);
        notificationTime.setMinutes(
          notificationTime.getMinutes() - notificationTime.getTimezoneOffset()
        );
        return notificationTime
          .toISOString()
          .substring(0, 16)
          .replace("T", " ");
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
      <SeoTags title="Notifications ‣ WaBM" />

      <div className="w-full space-y-2">
        <h1 className="text-2xl">Notifications</h1>
        <h3>Your WaBM Notifications all at one place.</h3>
      </div>

      <div className="wbm-card-3 space-y-3">
        <div className="rounded-md overflow-auto">
          <DataTable
            columns={notificationColumn}
            data={notificationList}
            customStyles={TableStyles}
            fixedHeader
            pagination
          />
        </div>
      </div>
    </main>
  );
};

export default Notifications;

const TableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
