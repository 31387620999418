export const isoTimestampToReadable = (timestamp) => {
  return new Date(timestamp).toISOString().slice(0, 19).replace("T", " ");
};

export const isoTimestampToLocal = (timestamp) => {
  return new Date(timestamp).toString().slice(0, 25);
};

export const isoTimestampToLocalWithFormat = (timestamp) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(timestamp).toLocaleDateString("en-GB", options);
};

export const getCurrentDateTime = () => {
  return new Date().toISOString().slice(0, 19).replace("T", " ");
};

export const convertUnixTimestampToDateTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const convertUnixTimestampToYearMonth = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based

  return `${year}-${month}`;
};

export const getUnixTimestamps = (months) => {
  const currentDate = new Date();
  const currentTimestamp = Math.floor(currentDate.getTime() / 1000);

  // Calculate the date N months ago
  const pastDate = new Date(currentDate);
  pastDate.setMonth(currentDate.getMonth() - months);
  const pastTimestamp = Math.floor(pastDate.getTime() / 1000);

  return {
    start: pastTimestamp,
    end: currentTimestamp,
  };
};
